import { createSlice } from '@reduxjs/toolkit';
import { IOutput } from "../../../Components/FlowOutput/FlowOutput.types";
import { formatDate } from "../../../Tools/date";

interface IRulesetDataState {
    output: IOutput[],
    activeRule: string
}

const initialState: IRulesetDataState = {
    output: [],
    activeRule: ''
}

export const rulesetDataSlice = createSlice({
    name: 'RulesetData',
    initialState,
    reducers: {

        // Add Ruleset Data Output
        addRulesetDataOutputAction: (state, action) => {
            const color = action.payload[1] || 'default';
            const output: IOutput = {
                message: action.payload[0],
                date: formatDate(new Date()),
                color: color
            }
            state.output.push(output);
        },

        // Add Active Rule
        addActiveRuleAction: (state, action) => {
            state.activeRule = action.payload;
        },

        // Reset Rule Data
        resetRulesetActiveRuleAction: (state, action) => {
            state.activeRule = '';
        },

        // Reset Rule Data
        resetRulesetDataAction: (state, action) => {
            return initialState;
        },
    }
});

export const {
    addRulesetDataOutputAction,
    addActiveRuleAction,
    resetRulesetDataAction,
    resetRulesetActiveRuleAction
} = rulesetDataSlice.actions;
export default rulesetDataSlice.reducer;