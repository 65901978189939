import React, {FC, useContext, useRef, useState} from "react";
import {Col, Row, Title, Text, Icon, iconsTypes} from "lavaa";
import css from "./style.module.scss";
import {Guideline} from "./guideline";
import {RiskLevel} from "../../RiskLevel";
import {IClientGuidelinesType, IResultType} from "../../../../Redux/Slices/Demo/Demo.data";
import {useDemo} from "../../../../Hooks/useDemo";
import {ModalsCtx} from "../../../../Context/Modals.context";
import {classNames} from "../../../../Tools";

interface IProps {
    result: IResultType,
    guidelines: IClientGuidelinesType[]
}

// Treatment Diagnose
const TreatmentDiagnose: FC<IProps> = React.memo((props) => {

    const {result, guidelines} = props;
    const { setPatientLabResultModalData, setPatientLabResultModalActive, setDiagnoseInfoModalActive, setDiagnoseInfoModalData } = useContext(ModalsCtx);
    let { activeClient } = useDemo();

    const timerRef = useRef<any>(0)
    const [isActive, setIsActive] = useState(false);
    const [isOpen, setIsOpen] = useState(false);

    const hasGuidelines = guidelines.length > 0;

    // On Report Label Click
    const onDiagnoseClick = (e: Event) => {
        e.stopPropagation();

        const data = {
            moduleName: result.moduleName,
            labResult: result,
            client: {name: activeClient.client.LastName}
        }
        setPatientLabResultModalData(data);
        setPatientLabResultModalActive(true);
    };

    // On More Info Click
    const onMoreInfoClick = (e: Event) => {
        e.stopPropagation();
        const data = {
            moduleName: result.moduleName,
            moduleUID: result.moduleUID,
        }
        setDiagnoseInfoModalData(data);
        setDiagnoseInfoModalActive(true);
    };

    function toggle() {
        if(isActive) {
            clearTimeout(timerRef.current);
            timerRef.current = setTimeout(() => {
                setIsActive(false);
            }, 50);
            setIsOpen(false);
        }
        else {
            if(!hasGuidelines) return; // If no guidelines, don't open

            timerRef.current = setTimeout(() => {
                setIsOpen(true);
            }, 600);
            setIsActive(true);
        }
    }


    return (
        <Col>
            <Col className={classNames(css.Diagnose, hasGuidelines ? '' : css.NoGuidelines)}>
            <Col className={css.Header} onClick={toggle}>
                <Row justifycontent="space-between" alignitems="center" marginBottom="0.75rem">
                    <Col onClick={ onDiagnoseClick }>
                        <Title size='x3' bold="true" ellipsis={true} maxLines={2}>{result.moduleName}</Title>
                    </Col>

                    {
                        hasGuidelines && (
                            <Col className={classNames(css.Arrow, isActive ? css.active : "")}>
                                <Icon name={iconsTypes.arrowDown} size="x2"/>
                            </Col>
                        )
                    }
                </Row>
                <Col shrink="0" marginBottom="1.75rem" onClick={ onDiagnoseClick }>
                    <RiskLevel
                        bgColor={result.bgColor}
                        text={result.resultGroupName}
                        percent={result.resultPercentage}
                        riskChange={result.resultTrend}
                    />
                </Col>
                <Row alignitems="center" >
                    <Row onClick={onMoreInfoClick}>
                        <Col className={css.MoreIcon} marginRight="0.5rem">
                            <Icon name={iconsTypes.infoUnfilled} size="x1"/>
                        </Col>
                        <Text text="Info" size="x2" bold={true} className={css.MoreInfo}/>
                    </Row>
                </Row>
            </Col>

            {
                hasGuidelines && (
                    <Row alignitems="start" className={classNames(css.Content, isActive ? css.active : "", isOpen ? css.open : "")}>
                        <div className={css.List}>
                            {
                                guidelines.map((guideline: IClientGuidelinesType) => {
                                    return (
                                        <Guideline guideline={guideline} key={guideline.guidelineUID}/>
                                    )
                                })
                            }
                        </div>
                    </Row>
                )
            }
        </Col>
        </Col>
    )
});

export {TreatmentDiagnose};
