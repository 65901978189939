import {useStatistics} from "../../../../../Hooks/UseStatistics";
import {IStatisticType} from "../../../../../Redux/Slices/Statistics/Statistics.data";
import {BarChartDataType, BarChartLabelType} from "lavaa";

type DataType = {
	total: number,
	risk: number,
}

export type AgeDataType = {
	[key: string]: DataType
}

export function useAgeStat(moduleId?: string): [BarChartDataType[], BarChartLabelType[]] {
	const {statisticsData} = useStatistics();
	const data =  prepareData(statisticsData, moduleId);
	const labels = prepareLabels();
	return [data, labels];
}

function prepareData(statisticsData: IStatisticType[], moduleId?: string): BarChartDataType[]{
	const data = filterData(statisticsData, moduleId);

	const dataSorted = sortData(data);

	return dataFormatter(dataSorted);
}


// Filter by Age Group type and High-Risk
function filterData(statisticsData: IStatisticType[], moduleId?: string): AgeDataType{
	const data: AgeDataType = {};
	statisticsData.forEach((item: IStatisticType) => {
		// Get data
		const moduleUID = item.ModuleUID;
		const group = item.AgeGroup;
		const count = item.Amount;
		const riskPercentage = item.ResultPercentage;

		// Filter by module if moduleId is provided
		if(moduleId && moduleUID !== moduleId){
			return;
		}

		if(!data.hasOwnProperty(group)){
			data[group] = {
				risk: 0,
				total: 0,
			}
		}


		if(isHighRisk(riskPercentage)){
			// Count if this group is high risk
			data[group].risk += count;
		}
		else{
			// Count all without high risk
			data[group].total += count;
		}
	});

	return data;
}

// Sort by age group name
function sortData(data: AgeDataType): [string, DataType][] {
	return Object.entries(data).sort((a: any, b: any) => {
		return b[0] > a[0] ? -1 : 1;
	});
}

function dataFormatter(data: [string, DataType][]): BarChartDataType[]{
	const dataFormatted: BarChartDataType[] = [];

	data.forEach(([ageGroup, data]: [string, {total: number, risk: number}], index) => {
		if(index >= 5) return;
		dataFormatted.push(
			{
				name: ageGroup,
				data: {
					total: data.total,
					risk: data.risk,
				}
			}
		)
	});

	return dataFormatted
}

export function isHighRisk(riskPercentage: number): boolean {
	return riskPercentage === 100;
}

function prepareLabels(): BarChartLabelType[]{
	return [
		{
			key: 'total',
			name: "All Patients",
			color: "#07B951"
		},
		{
			key: 'risk',
			name: "High-Risk Patients",
			color: "#FF3E1E"
		}
	];
}