import React, {FC} from "react";
import {PersonalInfoPage} from "../PersonalInfo";
import {HealthCardPage} from "../HealthCard";
import {GuidelinesPage} from "../Guidelines";
import {QuestionnairePage} from "../Questionnaire";
import {SurveysPage} from "../Surveys/Surveys";

interface IProps {
    activeTab: number
}

// Patient Page Content
const PatientPageContent: FC<IProps> = React.memo((props) => {

    const {activeTab} = props;

    return (
        <>
            {
                activeTab === 0 && (
                    <PersonalInfoPage/>
                )
            }
            {
                activeTab === 1 && (
                    <HealthCardPage/>
                )
            }
            {
                activeTab === 2 && (
                    <GuidelinesPage/>
                )
            }
            {
                activeTab === 3 && (
                    <QuestionnairePage/>
                )
            }
            {
                activeTab === 4 && (
                    <SurveysPage/>
                )
            }
        </>
    )
});

export {PatientPageContent};
