import {useStatistics} from "../../../../../Hooks/UseStatistics";
import {IStatisticType} from "../../../../../Redux/Slices/Statistics/Statistics.data";
import {BarChartDataType, BarChartLabelType} from "lavaa";

type GenderDataType = {
	men: number,
	women: number,
	total: number,
	riskPercentage: number,
}

type ChartDataType = {
	[key: string]: GenderDataType,
}

export function useTotalGenderStat(moduleId?: string, maxRows: number = 5): [BarChartDataType[], BarChartLabelType[]]{
	const {statisticsData} = useStatistics();

	const dataFormatted = prepareData(statisticsData, maxRows, moduleId);

	const labels = prepareLabels()

	return [dataFormatted, labels];
}

function prepareData(statisticsData: IStatisticType[], maxRows: number, moduleId?: string): BarChartDataType[]{
	const data = filterData(statisticsData, moduleId);

	const dataSorted = sortData(data);

	return formatData(dataSorted, maxRows);
}

// Merge data by Risk Group type and Gender
// Filter by module if moduleId is provided
function filterData(statisticsData: IStatisticType[], moduleId?: string): ChartDataType{
	const data: ChartDataType = {};

	statisticsData.forEach((item: IStatisticType) => {
		// Get data
		const moduleUID = item.ModuleUID;
		const group = item.ResultGroupName;
		const gender = item.Gender;
		const count = item.Amount;
		const riskPercentage = item.ResultPercentage;

		// Filter by module if moduleId is provided
		if(moduleId && moduleUID !== moduleId){
			return;
		}

		// Create group if not exists
		if(!data.hasOwnProperty(group)){
			data[group] = {
				men: 0,
				women: 0,
				total: 0,
				riskPercentage: 0,
			}
		}

		// Add data of male
		if(gender === "M"){
			data[group].men += count;
			data[group].total += count;
		}

		// Add data of female
		if(gender === "F"){
			data[group].women += count;
			data[group].total += count;
		}

		// Update risk percentage if it is higher
		if(riskPercentage > data[group].riskPercentage){
			data[group].riskPercentage = riskPercentage;
		}
	});

	return data;
}

// Sort by risk percentage
function sortData(data: ChartDataType): [string, GenderDataType][] {
	return Object.entries(data).sort((a: any, b: any) => {
		return b[1].riskPercentage - a[1].riskPercentage || b[1].total - a[1].total
	});
}

// Format data for chart
function formatData(data: [string, GenderDataType][], maxRows: number): BarChartDataType[] {
	const dataFormatted: BarChartDataType[] = [];

	data.forEach(([group, genderData]: [string, GenderDataType], index: number) => {
		// Skip if there are more than maxRows
		if(index >= maxRows){
			return;
		}

		dataFormatted.push({
			name: group,
			data: {
				men: genderData.men,
				women: genderData.women
			},
		});
	});

	return dataFormatted;
}

function prepareLabels(): BarChartLabelType[]{
	return [
		{
			key: 'men',
			name: "Men",
			color: "#2962FF"
		},
		{
			key: 'women',
			name: "Women",
			color: "#FFA726"
		}
	];
}