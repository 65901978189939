import React, {FC, useState} from "react";
import {Container, ScrollContainer} from "lavaa";
import {ModulePersonEditOutputModal} from "../../Custom/Modals/PersonEditOutput/PersonEditModal.component";
import {PatientLabResultModal} from "../../Custom/Modals/PatientLabResultModal/PatientLabResultModal.component";
import {PatientPageContent} from "./Content/PatientPageContent";
import {PatientPageMenu} from "./Menu/PatientPageMenu";
import {DiagnoseInfoModal} from "../../Custom/Modals/DiagnoseInfoModal/DiagnoseInfoModal.component";

// Patient Page Mobile
const PatientPageMobile: FC<any> = React.memo(() => {

    const [activeTab, setActiveTab] = useState(0);

    return (
        <Container>
            <ScrollContainer addPadding={false}>
                <PatientPageMenu setActiveTab={setActiveTab}/>
                <PatientPageContent activeTab={activeTab}/>
            </ScrollContainer>

            {/*  Person Edit Output Modal */}
            <ModulePersonEditOutputModal/>

            {/* Modals */}
            <PatientLabResultModal/>
            <DiagnoseInfoModal/>

        </Container>
    )
});

export {PatientPageMobile};
