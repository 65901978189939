import { FC, useEffect, useRef } from "react";
import { Col, Row, Cell, Text } from 'lavaa';
import { IOutput } from "./FlowOutput.types";
import css from './FlowOutput.module.scss';

interface IProps {
    logs: IOutput[]
}

// Flow Output
const FlowOutput: FC<IProps> = ( props) => {

    const {logs} = props;
    const blockRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if(!blockRef.current || !blockRef.current.parentNode) return;
        (blockRef.current.parentNode as HTMLElement).scrollTop = (blockRef.current.parentNode as HTMLElement).scrollHeight;
    }, [logs])

    return(
        <Col className={css.Output} innerRef={blockRef}>
          {
              logs.map((output: IOutput, key: number) =>
              <Row paddingTop='0.75rem' paddingLeft='1rem' paddingRight='1rem' key={key}>
                  <Cell paddingRight="0.5rem">
                      <Text text={output.date} bold="true"/>
                  </Cell>
                  {
                      output.link ? (
                        <a href={output.link} target="_blank" className={css.Link}><Text text={output.message} className={css.Log} data-type={output.color}/></a>
                      ) : (
                        <Text text={output.message} className={css.Log} data-type={output.color}/>
                      )
                  }

              </Row>
            )
          }
        </Col>
    )
};

export { FlowOutput };