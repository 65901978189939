import JL from "json-logic-js";
import { FieldOrGroup, Fields } from "@react-awesome-query-builder/ui";

export function parseJsonLogicFlow(data: any){
  if(data && data.jsonLogic && Array.isArray(data.jsonLogic)){
    const initialNodes = data.jsonLogic.find((item: any) => item.name === 'initialNodes') || { data: [] };
    const initialEdges = data.jsonLogic.find((item: any) => item.name === 'initialEdges') || { data: [] };
    return {initialNodes: initialNodes.data, initialEdges: initialEdges.data};
  }

  return {initialNodes: [], initialEdges: []};
}

export function parseJsonLogicUsedFields(jsonLogic: object, clientScheme: Fields): JsonLogicFieldType[]{

  // Get Uses Data from JsonLogic
  const usesData = JL.uses_data(jsonLogic);

  return prepareJsonLogicInputFields(clientScheme, usesData);
}

export function prepareJsonLogicInputFields(clientScheme: Fields, usesData: string[] | null = null, keysInCamelCase = false): JsonLogicFieldType[]{

  const types = ['text', 'number', 'select', 'boolean'];
  const fields: JsonLogicFieldType[] = [];

  Object.entries(clientScheme).forEach(([key, field]) => {
    let localFields: {[key: string]: FieldOrGroup} = {}, localFieldsSorted: {[key: string]: FieldOrGroup} = {}, count = 0;

    const localKey = (keysInCamelCase) ? toCamelCase(key) : key;
    const state: JsonLogicFieldType = {
      key: localKey,
      parentLabel: field.label ?? "",
      fields: {},
      field: null
    }

    if("subfields" in field){
      Object.entries(field.subfields).forEach(([key, childField]) => {
        const localKey = (keysInCamelCase) ? toCamelCase(key) : key;
        if(
          types.includes(childField.type)
          && (usesData == null || (usesData!.includes(key)))
        ){
          count++;
          Object.assign(localFields, {
            [localKey]: childField
          });
        }
      });

      Object.entries(localFields)
        .sort(function(f1, f2){
          const a = f1[1].type === "boolean" ? 1 : 0;
          const b = f2[1].type === "boolean" ? 1 : 0;
          return a - b;
        })
        .map(([localKey, field]) => {
          Object.assign(localFieldsSorted, {
            [localKey]: field
          });
        });

      state.fields = localFieldsSorted;
    }
    else{
      if(
        types.includes(field.type)
        && (usesData == null || (usesData!.includes(key)))
      ){
        state.field = field;
        count++;
      }
    }

    if(count > 0){
      fields.push(state);
    }
  });

  return fields;
}

export function prepareJsonLogicDataFields(fields: any): object{
  const formatted = Object.assign({}, fields);

  Object.keys(fields).forEach((key) => {
    if(isObject(fields[key])){
      formatted[key] = [fields[key]];
    }
  });

  return formatted;
}

export function clientDataToLowerCamel(client: any): object{
  const formatted: any = {};
  Object.keys(client).forEach((key) => {
    const data: any = {};
    Object.keys(client[key]).forEach((key2) => {
      data[toCamelCase(key2, false)] = client[key][key2];
    });
    formatted[toCamelCase(key, false)] = data;
  });

  return formatted;
}

export interface JsonLogicFieldType {
  key: string,
  parentLabel: string,
  field: FieldOrGroup | null,
  fields: {
    [key: string]: FieldOrGroup
  }
}

const isObject = (obj: any) => {
  return Object.prototype.toString.call(obj) === '[object Object]';
};

function toCamelCase(str: string, isFirstCharUpperCase = true): string{
  return str
    .replace(/\s(.)/g, function(a) {
      return isFirstCharUpperCase ? a.toUpperCase() : a.toLowerCase();
    })
    .replace(/\s/g, '')
    .replace(/^(.)/, function(b) {
      return b.toLowerCase();
    });
}