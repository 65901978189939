import React, {FC, useContext, useState} from "react";
import {Row, Col, Text, Icon, iconsTypes} from "lavaa";
import { IClientType, IModuleType } from "../../../Redux/Slices/Demo/Demo.data";
import css from "../PatientListMob.module.scss";
import {ReportLabel} from "../../PatientPage/ReportLabel/ReportLabel.component";
import {classNames} from "../../../Tools";
import {ModalsCtx} from "../../../Context/Modals.context";
import {useNavigate} from "react-router-dom";

interface IProps {
    // onModuleSelect: (data: IModuleType[]) => void,
    modules: IModuleType[],
    client: IClientType
}

// Patient Card Mobile
const PatientCardMob: FC<IProps> = (props) => {
    const {modules, client} = props;
    const [isActive, setIsActive] = useState(false);
    const navigate = useNavigate();
    const { setPatientLabResultModalActive, setPatientLabResultModalData } = useContext(ModalsCtx);

    const modulesMap: any = {};
    modules.forEach((module: IModuleType) => {
        modulesMap[module.ModuleUID] = module.ModuleName;
    });

    const labResults = client.results.filter((labResult: any) => modulesMap[labResult.moduleUID]);

    function toggleHandler(){
        setIsActive(!isActive);
    }

    function onReportLabelClick(e: Event, moduleName: string, labResult: any, client: any) {
        e.stopPropagation();

        setPatientLabResultModalData({moduleName, labResult, client});
        setPatientLabResultModalActive(true);
    }

    function onPatientSelect () {
        navigate( "/patients/" + client.id );
    }

    const hasResults = labResults.length > 0;
    let firstResult:any = null, firstModuleUID = '', firstModuleName = '';

    if(hasResults){
        firstResult = labResults[0];
        firstModuleUID = firstResult.moduleUID;
        firstModuleName = modulesMap[firstModuleUID];
    }

    return (
        <Col className={css.Patient} data-id={client.id}>
            {/*Info*/}
            <Row className={css.Info} alignitems="start">
                {/*Basic Info*/}
                <Col grow="1" onClick={onPatientSelect}>
                    <Text text={client.name} size="x4" bold={true} className={css.Name}/>
                    {
                        hasResults && (
                            <Text text={firstModuleName} size="x3" className={css.ModuleName}/>
                        )
                    }
                </Col>

                {/*Report Label*/}
                <Row shrink="0">
                    {
                        hasResults && (
                            <ReportLabel type="button" onClick={ (e: Event) => onReportLabelClick(e, firstModuleName, firstResult, client) } textColor={ firstResult.textColor } bgColor={ firstResult.bgColor }>{ firstResult.resultGroupName }</ReportLabel>
                        )
                    }
                </Row>
            </Row>

            {/*More*/}
            {
                hasResults && (
                    <Row alignitems="center" className={css.More} onClick={toggleHandler}>
                        <Text text="More Modules" size="x4" bold={true} className={css.MoreText}/>

                        {/*Arrow*/}
                        <div className={classNames(css.ArrowIcon, isActive && css.ArrowIconActive)}><Icon name={iconsTypes.arrowDownBig} /></div>
                    </Row>
                )
            }

            <Col className={classNames(css.ModulesList, isActive ? css.active : '')}>
                {
                    labResults.map((labResult) => {
                        const { moduleUID } = labResult;
                        const moduleName = modulesMap[moduleUID];

                        return(
                            <Row alignitems="center" className={css.ModuleRow} key={moduleUID}>

                                <Row grow="1">
                                    <Text text={moduleName} size="x3" className={css.ModuleName}/>
                                </Row>

                                {/*Report Label*/}
                                <Row shrink="0">
                                    <ReportLabel type="button" onClick={ (e: Event) => onReportLabelClick(e, moduleName, labResult, client) } textColor={ labResult.textColor } bgColor={ labResult.bgColor }>{ labResult.resultGroupName }</ReportLabel>
                                </Row>
                            </Row>
                        )
                    })
                }
            </Col>

        </Col>
    )
};

export { PatientCardMob };
