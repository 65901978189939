import { FC } from 'react';
import { Row } from 'lavaa';
import { Splitter, SplitterPanel } from 'primereact/splitter';
import { PatientsUpload } from "../../PatientsUpload/PatientsUpload.component";
import css from "./UploadPatients.module.scss";
import { UploadOutput } from "./UploadOutput.component";

// Upload Patients
const UploadPatients: FC<any> = () => {

  return (
    <>
      <Row grow="1">
          <Splitter style={{ border: "none", width: "100%" }} gutterSize={15}>
            <SplitterPanel size={66}>
              <Splitter layout="vertical" style={{ height: '100%' }} gutterSize={7}>
                <SplitterPanel size={70}>
                  <PatientsUpload/>
                </SplitterPanel>

                {/* Bottom Pane - (Output etc) */}
                <SplitterPanel size={30} minSize={30} className={css.OutputPanel}>
                  <UploadOutput/>
                </SplitterPanel>
              </Splitter>
            </SplitterPanel>

          </Splitter>
      </Row>
    </>
  );
};

export { UploadPatients };