import React, {FC} from "react";
import {Container} from 'lavaa';
import css from "./Survey.module.scss";
import {QuestionnairePage} from "./Questionnaire/Questionnaire.com";
import {useParams} from "react-router-dom";
import {QuestionnaireErrorPage} from "./Questionnaire/views/error/ErrorPage.com";

// Survey (Questionnaire Public)
const Survey: FC<any> = () => {
    const {surveyId} = useParams();

    if (!surveyId) {
        return <QuestionnaireErrorPage/>;
    }

    return (
        <Container className={css.Container}>
            <QuestionnairePage questionnaireKey={surveyId}/>
        </Container>
    );

};

export {Survey};