import { Route } from 'react-router-dom';
import { Patients } from "../Pages/Patients/Patients.component";
import { UploadPatients } from "../Pages/UploadPatients/UploadPatients.component";
import { PatientPage } from "../PatientPage/PatientPage.component";
import {Dashboard} from "../Pages/Dashboard/Dashboard.com";
import { Reports } from '../Reports/Reports.component';

// Routes
const FlowRoutes = [
    <Route key="/" path="/" element={ <Dashboard /> } />,
    <Route key="/patients" path="/patients" element={ <Patients /> } />,
    <Route key="/search/:search" path="/search/:search" element={ <Patients /> } />,
    <Route key="/patients/:patientId" path="/patients/:patientId" element={ <PatientPage /> } />,
    <Route key="/upload-patients" path="/upload-patients" element={ <UploadPatients /> } />,
    <Route key="/reports/:reportType" path="/reports/:reportType?" element={ <Reports /> } />,
    <Route key="/reports/:reportType/:reportSubType" path="/reports/:reportType/:reportSubType?" element={ <Reports /> } />
];

export { FlowRoutes };