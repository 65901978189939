import React, {FC, useContext} from "react";
import css from "../../../Survey.module.scss";
import {Col, Text} from "lavaa";
import {IQuestionnaireType} from "../../../../../../../Redux/Slices/Questionnaire/Questionnaire.data";
import {AppCtx} from "../../../../../../../Context/App.context";
import {useQuestionnaire} from "../../../../../../../Hooks/UseQuestionnaire";

type FieldType = {
    i: number,
    name: string,
    value: string,
}

interface IProps {
    inputs: {[k: string]: any},
}

// Questionnaire Confirm Fields
const QuestionnaireConfirmFields: FC<IProps> = React.memo((props) => {
    const {inputs} = props;

    const {deviceType} = useContext(AppCtx);

    const {questionnaireData} = useQuestionnaire();

    const preparedFields: FieldType[] = [];
    let n = 0;

    questionnaireData.forEach((question: IQuestionnaireType) => {
        const id = question.Parameter.ParameterID.toString();
        if(!inputs.hasOwnProperty(id)) return;

        let value = inputs[id];

        if(typeof value == "boolean"){
            value = value ? "Yes" : "No";
        }

        if(typeof value === 'number' && !Number.isNaN(value)){
            value = value.toString();
        }

        // TODO: Add more types (dates, multiple, etc.)

        preparedFields.push({
            i: ++n,
            name: question.QuestionText ?? "",
            value: value
        });
    });

    let titleSize: 'x3' | 'x5' = deviceType === 'desktop' ? 'x5' : 'x3';
    let textSize: 'x2' | 'x3' = deviceType === 'desktop' ? 'x3' : 'x2';

    return (
        <Col>
            {
                preparedFields.map((field: FieldType) => {
                    return(
                        <Col key={field.i} className={css.ConfirmField}>
                            <Col className={css.FieldTitle}>
                                <Text size={titleSize}>{field.i}. {field.name}</Text>
                            </Col>
                            <Col>
                                <Text size={textSize} bold={true}>{field.value}</Text>
                            </Col>
                        </Col>
                    )
                })
            }
        </Col>
    )
});

export {QuestionnaireConfirmFields};
