import React, {FC} from "react";
import {Col, iconsTypes, Row, Text, Dropdown} from "lavaa";
import css from "./GuidelineParams.module.scss";

const filterByDiagnosesData: any = [
    { id: "with-g", value: "Modules with guidelines" },
    { id: "without-g", value: "Modules without guidelines" },
    { id: "checked", value: "Completed guidelines" },
    { id: "unchecked", value: "Uncompleted guidelines" },
    { id: "all", value: "All" },
];
const filterByPriorityData: any = [
    { id: "checked", value: "Completed guidelines" },
    { id: "unchecked", value: "Uncompleted guidelines" },
    { id: "all", value: "All" },
];

interface IProps {
    active: boolean,
    onChangeToggle: (arg0: boolean) => void,
    guidelinesFilter: string,
    onChangeFilter: (arg0: string) => void
}

// Guideline Params
const GuidelineParams: FC<IProps> = React.memo((props) => {
    let { active, onChangeToggle, guidelinesFilter, onChangeFilter } = props;

    function selectHandler(data: any){
        onChangeFilter(data.id);
    }

    const filterData = active ? filterByPriorityData : filterByDiagnosesData;

    const selected = filterData.find((item: any) => item.id === guidelinesFilter);

    return (
        <Row alignitems="center" grow="1" justifycontent="space-between">
            <Col>
                <Dropdown icon={iconsTypes.filter} type="button-simplified" placeholder="Filter" data={ filterData } selected={selected} onSelect={selectHandler}></Dropdown>
            </Col>
            <Row alignitems="center" justifycontent="end">
                <Row onClick={() => {onChangeToggle(false)}} className={css.Wrapper} marginRight="1.75rem">
                    <Text text="by Diagnoses" size="x3" bold={true} className={!active ? css.ActiveText : css.Text}/>
                </Row>
                <Row onClick={() => {onChangeToggle(true)}} className={css.Wrapper}>
                    <Text text="by Priority" size="x3" bold={true} className={active ? css.ActiveText : css.Text}/>
                </Row>
            </Row>
        </Row>
    )
});

export {GuidelineParams};