import React, {FC, useEffect, useState} from "react";
import {Col} from "lavaa";
import {TreatmentDiagnose} from "./diagnose";
import {useDemo} from "../../../../Hooks/useDemo";
import {IClientGuidelinesType, IClientTreatmentType, IResultType} from "../../../../Redux/Slices/Demo/Demo.data";

type DiagnoseType = {
    result: IResultType,
    guidelines: IClientGuidelinesType[];
}

interface IProps {
    filter: string
}

// Guidelines By Diagnoses
const GuidelinesByDiagnoses: FC<IProps> = React.memo((props) => {

    const {filter} = props;
    const {activeClient} = useDemo();
    const [diagnoses, setDiagnoses] = useState<DiagnoseType[]>([]);
    const treatments = activeClient?.treatments;

    useEffect(() => {
        let localDiagnoses: DiagnoseType[] = activeClient?.results?.map((result: IResultType) => {
            let guidelines: IClientGuidelinesType[] = [];
            treatments?.map((treatment: IClientTreatmentType) => {
                if(treatment.moduleUID === result.moduleUID && treatment.guidelines){
                    guidelines.push(...treatment.guidelines);
                }
            });

            return {
                result: result,
                guidelines: guidelines
            };
        });

        if(!localDiagnoses) return;

        localDiagnoses.sort(compareDiagnoses);

        setDiagnoses(localDiagnoses);
    }, [activeClient?.client?.SourceClientID, filter]); // Re-render when client changes

    return (
        <Col>
            {
                diagnoses.map((diagnose: DiagnoseType) => {
                    let guidelines: IClientGuidelinesType[] = [];
                    treatments?.map((treatment: IClientTreatmentType) => {
                        if(treatment.moduleUID === diagnose.result.moduleUID && treatment.guidelines){
                            guidelines.push(...treatment.guidelines);
                        }
                    });

                    if(filter === "checked"){
                        guidelines = guidelines.filter((guideline: IClientGuidelinesType) => guideline.isChecked);
                    }

                    if(filter === "unchecked"){
                        guidelines = guidelines.filter((guideline: IClientGuidelinesType) => !guideline.isChecked);

                    }

                    if(filter === "without-g" && guidelines.length > 0) return;
                    if(
                        (filter === "with-g" || filter === "checked" || filter === "unchecked")
                        && guidelines.length === 0
                    ) return;

                    return (<TreatmentDiagnose result={diagnose.result} guidelines={guidelines} key={diagnose.result.moduleUID}/>)
                })
            }
        </Col>
    )
});

// Compare Diagnoses
// Stage 1: Sort by number of unchecked guidelines
// Stage 2: Sort by number of guidelines
// Stage 3: Sort by percentage of risk

function  compareDiagnoses(a: DiagnoseType, b: DiagnoseType): number{

    // Stage 1: Sort by number of unchecked guidelines
    const bCountUnchecked = b.guidelines.filter((guideline: IClientGuidelinesType) => !guideline.isChecked).length;
    const aCountUnchecked = a.guidelines.filter((guideline: IClientGuidelinesType) => !guideline.isChecked).length;

    if(bCountUnchecked != aCountUnchecked){
        return bCountUnchecked - aCountUnchecked;
    }

    // Stage 2: Sort by number of guidelines
    const bCount = b.guidelines.length;
    const aCount = a.guidelines.length;

    if(bCount != aCount){
        return bCount - aCount;
    }

    // Stage 3: Sort by percentage of risk
    const bPercentage = b.result.resultPercentage;
    const aPercentage = a.result.resultPercentage;

    return  bPercentage - aPercentage;
}

export {GuidelinesByDiagnoses};
