import React, {FC, useRef, useState} from 'react';
import {Text, Row, Col, iconsTypes, Icon} from "lavaa";
import css from './style.module.scss';
import {classNames} from "../../../../../Tools";

interface IProps {
    title: string,
    questions: number,
    children: any,
}

// Module
const Module: FC<IProps> = (props) => {

    const {title, questions, children} = props;

    const timerRef = useRef<any>(0)
    const [isActive, setIsActive] = useState(true);
    const [isOpen, setIsOpen] = useState(true);


    function toggle() {
        if(isActive) {
            clearTimeout(timerRef.current);
            timerRef.current = setTimeout(() => {
                setIsActive(false);
            }, 50);
            setIsOpen(false);
        }
        else {
            timerRef.current = setTimeout(() => {
                setIsOpen(true);
            }, 600);
            setIsActive(true);
        }
    }

    return (
        <Col className={css.ModuleBox}>
            <Row className={classNames(css.Module, isActive ? css.active : "")} alignitems="center" justifycontent="space-between" onClick={toggle}>
                <Row alignitems="center">
                    <Row marginRight="0.25rem"><Text size="x4" className={css.ModuleTitle}>Module:</Text></Row>
                    <Row><Text size="x4" bold={true} className={css.ModuleTitle} ellipsis={true} maxLines={1}>{title}</Text></Row>
                    <Row marginLeft="0.25rem">
                        <Text size="x2" className={css.ModuleQCount}>{questions} Question{questions != 1 ? 's' : ''}</Text>
                    </Row>
                </Row>
                <Row>
                    <Col className={classNames(css.Arrow, isActive ? css.active : "")} onClick={toggle}>
                        <Icon name={iconsTypes.arrowDown} size="x2"/>
                    </Col>
                </Row>
            </Row>
            <Col className={classNames(css.ContentBox, isActive ? css.active : "", isOpen ? css.open : "")}>
                <Col className={css.Content}>
                    {children}
                </Col>
            </Col>
        </Col>
    )
};

export {Module};