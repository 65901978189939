import React, {FC} from "react";
import css from "../style.module.scss";
import {Col, Row, Title, Text, iconsTypes, Button} from "lavaa";
import {QuestionnaireConfirmFields} from "../../../Questionnaire/confirm";

interface IProps {
    inputs: {[k: string]: any},
    onReturn: () => void
    onConfirm: () => void,
    loading: boolean
}

// Questionnaire Submit Page
const QuestionnaireSubmitPage: FC<IProps> = React.memo((props) => {

    const { onReturn, inputs, onConfirm, loading } = props;

    const questionsCount = Object.values(inputs).length;

    function confirmHandler(){
        if(loading) return;
        onConfirm();
    }

    return (
        <Col className={css.Wrapper}>
            <Row className={css.Header} paddingBottom="1.5rem" justifycontent="space-between">
                <Col>
                    <Row alignitems="center" justifycontent="space-between">
                        <Title size='x5'>Questionnaire</Title>
                        <Text size='x2'>{questionsCount} Question{questionsCount != 1 ? 's' : ''}</Text>
                    </Row>
                    <Row marginTop="0.5rem">
                        <Text size='x3' bold={true}>Kindly review your responses before proceeding and CONFIRM your answers</Text>
                    </Row>
                </Col>
            </Row>
            <Col paddingTop="2rem">
                <QuestionnaireConfirmFields inputs={inputs}/>

                <Col justifycontent="start" paddingBottom="1rem" marginTop="2rem">
                    <Row marginBottom="1rem">
                        <Button
                            icon={iconsTypes.arrowLeft}
                            text="Back to form"
                            type={"secondary"}
                            onClick={onReturn}
                            grow="1"
                        />
                    </Row>
                    <Row>
                        <Button
                            text="Confirm"
                            type={"primary"}
                            onClick={confirmHandler}
                            isLoading={loading}
                            grow="1"
                        />
                    </Row>
                </Col>
            </Col>
        </Col>
    )
});

export {QuestionnaireSubmitPage};
