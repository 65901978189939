import {FC, useContext, useEffect, useState} from "react";
import {Col, Row, ScrollContainer, Text, Checkbox, iconsTypes, DropdownMulti} from "lavaa";
import { classNames } from "../../../Tools";
import { useDemo } from "../../../Hooks/useDemo";
import { IModuleType } from "../../../Redux/Slices/Demo/Demo.data";
import { useLocalStorage } from "../../../Hooks/UseLocalStorage";
import css from "./ModulesMenu.module.scss";
import {AppCtx} from "../../../Context/App.context";

interface IProps {
  onModuleSelect: (data: IModuleType[]) => void
}

// ModulesMenu
const ModulesMenu: FC<IProps> = (props) => {

  const [ cacheModules, setCacheModules ] = useLocalStorage<Array<string>>("modules", []);
  let {deviceType} = useContext(AppCtx);

  const { onModuleSelect } = props;

  const { modules } = useDemo();
  const [init, setInit] = useState(false);
  const [ selectedItems, setSelectedItems ] = useState<IModuleType[]>([]);
  const [ dropdownReset, setDropdownReset ] = useState<number>(0);
  
  // On Select
  const onSelect = (item: IModuleType, val: any) => {
    let updateData;

    // Add
    if (val === true) {
      updateData = [...selectedItems, item];
      setSelectedItems(updateData);
    }

    // Delete
    else {
      updateData = [...selectedItems.filter((el: IModuleType) => el.ModuleUID !== item.ModuleUID)];
      setSelectedItems(updateData);
    }

    // setCacheModules(updateData.map((item: any) => item.ModuleUID));
    onModuleSelect(updateData);
  };

  // Check if checked
  const isItemChecked = ( item: IModuleType, selected: IModuleType[] ) => {
    return !!selected.find(( el: IModuleType ) => (el.ModuleUID === item.ModuleUID));
  };

  // Set Selected All Modules
  useEffect(() => {
    setSelectedItems([...modules]);
    onModuleSelect([...modules]);
    setDropdownReset((prev) => prev + 1);
  }, [modules]);

  // Init Cached Modules
  // useEffect(() => {
  //   return
  //   if (modules.length && cacheModules.length === 0 && !init) {
  //     setInit(true);
  //     let ignoreItems = ['OGTT'];
  //     let selectedModules = modules.filter((item: any) => !ignoreItems.includes(item.ModuleName));
  //     let moduleUIDs = selectedModules.map((item: any) => item.ModuleUID);
  //     setCacheModules(moduleUIDs);
  //     setSelectedItems([...selectedModules]);
  //     onModuleSelect([...selectedModules]);
  //   }

  //   // Restore
  //   else if (modules.length && cacheModules.length) {
  //     setInit(true);
  //     const restoreData = modules.filter((item: any) => cacheModules.includes(item.ModuleUID));
  //     setSelectedItems([...restoreData]);
  //     onModuleSelect([...restoreData]);
  //   }
  // }, [modules, cacheModules]);

  if(modules.length === 0){
    return null;
  }

  if(deviceType === "mobile"){
    return (
        <DropdownMulti
            wrap="row"
            initialSelected={selectedItems}
            hidePlaceholder={false}
            placeholder="Select"
            data={modules}
            displayField="ModuleName"
            selectField="ModuleUID"
            icon={iconsTypes.filter}
            onSelect={onModuleSelect}
            reset={dropdownReset}
            type="button"
        />
    )
  }

  return (
    <Col grow="1" paddingTop="1rem" paddingBottom="1rem" className={css.Menu}>
      <Row paddingBottom="1rem" paddingLeft="1.5rem" paddingRight="1.5rem">
        <Text text="Modules" size="x4" bold="true"/>
      </Row>
      <Row paddingBottom="1rem" paddingLeft="1.5rem" paddingRight="1.5rem">
        <Text text="Select one or more Module from list" size="x2"/>
      </Row>
      <Col grow="1">
        <ScrollContainer>
          {
            modules.map( ( module: IModuleType ) => {
              const isChecked = isItemChecked( module, selectedItems );
              return (
                <Row key={module.ModuleUID} alignitems="center" paddingLeft="1.5rem" paddingBottom="2rem">
                  <label htmlFor={ '__checkbox__' + module.ModuleUID } className={css.Item}>
                    <Row paddingRight="0.5rem" alignitems="center">
                      <Checkbox id={ '__checkbox__' + module.ModuleUID } checked={ isChecked } onChange={( val: boolean ) => onSelect( module, val )}/>
                    </Row>
                    <Text text={ module.ModuleName } size="x2" className={classNames(css.Label, isChecked ? css.Active : '')} bold="true"/>
                  </label>
                </Row>
              )
            })
          }
        </ScrollContainer>
      </Col>
    </Col>
  );
};

export { ModulesMenu };