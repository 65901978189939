import { Row, Cell, Col, Icon, iconsTypes, Text, clearLocalStorage } from 'lavaa';
import { NavLink } from "react-router-dom";
import { useAccount } from '../../Hooks/UseAccount';
import { ThemeSwitcher } from '../Custom/ThemeSwitcher/ThemeSwitcher.component';
import { FC, useContext } from 'react';
import css from './MenuProfile.module.scss';
import { ModalsCtx } from '../../Context/Modals.context';
import {AppCtx} from "../../Context/App.context";

interface IProps {
    closeHandler: () => void
}

// Menu Profile Dropdown
const MenuProfileDropdown: FC<IProps> = (props) => {
    const { closeHandler } = props;

    // Contexts
    let { setPerformanceNow } = useContext(AppCtx);

    // Contexts
    let { setHelpModal } = useContext(ModalsCtx);

    // Hooks
    const { logoutAccount } = useAccount();

    // Open Help
    const openHelp = () => {
        setHelpModal(true);
        closeHandler();
    };

    // Reset Layout
    const resetLayout = () => {
        clearLocalStorage(['BaseLayout']);
        setPerformanceNow(performance.now());
    };

    // Logout
    function logoutHandler(){
        logoutAccount();
    }

    return (
        <>
            <div className={css.DropdownLayer} onClick={closeHandler}/>
            <Col paddingBottom='1.75rem' className={css.Dropdown}>
                
                {/* Settings */}
                <NavLink to="settings" className={css.DropdownLink}>
                    <Row className={css.DropdownItem} alignitems='center' paddingLeft='1.5rem' paddingTop='1rem' paddingRight='1.5rem' paddingBottom='1rem'>
                        <Cell paddingTop='0' paddingBottom='0' paddingLeft='0' paddingRight='0.75rem' className={css.DropdownIcon}>
                            <Icon name={iconsTypes.gear}/>
                        </Cell>
                        <Text text='Settings' size='x2' bold='true' className={css.DropdownLabel}/>
                    </Row>
                </NavLink>

                {/* Help Center */}
                <Row className={css.DropdownItem} alignitems='center' paddingLeft='1.5rem' paddingTop='1rem' paddingRight='1.5rem' paddingBottom='1rem' onClick={openHelp}>
                    <Cell style={{color: 'var(--secondary-bg-icons-color)'}} paddingTop='0' paddingBottom='0' paddingLeft='0' paddingRight='0.75rem' className={css.DropdownIcon}>
                        <Icon name={iconsTypes.help}/>
                    </Cell>
                    <Text text="Help Center" size="x2" bold="true" className={css.DropdownLabel}/>
                </Row>

                <Row className={css.DropdownItem} alignitems='center' paddingLeft='1.5rem' paddingTop='1rem' paddingRight='1.5rem' paddingBottom='1rem' onClick={resetLayout}>
                    <Cell paddingTop='0' paddingBottom='0' paddingLeft='0' paddingRight='0.75rem' className={css.DropdownIcon}>
                        <Icon name={iconsTypes.reset}/>
                    </Cell>
                    <Text text='Reset layout' size='x2' bold='true' className={css.DropdownLabel}/>
                </Row>

                {/* Logout */}
                <Row className={css.DropdownItem} alignitems='center' paddingLeft='1.5rem' paddingTop='1rem' paddingRight='1.5rem' paddingBottom='1rem' onClick={logoutHandler}>
                    <Cell paddingTop='0' paddingBottom='0' paddingLeft='0' paddingRight='0.75rem' className={css.DropdownIcon}>
                            <Icon name={iconsTypes.logout}/>
                    </Cell>
                    <Text text='Logout' size='x2' bold='true' className={css.DropdownLabel}/>
                </Row>

                {/* Theme Switcher */}
                <ThemeSwitcher/>
            </Col>
        </>
    );
};

export { MenuProfileDropdown };