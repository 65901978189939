import {getQuestionnaireAction, getQuestionnaireStatisticsAction} from "./Questionnaire.slice";
import {addSnackbar} from "lavaa";
import {getDemoClientAction} from "../Demo/Demo.slice";

export const receiveAddQuestionnaireAnswerCallback = ( store: any, payload: any) => {
    if(payload?.resultCode !== 'OK') return;

    const clientID = payload.data?.clientID;
    const projectID = payload.data?.project;

    addSnackbar('success', 'Thank you for your answers!', 'Your form is successfully confirmed');

    // Update questionnaire data
    store.dispatch(getQuestionnaireAction([clientID, projectID]));
    store.dispatch(getQuestionnaireStatisticsAction([clientID, projectID]));

    // Update client data
    store.dispatch(getDemoClientAction([clientID, store.getState().demo.localFlowId, store.getState().account.HMSProjectId]));
}

export const receiveOpenQuestionnaireCallback = ( store: any, payload: any) => {
    const result = payload?.resultCode === 'OK';

    // Dispatch event to update questionnaire page state
    const event = new CustomEvent('ReceiveOpenQuestionnaire', {detail: result});
    window.dispatchEvent(event);
}

export const receiveAddOpenQuestionnaireAnswerCallback = ( store: any, payload: any) => {
    if(payload?.resultCode !== 'OK') return;

    // Dispatch event to update questionnaire page state
    const event = new CustomEvent('OpenQuestionnaireSubmitEvent');
    window.dispatchEvent(event);
}