import { useCallback, useEffect } from "react";
import debounce from 'lodash/debounce';

// User Activity Hook
export const useUserActivity = (logout: () => void) => {
    const checkInterval = 20 * 1000; // 20 seconds
    const inactivityLimit = 30 * 60 * 1000; // 30 minutes

    // Reset Timer
    const resetTimer = useCallback(() => {
        // console.log('Reset Timer');
        localStorage.setItem('lastActivity', Date.now().toString());
    }, []);

    // Handle Activity
    const handleActivity = useCallback(() => {
      resetTimer();
  }, [resetTimer]);

    // Handle Activity Debounce
    const handleActivityDebounce = useCallback(debounce(() => {
      resetTimer();
    }, 300), [resetTimer]);

    // Check Inactivity
    const checkInactivity = useCallback(() => {
        const lastActivity = localStorage.getItem('lastActivity');
        if (lastActivity) {
          const lastActivityTime = parseInt(lastActivity, 10);
          const currentTime = Date.now();
          if (currentTime - lastActivityTime > inactivityLimit) {
            logout();
          }
        }
    }, [logout]);

    // Watch for User Activity
    useEffect(() => {
        document.addEventListener('mousemove', handleActivityDebounce);
        document.addEventListener('click', handleActivity);
        document.addEventListener('keypress', handleActivity);
        document.addEventListener('touchmove', handleActivityDebounce);
        document.addEventListener('touchstart', handleActivity);
    
        // Init Timer
        resetTimer();
    
        const intervalId = setInterval(checkInactivity, checkInterval);
    
        return () => {
          clearInterval(intervalId);
          document.removeEventListener('mousemove', handleActivityDebounce);
          document.removeEventListener('click', handleActivity);
          document.removeEventListener('keypress', handleActivity);
          document.removeEventListener('touchmove', handleActivityDebounce);
          document.removeEventListener('touchstart', handleActivity);
        };
    }, []);
};