import {FC, useContext, useState} from 'react';
import { Col, Row, Cell, Input, Title, Button, Text } from 'lavaa';
import { useAccount } from '../../../../Hooks/UseAccount';
import css from '../Auth.module.scss';
import {AppCtx} from "../../../../Context/App.context";

// Login Page
const AuthLogin: FC<any> = () => {

    // Hooks
    const { loginAccount } = useAccount();
    const { deviceType } = useContext(AppCtx);

    const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

    // States
    const [login, setLogin] = useState( isDev ? 'lavaa_dev_user' : '');
    const [password, setPassword] = useState(isDev ? 'Lavaa!22!Dev' : '');

    function loginHandler(){
        loginAccount(login, password);
    }

    return (
        <Col className={ css.AuthPage } alignitems="center" justifycontent="center">

            {/* Form */}
            <Col className={ css.AuthForm } alignitems="center">
                <Title textAlign="center" grow="1" size={deviceType === "mobile" ? "x7" : "x8"} className={css.Title}>Welcome and Login</Title>

                <Col className={css.AuthInputs}>

                    {/* Login */}
                    <Row grow="1" paddingBottom="1.5rem">
                        <Input
                            type="text"
                            wrap="col"
                            label="*Username"
                            placeholder="........................................"
                            onChange={ setLogin }
                            value={login}
                            className={css.AuthInput}
                            labelClassName={css.AuthInputLabel}
                        />
                    </Row>

                    {/* Password */}
                    <Row grow="1" paddingBottom="1.5rem">
                        <Input
                            type="password"
                            wrap="col"
                            label="*Password"
                            placeholder="........................................"
                            onChange={ setPassword }
                            value={password}
                            className={css.AuthInput}
                            labelClassName={css.AuthInputLabel}
                        />
                    </Row>
                    <Cell grow="1" paddingBottom="1.5rem">
                        <Button text="Login"  size="large" grow="1" onClick={loginHandler}/>
                    </Cell>
                    <Cell paddingBottom="2rem" justifycontent="center">
                        <Text size="x3" textAlign="center" link="/login" className={css.AuthLinkForgot}>Forgot Password?</Text>
                    </Cell>
                    <Cell paddingBottom="2rem" justifycontent="center">
                        <Text size="x3" textAlign="center">Don't have an account?</Text>
                        <Text size="x3" textAlign="center" link="/registration" bold={true} className={css.AuthLinkSignUp}>Sign Up</Text>
                    </Cell>
                </Col>
            </Col>
        </Col>
    );
};

export { AuthLogin };