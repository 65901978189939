import {FC, useEffect, useState} from 'react';
import {Cell, Col, Icon, iconsTypes, Row, Text} from 'lavaa';
import css from './style.module.scss';
import {classNames} from "../../../Tools";

interface IProps {
    bgColor: string,
    text: string,
    percent: number,
    riskChange?: number
}

// Risk Level
const RiskLevel: FC<IProps> = (props) => {

    const {bgColor, text, percent, riskChange = 0} = props;

    const [percentLocal, setPercentLocal] = useState<number>(0); // set 0 for init animation

    useEffect(() => {
        setPercentLocal(percent);
    }, [percent]);

    return (
        <Col>
            <Row alignitems="center" marginBottom="0.5rem">
                <Text text={text} size="x1" bold={true}/>
                {
                    riskChange < 0 &&
                    <Col className={classNames(css.RiskChangeArrow, css.RiskArrowUp)}>
                        <Icon name={iconsTypes.riskArrowUp} size="x1"/>
                    </Col>
                }
                {
                    riskChange > 0 &&
                    <Col className={classNames(css.RiskChangeArrow, css.RiskArrowDown)}>
                        <Icon name={iconsTypes.riskArrowDown} size="x1"/>
                    </Col>
                }
            </Row>
            <Row>
                <Cell className={css.ProgressBar}>
                    <Cell className={css.ProgressBarFill} style={{width: `${percentLocal}%`, backgroundColor: bgColor}}/>
                </Cell>
            </Row>
        </Col>
    )
};

export {RiskLevel};
