import {createSlice} from '@reduxjs/toolkit';
import {IStatisticType} from "./Statistics.data";

interface IStatisticsTypeState {
    statistics: IStatisticType[]
}

const initialState: IStatisticsTypeState = {
    statistics: []
};

export const statisticsSlice = createSlice({
    name: 'Statistics',
    initialState,
    reducers: {

        // Get Project Statistics
        getProjectStatisticsAction: (state, action) => {
        },

        // Receive Questionnaire
        receiveProjectStatisticsAction: (state, action) => {
            console.warn('Receive Project Statistics Action --- ');
            console.log(action.payload);
            state.statistics = action.payload.data;
        },

        // Reset Project Statistics
        resetStatisticsAction: () => {
            return initialState;
        },
    }
});

export const {
    getProjectStatisticsAction,
    receiveProjectStatisticsAction,
    resetStatisticsAction
} = statisticsSlice.actions;
export default statisticsSlice.reducer;