import React, {FC, useContext, useEffect, useState} from "react";
import {Container, Grid, Splitter} from "lavaa";
import {ModuleSettingsModal} from "../../Custom/Modals/PersonEdit/PersonEditModal.component";
import {PatientLabResultModal} from "../../Custom/Modals/PatientLabResultModal/PatientLabResultModal.component";
import {AppCtx} from "../../../Context/App.context";
import {PatientPageContent} from "./Content/PatientPageContent";
import {PatientPageMenu} from "./Menu";
import {MenuWidgetsModal} from "../../Custom/Modals/MenuWidgets";
import {DiagnoseInfoModal} from "../../Custom/Modals/DiagnoseInfoModal/DiagnoseInfoModal.component";
import {QuestionnaireSide} from "./Questionnaire/side";
import css from "./style.module.scss";

// Patient Page Desktop
const PatientPageDesktop: FC<any> = React.memo(() => {

    // Contexts
    const { performanceNow } = useContext(AppCtx);

    //States
    const [activeTab, setActiveTab] = useState(0);
    const [screenSize, setScreenSize] = useState<number>(0);

    useEffect(() => {
        window.addEventListener('resize', checkScreenSize);

        function checkScreenSize() {
            setScreenSize(window.innerWidth);
        }

        checkScreenSize();

        return () => {
            window.removeEventListener('resize', checkScreenSize);
        }
    }, []);

    let collapsible= [0];
    let paneSizes = ['15%', '85%'];
    let minSizes: any = [250, 900];
    let splitterName = 'BaseLayout';

    if(screenSize <= 1450) {
        minSizes = [250, 850];
    }

    // Active tab is 3 (Basic Assessment / Questionnaire)
    const isQuestionnaire = activeTab === 3;
    let hasQuestionnaire = isQuestionnaire;

    if(isQuestionnaire) {
        if(screenSize > 1450) {
            collapsible = [0, 2];
            paneSizes = ['15%', '85%', '0%'];
            minSizes = [250, 900, 270];
            splitterName = 'QuestionnaireLayout';
        }
        else{
            hasQuestionnaire = false;
        }
    }

    return (
        <Container className={css.Container}>

            <Splitter resetLayoutKey={performanceNow} direction="vertical" collapsible={collapsible} paneSizes={paneSizes} minSizes={minSizes} splitterSize="10px" collapsedSplitterSize="20px" name={splitterName} grow="1">
                <Grid>
                    {/* Menu */}
                    <PatientPageMenu/>
                </Grid>
                <Grid>
                    {/* Pages */}
                    <PatientPageContent activeTab={activeTab} setActiveTab={setActiveTab}/>
                </Grid>
                {
                    hasQuestionnaire ? (
                        <QuestionnaireSide/>
                    ) : null
                }
            </Splitter>


            {/*  Person Edit Modal */}
            <ModuleSettingsModal/>

            {/* Modals */}
            <PatientLabResultModal/>
            <DiagnoseInfoModal/>

            {/*  Menu Widgets Modal */}
            <MenuWidgetsModal/>

        </Container>
    )
});

export {PatientPageDesktop};
