import React, { FC } from "react";
import { Col, Row, Text} from "lavaa";
import { useDemo } from "../../../../../Hooks/useDemo";
import { useClients } from "../../../../../Hooks/UseClients";
import { convertValueWithScheme } from "../../../../../Tools/schemeData";
import css from "./MedicalHistory.module.scss";

interface IData {
  label: string,
  value: string,
}

const MedicalHistory: FC = React.memo(() => {

  const { activeClient } = useDemo();
  const { clientScheme } = useClients();

  if ( !activeClient?.client?.MedicalHistories || !clientScheme?.MedicalHistories) return null;

  const rawData = activeClient.client.MedicalHistories[0];

  const data: IData[] = [];
  Object.entries( rawData ).forEach( ( [key, value] ) => {
    const scheme = clientScheme.MedicalHistories.subfields[key];
    if ( !scheme ) return;

    data.push(
      {
        label: scheme.label,
        value: convertValueWithScheme( scheme, value ),
      }
    )
  } );

  return (
    <Col paddingTop="0.5rem" paddingBottom="1rem" paddingLeft="1.25rem" paddingRight="1.25rem">
      {
        data.map( ( item: any, index: number ) => (
            <Row key={index} className={css.InfoItem}>
              <Text text={item.label} size="x2" ellipsis={true} className={css.ItemLabel} bold="true"/>
              <Text text={item.value} size="x2" className={css.ItemValue}/>
            </Row>
        ) )
      }
    </Col>
  )
});

export { MedicalHistory };
