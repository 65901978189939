import React, {FC, useContext} from "react";
import {Col, Row, Title, Text, ScrollContainer, Icon, iconsTypes} from "lavaa";
import css from "./style.module.scss";
import {Treatment} from "./treatment";
import {RiskLevel} from "../../RiskLevel";
import {IClientGuidelinesType, IResultType} from "../../../../Redux/Slices/Demo/Demo.data";
import {useDemo} from "../../../../Hooks/useDemo";
import {ModalsCtx} from "../../../../Context/Modals.context";
import {classNames} from "../../../../Tools";

interface IProps {
    result: IResultType,
    guidelines: IClientGuidelinesType[]
}

// Treatment Diagnose
const TreatmentDiagnose: FC<IProps> = React.memo((props) => {

    const {result, guidelines} = props;
    const { setPatientLabResultModalData, setPatientLabResultModalActive, setDiagnoseInfoModalActive, setDiagnoseInfoModalData } = useContext(ModalsCtx);
    let { activeClient } = useDemo();

    // On Report Label Click
    const onDiagnoseClick = () => {

        const data = {
            moduleName: result.moduleName,
            labResult: result,
            client: {name: activeClient.client.LastName}
        }
        setPatientLabResultModalData(data);
        setPatientLabResultModalActive(true);
    };

    // On More Info Click
    const onMoreInfoClick = (e: Event) => {
        e.stopPropagation();
        const data = {
            moduleName: result.moduleName,
            moduleUID: result.moduleUID,
        }
        setDiagnoseInfoModalData(data);
        setDiagnoseInfoModalActive(true);
    };

    const hasGuidelines = guidelines.length > 0;

    return (
        <Col>
            <Col className={classNames(css.Diagnose, hasGuidelines ? '' : css.NoGuidelines)}>
            <Row className={css.Header} alignitems="center" onClick={ onDiagnoseClick }>
                <Col grow="1" className={css.HeaderTexts}>
                    <Col marginBottom="0.25rem">
                        <Title size='x3' bold="true">{result.moduleName}</Title>
                    </Col>
                    <Row alignitems="center" onClick={onMoreInfoClick}>
                        <Col className={css.MoreIcon} marginRight="0.5rem">
                            <Icon name={iconsTypes.infoUnfilled} size="x1"/>
                        </Col>
                        <Text text="Info" size="x2" bold={true} className={css.MoreInfo}/>
                    </Row>
                    <Col>
                        {/* TODO - add description */}
                        {/*<Text size='x2' ellipsis={true} maxLines={2}>Lavaa ADA+ (diabetes, type 2) engine</Text>*/}
                    </Col>
                </Col>
                <Col shrink="0">
                    <RiskLevel
                        bgColor={result.bgColor}
                        text={result.resultGroupName}
                        percent={result.resultPercentage}
                        riskChange={result.resultTrend}
                    />
                </Col>
            </Row>

            {
                hasGuidelines && (
                    <ScrollContainer maxHeight={200}>
                        <Row className={css.ListWrapper} alignitems="start">
                            <div className={css.List}>
                                {
                                    guidelines.map((guideline: IClientGuidelinesType) => {
                                        return (
                                            <Treatment guideline={guideline} key={guideline.guidelineUID}/>
                                        )
                                    })
                                }
                            </div>
                        </Row>
                    </ScrollContainer>
                )
            }
        </Col>
        </Col>
    )
});

export {TreatmentDiagnose};
