import {FC} from "react";
import {Row, iconsTypes, Col, Icon, Text} from 'lavaa';
import css from "../../Dashboard.module.scss";

type Props = {
    type: 'noData' | 'moduleNotSelected';
    text?: string;
}

// Empty Statistics Chart
const EmptyChart: FC<Props> = ({type, text}) => {
    const label = type === 'noData' ? 'Chart has no data' : 'Choose filter module';

    return (
        <Col className={css.ChartEmpty} alignitems="center" justifycontent="center">
            <Row className={css.ChartEmptyIcon} marginBottom="0.75rem">
                <Icon name={iconsTypes.queryStats} size="x4"/>
            </Row>
            <Text size="x3" bold={true}>{text ? text : label}</Text>
        </Col>
    )
};

export {EmptyChart};