import React, {FC, useContext} from "react";
import {Button, Col, iconsTypes, Row, Text, ScrollContainer} from "lavaa";
import css from "./style.module.scss";
import {ModalsCtx} from "../../../../Context/Modals.context";
import {NurseDashboardMenuWidgets} from "./widgets";
import {useDemo} from "../../../../Hooks/useDemo";

// Patient Page Menu
const PatientPageMenu: FC<any> = React.memo(() => {

    const { activeClient } = useDemo();
    const { setMenuWidgetsModalActive } = useContext(ModalsCtx);

    if ( !activeClient?.client ) return null;

    function menuSettingsClickHandler() {
        setMenuWidgetsModalActive(true);
    }

    const name = ((activeClient.client?.FirstName ?? "") + " " + (activeClient.client?.LastName ?? "")).trim();

    return (
        <Col className={css.Menu}>
            <Row className={css.Config} justifycontent="end">
                <Button icon={iconsTypes.gear} type="gray" variant="text" iconSize="x1" onClick={ menuSettingsClickHandler } />
            </Row>
            <Col className={css.UserInfo}>
                <Row marginBottom="0.25rem">
                    <Text size="x5" bold="true">{name}</Text>
                </Row>
                <Row>
                    <Text size="x3">#{activeClient.client?.SourceClientID}</Text>
                </Row>
            </Col>
            <Row className={css.UserData} marginBottom="0.75rem">
                <Row marginRight="2rem">
                    <Col paddingRight="0.75rem">
                        <Text size="x2">Gender:</Text>
                    </Col>
                    <Col>
                        <Text size="x2" bold="true">{activeClient.client?.Gender === "F" ? "Female" : "Male"}</Text>
                    </Col>
                </Row>
                <Row>
                    <Col paddingRight="0.75rem">
                        <Text size="x2">Age:</Text>
                    </Col>
                    <Col>
                        <Text size="x2" bold="true">{activeClient.client?.FullYears}</Text>
                    </Col>
                </Row>
            </Row>
            <Col className={css.Widgets} grow="1">
                <ScrollContainer>
                    <NurseDashboardMenuWidgets/>
                </ScrollContainer>
            </Col>
        </Col>
    )
});

export {PatientPageMenu};
