import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ReportPayload, StateType } from './Reports.types';

const initialState: StateType = {
    reportsData: {},
    reportsDashboardData: {},
    snoozeUpdated: 0,
    clientDetails: {
        email: [],
        phones: [],
        address: ''
    },
    clientDetailsLastUpdate: 0
};

export const reportsSlice = createSlice({
    name: 'Reports',
    initialState,
    reducers: {

        // Get Client Details By Id
        getClientDetailsByIdAction: (state: StateType, action: PayloadAction<any>) => {},

        // Receive Client Details
        receiveClientDetailsAction: (state: StateType, action: PayloadAction<any>) => {
            const { data = {} } = action.payload;
            const normalizeValue = (value: any) => {
                try {
                    return JSON.parse(value);
                } catch (error) {
                    return value;
                }
            };

            if (data.Data) {
                const email = normalizeValue(data.Data.Email) || [];
                const phones = normalizeValue(data.Data.Phones) || [];
                const address = normalizeValue(data.Data.Address) || '';
                const clientDetails = { email, phones, address };
    
                state.clientDetails = clientDetails;
                state.clientDetailsLastUpdate = performance.now();
            }
        },

        // Clear Client Details
        clearClientDetailsAction: (state: StateType) => {
            state.clientDetails = initialState.clientDetails;
            state.clientDetailsLastUpdate = initialState.clientDetailsLastUpdate;
        },

        // Get Reports
        getReportsAction: (state: StateType, action: PayloadAction<any>) => {},

        // Get Reports With ID
        getReportsWithPagerAction: (state: StateType, action: PayloadAction<any>) => {},

        // Run Reports Action
        runReportsAction: (state: StateType, action: PayloadAction<any>) => {},

        // Receive Reports By Parent
        receiveReportsAction: (state: StateType, action: PayloadAction<ReportPayload>) => {
            const { payload } = action;
            
            const { data } = payload;
            const { resultID, reportName, headers, rows, totalRows, totalPages } = data;

            const reportData = {
                reportName,
                headers,
                rows,
                totalRows,
                totalPages,
                lastUpdated: performance.now()
            }

            if(resultID == "dashboard"){
                state.reportsDashboardData[reportName] = reportData;
            }
            else{
                state.reportsData[reportName] = reportData;
            }
        },

        // Receive Reports Run Action
        receiveReportsRunAction: (state: StateType, action: PayloadAction<any>) => {
            const { data } = action.payload;
            state.snoozeUpdated = data;
        }
    }
});

export const {
    getClientDetailsByIdAction,
    clearClientDetailsAction,
    getReportsAction,
    getReportsWithPagerAction,
    runReportsAction,
    receiveReportsAction
} = reportsSlice.actions;
export default reportsSlice.reducer;