import React, {FC, RefObject, useEffect, useRef, useState} from "react";
import css from "../style.module.scss";
import {Col, Row, Title, Text, Dropdown, iconsTypes, Toggle, ScrollContainer, Button, Tooltip} from "lavaa";
import {calculateActiveElement} from "../util";
import {useLocalStorage} from "../../../../../Hooks/UseLocalStorage";
import {useQuestionnaire} from "../../../../../Hooks/UseQuestionnaire";
import {useDemo} from "../../../../../Hooks/useDemo";
import {IModuleType} from "../../../../../Redux/Slices/Demo/Demo.data";
import {QuestionnaireFields} from "../fields";
import {classNames} from "../../../../../Tools";

interface IProps {
    onSubmit: (filledInputs: {[k: string]: any}, confirmImmediately: boolean) => void
    inputs: any,
    onChange: (field: string, value: any) => void,
    loading: boolean
}

// Questionnaire Form Page
const QuestionnaireFormPage: FC<IProps> = React.memo((props) => {

    const { onSubmit, inputs, onChange, loading } = props;

    const [ viewDefault, setViewDefault  ] = useLocalStorage<boolean>("questionnaireDesktopView", true);
    const viewDefaultRef = useRef<boolean>(viewDefault);

    const [ filter, setFilter  ] = useState<string>("all");

    const [activeItem, setActiveItem] = React.useState<string>('');
    const scrollRef: RefObject<any> = useRef(null);

    const [showSubmit, setShowSubmit] = useState<boolean>(false);

    const {questionnaireData} = useQuestionnaire();
    const { modules } = useDemo();

    const filterData: any = modules.map(( module: IModuleType ) => {
        return{
            id: module.ModuleUID,
            value: module.ModuleName
        }
    });
    filterData.push({id: 'all', value: 'Show All'});

    const selectedFilter = filterData.find((item: any) => item.id === filter);

    useEffect(() => {
        setShowSubmit(false);
    }, [questionnaireData]);

    useEffect(() => {
        validateFields();
    }, [inputs]);

    useEffect(() => {

        // Select which item is active
        function scrollHandler() {
            const activeIndex = calculateActiveElement(scrollRef.current);
            if (!viewDefaultRef.current) {
                setActiveItem(activeIndex);
            }
        }

        scrollRef.current.addEventListener("scroll", scrollHandler);
        scrollHandler();

        return () => {
            scrollRef.current?.removeEventListener("scroll", scrollHandler);
        }
    }, []);

    function selectFilterHandler(data: any){
        setFilter(data.id);
    }

    function handleChangeView() {
        const newStatus = !viewDefault;
        setViewDefault(newStatus);
        viewDefaultRef.current = newStatus;

        if(!newStatus){
            const activeIndex = calculateActiveElement(scrollRef.current);
            setActiveItem(activeIndex);
        }
    }

    function validateFields(){
        const filledInputs: any = {};
        let canSubmit = false;

        Object.entries(inputs).map(([key, value]) => {
            if( value !== null && value !== undefined && value !== ''){
                // TODO - add input validation
                filledInputs[key] = value;
                canSubmit = true;
            }
        });

        setShowSubmit(canSubmit);
        return filledInputs;
    }

    function submit(confirmImmediately: boolean = false) {
        if(!showSubmit) return;

        const filledInputs: any = validateFields();

        if(Object.keys(filledInputs).length === 0) {
            setShowSubmit(false);
            return;
        }

        onSubmit(filledInputs, confirmImmediately);
    }

    const tooltipText: string = viewDefault ? "Uncheck Screen mode to see one question at a time" : "Check Screen mode to see all questions at a time";

    const questionsCount = questionnaireData.length;

    return (
        <Col className={css.Wrapper}>
            <Row marginBottom="2rem" alignitems="center" justifycontent="space-between">
                <Row alignitems="center" className={css.Container} grow="1">
                    <Col className={css.Title}>
                        <Title size='x6'>Questionnaire</Title>
                    </Col>
                    <Col marginLeft="1.75rem">
                        <Text size='x3'>{questionsCount} Question{questionsCount != 1 ? 's' : ''}</Text>
                    </Col>
                    <Col className={css.Filter}>
                        <Dropdown icon={iconsTypes.filter} type="button-simplified" placeholder="Filter" hasSearch={true} data={ filterData } selected={selectedFilter} onSelect={selectFilterHandler}/>
                    </Col>
                    <Row grow="1" marginLeft="1.75rem" marginRight="1.75rem" justifycontent="end">
                        <Col className={css.ViewIcon}>
                            <Tooltip positionH="center" positionV="bottom" tooltipText={tooltipText} icon={iconsTypes.accessibility}/>
                        </Col>
                        <Toggle
                            onToggle={handleChangeView}
                            active={viewDefault}
                        />
                    </Row>
                </Row>
                {/* Paginator */}
                {/*<Row alignitems="center">*/}
                {/*    <Paginator type="compact" page={1} changePage={() => {}} perPage={10} currentItems={data.length}/>*/}
                {/*</Row>*/}
            </Row>
            <ScrollContainer scrollRef={scrollRef}>
                <Col className={classNames(css.FieldsWrapper, questionsCount > 0 && css._hasContent, questionsCount === 1 && css._hasOneField)}>
                    <QuestionnaireFields
                        questionnaireData={questionnaireData}
                        inputs={inputs}
                        onChange={onChange}
                        filter={filter}
                        viewDefault={viewDefault}
                        activeItem={activeItem}
                    />

                    {/* Submit Buttons */}
                    <Row>
                    {
                        (questionsCount > 0) && (
                            <>
                                {/* Save results */}
                                <Row justifycontent="start">
                                    <Button
                                        text="Submit"
                                        type={showSubmit ? "primary" : "disable"}
                                        onClick={() => submit(true)}
                                        isLoading={loading}
                                    />
                                </Row>

                                {/* Review + Save */}
                                {
                                    (!loading) && (
                                        <Row justifycontent="start" paddingLeft="1rem">
                                            <Button
                                                text="Review + Save"
                                                type={showSubmit ? "secondary" : "disable"}
                                                onClick={() => submit(false)}
                                            />
                                        </Row>
                                    )
                                }
                            </>
                        )
                    }
                    </Row>
                </Col>
            </ScrollContainer>
        </Col>
    )
});

export {QuestionnaireFormPage};
