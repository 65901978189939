import { useDispatch, useSelector } from 'react-redux';
import mixpanel from 'mixpanel-browser';
import {
    getAccountAction,
    loginAccountAction,
    logoutAccountAction,
    registrationAccountAction, selectUserProjectsAction
} from '../Redux/Slices/Account/Account.slice';
import { setLocalStorage } from './UseLocalStorage';
import { resetDemoAction } from "../Redux/Slices/Demo/Demo.slice";
import { resetStatisticsAction } from "../Redux/Slices/Statistics/Statistics.slice";
import {clearLocalStorage} from "lavaa";

export const useAccount = (): any => {
    const account = useSelector((state: any) => state.account);
    const HMSProjectId = useSelector((state: any) => state.account.HMSProjectId);
    const accountLogo = useSelector((state: any) => state.account.logo);
    const poweredBy = useSelector((state: any) => state.account.poweredBy);
    const projects = useSelector((state: any) => state.account.projects);
    const availableReports = useSelector((state: any) => state.account.availableReports);
    const dispatch = useDispatch();
    
    // Is Auth
    const isAuth = account.id !== null;

    // Fetch Account
    const fetchAccount = () => {
        console.log('Fetch Account --- ')
        dispatch(getAccountAction([account.access_token]));
    };

    // Login Account
    const loginAccount = (login: string, password: string) => {
        dispatch(loginAccountAction([login, password]));
    };

    // Registration Account
    const registrationAccount = (data: any) => {
        const { name, email, password} = data;
        dispatch(registrationAccountAction([{
            name,
            email,
            password
        }]));
    };

    // Logout Account
    const logoutAccount = () => {
        setLocalStorage("modules", []); // Clear modules cache
        dispatch(resetDemoAction()); // Reset demo redux storage
        setLocalStorage("refresh_token", ""); // Temporary
        setLocalStorage("access_token", ""); // Temporary

        setLocalStorage("HMSProjectId", null); // Clear HMSProjectId

        dispatch(resetStatisticsAction()); // Reset statistics redux storage
        setLocalStorage("dashboard-modules-statistics", undefined); // Reset statistics selected modules

        if (mixpanel.hasOwnProperty('config') === true) {
            mixpanel.reset();
        }
        dispatch(logoutAccountAction([]));
    };

    function selectProject(projectId: string) {
        // Clear statistics graph cache
        clearLocalStorage(['dashboard-modules-statistics']);

        // Update selected projectId
        dispatch(selectUserProjectsAction([projectId]));
        setLocalStorage("HMSProjectId", projectId);
    }

    // Get Account Display Name
    const getAccountDisplayName = () => {
        let displayName = account.name;
        account.details && Array.isArray(account.details) && account.details.forEach((item: any) => {
            if(item.name === 'DisplayName') {
                displayName = item.value;
            }
        });
        return displayName;
    };

    return {
        fetchAccount, loginAccount, registrationAccount, logoutAccount, selectProject, getAccountDisplayName,
        account, isAuth, HMSProjectId, accountLogo, poweredBy, projects, availableReports
    };
}