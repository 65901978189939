export const labResultsRanges = [{
    "name": "FastingGlucose",
    "normal": [{
        "min": 70.01,
        "max": 110
    }
    ],
    "high": [{
        "min": 45.01,
        "max": 70
    }, {
        "min": 110.01,
        "max": 125
    }
    ],
    "extreme": [{
        "max": 45
    }, {
        "min": 125.01
    }
    ]
}, {
    "name": "TotalCholesterol",
    "normal": [{
        "min": 110.01,
        "max": 200
    }
    ],
    "high": [{
        "min": 200.01,
        "max": 240
    }
    ],
    "extreme": [{
        "max": 110
    }, {
        "min": 240.01
    }
    ]
}, {
    "name": "HDLCholesterol",
    "normal": [{
        "min": 40.01
    }
    ],
    "high": [{
        "min": 35.01,
        "max": 40
    }
    ],
    "extreme": [{
        "max": 35
    }
    ]
}, {
    "name": "Triglycerides",
    "normal": [{
        "max": 150
    }
    ],
    "high": [{
        "min": 150.01,
        "max": 200
    }
    ],
    "extreme": [{
        "min": 200.01
    }
    ]
}, {
    "name": "HBA1C",
    "normal": [{
        "max": 5.7
    }
    ],
    "high": [{
        "min": 5.71,
        "max": 6.2
    }
    ],
    "extreme": [{
        "min": 6.21
    }
    ]
}, {
    "name": "Sodium",
    "normal": [{
        "min": 135.01,
        "max": 145
    }
    ],
    "high": [{
        "min": 130.01,
        "max": 135
    }, {
        "min": 145.01,
        "max": 150
    }
    ],
    "extreme": [{
        "max": 130
    }, {
        "min": 150.01
    }
    ]
}, {
    "name": "Potassium",
    "normal": [{
        "min": 3.51,
        "max": 5.1
    }
    ],
    "high": [{
        "min": 3.21,
        "max": 3.5
    }, {
        "min": 5.11,
        "max": 6
    }
    ],
    "extreme": [{
        "max": 3.2
    }, {
        "min": 6.01
    }
    ]
}, {
    "name": "Albumin",
    "normal": [{
        "min": 3.51,
        "max": 5.2
    }
    ],
    "high": [{
        "min": 3.31,
        "max": 3.5
    }, {
        "min": 5.21,
        "max": 6
    }
    ],
    "extreme": [{
        "max": 3.3
    }, {
        "min": 6.01
    }
    ]
}, {
    "name": "LDLCholesterol",
    "normal": [{
        "min": 40.01,
        "max": 100
    }
    ],
    "high": [{
        "min": 100.01,
        "max": 160
    }
    ],
    "extreme": [{
        "max": 40
    }, {
        "min": 160.01
    }
    ]
}, {
    "name": "Hemoglobin",
    "gender": "M",
    "normal": [{
        "min": 14.01,
        "max": 16.6
    }
    ],
    "high": [{
        "min": 12.01,
        "max": 14
    }, {
        "min": 16.61,
        "max": 17.2
    }
    ],
    "extreme": [{
        "max": 12
    }, {
        "min": 17.21
    }
    ]
}, {
    "name": "Hemoglobin",
    "gender": "F",
    "normal": [{
        "min": 12.11,
        "max": 15.1
    }
    ],
    "high": [{
        "min": 10.51,
        "max": 12.1
    }, {
        "min": 15.11,
        "max": 17.2
    }
    ],
    "extreme": [{
        "max": 10.5
    }, {
        "min": 17.21
    }
    ]
}
];