import { FC } from 'react';
import { Row, Col, Text } from 'lavaa';

type Props = {
    value: string[] | number[] | string;
};

// ArraySimpleCellRenderer Component
export const ArraySimpleCellRenderer: FC<Props> = (props: Props) => {
    let { value = [] } = props;

    // Chech if array present as string
    if (typeof value === 'string' && /^\[.*?\]$/.test(value)) {
        try {
            value = JSON.parse(value);
        } catch (e: any) {
            console.log('ERROR', e);
        }
    }

    // If value is null
    if (value === null) {
        value = [];
    }
    // console.log('value', value, typeof value);
    
    return <Col shrink="1" justifycontent="center" alignitems="center" style={{whiteSpace: 'normal'}} paddingTop="0.25rem" paddingBottom="0.25rem">
        {
            (value as [])?.map((item: any, index: number) => {
                return <Row key={'arrCellItem' + item + index} paddingBottom="0.25rem">
                    <Text size="x2" style={{textAlign: 'center'}} key={index}>{item}</Text>
                </Row>
            })
        }
    </Col>
};