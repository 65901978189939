import React, {FC, useContext} from "react";
import {Row, Grid, Title, Dropdown, iconsTypes, Container, ScrollContainer, Col, Button} from 'lavaa';
import css from "./Dashboard.module.scss";
import {TotalGenderStat} from "./Charts/totalGenderStat";
import {TotalAgeStat} from "./Charts/totalAgeStat";
import {GenderStat} from "./Charts/genderStat";
import {AgeStat} from "./Charts/ageStat";
import {PatientsStat} from "./Charts/patientsStat";
import {ModulesStat} from "./Charts/modulesStat";
import {DashboardReport} from "./Charts/reports/DashboardReport.com";
import {DashboardReportNames} from "./Charts/reports/DashboardReport.data";
import {ModalsCtx} from "../../../Context/Modals.context";
import {DashboardCards} from "./Dashboard.data";

type Props = {
	filterData: any,
	selectedFilter: any,
	selectFilterHandler: (data: any) => void,
	filterModuleId: string | undefined,
}

// Dashboard Mobile
const DashboardMobile: FC<Props> = ({
	filterData,
	selectedFilter,
	selectFilterHandler,
	filterModuleId
}) => {

	const { menuDashboardCards, setMenuDashboardCardsModalActive } = useContext(ModalsCtx);

	function clickSelectCardsHandler(){
		setMenuDashboardCardsModalActive(true)
	}

	const cardsComponents = {
		[DashboardCards.AgeStat.key]: <AgeStat moduleId={filterModuleId}/>,
		[DashboardCards.ModulesStat.key]: <ModulesStat moduleId={filterModuleId}/>,
		[DashboardCards.GenderStat.key]: <GenderStat moduleId={filterModuleId}/>,
		[DashboardCards.PatientsStat.key]: <PatientsStat moduleId={filterModuleId}/>,
		[DashboardCards.TotalAgeStat.key]: <TotalAgeStat moduleId={filterModuleId}/>,
		[DashboardCards.TotalGenderStat.key]: <TotalGenderStat moduleId={filterModuleId}/>,
		[DashboardCards.DischargeLast48HReport.key]: <DashboardReport reportName={DashboardReportNames.DischargeLast48H}/>,
		[DashboardCards.HighWorryScoreReport.key]: <DashboardReport reportName={DashboardReportNames.HighWorryScore} initialSorting={{sortedByField: 'WorryScore', isAscending: false}}/>,
		[DashboardCards.LongOverdueReport.key]: <DashboardReport reportName={DashboardReportNames.LongOverdue} initialSorting={{sortedByField: 'Overdue', isAscending: false}}/>,
		[DashboardCards.NoAwvThisYearReport.key]: <DashboardReport reportName={DashboardReportNames.NoAwvThisYear}/>,
		[DashboardCards.NoAwvThisYearLastYearReport.key]: <DashboardReport reportName={DashboardReportNames.NoAwvThisYearLastYear}/>,
		[DashboardCards.GeneralReport.key]: <DashboardReport reportName={DashboardReportNames.General} reportLink="/reports/generalReport"/>,
		[DashboardCards.DischargeTCMReport.key]: <DashboardReport reportName={DashboardReportNames.DischargeTCM} reportLink="/reports/tcm/DischargeTCM"/>,
		[DashboardCards.AdmissionTCMReport.key]: <DashboardReport reportName={DashboardReportNames.AdmissionTCM} reportLink="/reports/tcm/AdmissionTCM"/>,
	}

	return (
		<Container paddingTop="1.5rem" paddingBottom="1.5rem">
			<ScrollContainer addPadding={false}>
				<Col paddingBottom="1.75rem" paddingLeft="1.75rem" paddingRight="1.75rem" >

					{/* Title */}
					<Row grow="1" style={{whiteSpace: 'nowrap'}}>
						<Title className={css.Title} size='x4'>Dashboard</Title>
					</Row>

					{/* Filter */}
					<Row alignitems="center" grow="0" paddingTop="1.75rem">

						{/* Drop Down */}
						<Dropdown
							icon={iconsTypes.filter}
							placeholder="Select"
							hasSearch={true}
							data={filterData}
							selected={selectedFilter}
							onSelect={selectFilterHandler}
							selectView="mobile-filter"
							type="button"
						/>
					</Row>

					{/* Filter */}
					<Row alignitems="center" grow="0" paddingTop="1.75rem">
						<Button
							type="secondary"
							text="Shown Charts"
							size="medium"
							grow="1"
							icon={iconsTypes.gridView}
							onClick={clickSelectCardsHandler}
						/>
					</Row>
				</Col>
				<Grid className={css.Grid}>
					{
						Object.keys(DashboardCards).map((key) => {
							if(menuDashboardCards.includes(key)){
								return (
									<React.Fragment key={key}>
										{cardsComponents[key]}
									</React.Fragment>
								)
							}
						})
					}
				</Grid>
			</ScrollContainer>
		</Container>
	);

};

export {DashboardMobile};