import React, {FC, useContext} from "react";
import {Col, Row, Title} from "lavaa";
import css from "./style.module.scss";
import {LabResults} from "../Widgets/LabResults/LabResults.component";
import {MedicalHistory} from "../Widgets/MedicalHistory/MedicalHistory.component";
import {HighRisk} from "../Widgets/HighRisk";
import {Treatments} from "../Widgets/Treatments";
import {PersonalInfo} from "../Widgets/PersonalInfo";
import {ModalsCtx} from "../../../../Context/Modals.context";
import {MenuWidgets} from "../../../Custom/Modals/MenuWidgets/type";
import {LastUpdate} from "../Widgets/LastUpdate";
import {Vitals} from "../Widgets/Vitals";

// Nurse Dashboard Menu Widgets
const NurseDashboardMenuWidgets: FC<any> = React.memo(() => {

    const { menuWidgets } = useContext(ModalsCtx);

    const menuWidgetsComponents = {
        [MenuWidgets.HighRisk]: <HighRisk/>,
        [MenuWidgets.PersonalInfo]: <PersonalInfo/>,
        [MenuWidgets.Guidelines]: <Treatments/>,
        [MenuWidgets.MedicalHistory]: <MedicalHistory/>,
        [MenuWidgets.LabResults]: <LabResults/>,
        [MenuWidgets.Vitals]: <Vitals/>,
    }

    return (
        <Col className={css.WidgetsContent}>
            {/* Last Update Widget */}
            <LastUpdate/>

            {/* Widgets */}
            {
                menuWidgets.map((widget: MenuWidgets) => {
                    if(!menuWidgetsComponents[widget]) return null;
                    return(
                        <Col className={css.Widget} key={widget}>
                            <Row className={css.Title}>
                                <Title size='x4'>{widget}</Title>
                            </Row>
                            {menuWidgetsComponents[widget]}
                        </Col>
                    )
                })
            }
        </Col>
    )
});

export {NurseDashboardMenuWidgets};
