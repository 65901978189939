import React, {FC, useContext, useEffect, useState} from "react";
import css from "../style.module.scss";
import {Col, Row, Title, Text, Dropdown, iconsTypes, Button} from "lavaa";
import {useQuestionnaire} from "../../../../../Hooks/UseQuestionnaire";
import {useDemo} from "../../../../../Hooks/useDemo";
import {IModuleType} from "../../../../../Redux/Slices/Demo/Demo.data";
import {AppCtx} from "../../../../../Context/App.context";
import {QuestionnaireFields} from "../Module/fields";

interface IProps {
    onSubmit: (filledInputs: {[k: string]: any}, confirmImmediately: boolean) => void
    inputs: any,
    onChange: (field: string, value: any) => void,
    loading: boolean
}

// Questionnaire Form Page
const QuestionnaireFormPage: FC<IProps> = React.memo((props) => {

    const { onSubmit, inputs, onChange, loading } = props;

    const {deviceType} = useContext(AppCtx);

    const [ filter, setFilter  ] = useState<string>("all");

    const {questionnaireData} = useQuestionnaire();
    const { modules } = useDemo();

    const [showSubmit, setShowSubmit] = useState<boolean>(false);

    useEffect(() => {
        setShowSubmit(false);
    }, [questionnaireData]);

    useEffect(() => {
        validateFields();
    }, [inputs]);

    function validateFields(){
        const filledInputs: any = {};
        let canSubmit = false;

        Object.entries(inputs).map(([key, value]) => {
            if( value !== null && value !== undefined && value !== ''){
                // TODO - add input validation
                filledInputs[key] = value;
                canSubmit = true;
            }
        });

        setShowSubmit(canSubmit);
        return filledInputs;
    }

    function submit(confirmImmediately: boolean = false) {
        if(!showSubmit) return;

        const filledInputs: any = validateFields();

        if(Object.keys(filledInputs).length === 0) {
            setShowSubmit(false);
            return;
        }

        onSubmit(filledInputs, confirmImmediately);
    }

    const filterData: any = modules.map(( module: IModuleType ) => {
        return{
            id: module.ModuleUID,
            value: module.ModuleName
        }
    });
    filterData.push({id: 'all', value: 'Show All'});

    const selectedFilter = filterData.find((item: any) => item.id === filter);

    function selectFilterHandler(data: any){
        setFilter(data.id);
    }

    const questionsCount = questionnaireData.length;

    return (
        <Col className={css.Wrapper}>
            <Row className={css.Header} paddingBottom="1.5rem" justifycontent="space-between">
                <Col>
                    <Row>
                        <Title size='x5'>Questionnaire</Title>
                    </Row>
                    <Row marginTop='0.5rem'>
                        <Text size='x2'>{questionsCount} Question{questionsCount != 1 ? 's' : ''}</Text>
                    </Row>
                </Col>
                <Col>
                    <Dropdown icon={iconsTypes.filter} selectView="mobile-filter" type="button-simplified" placeholder="Filter" hasSearch={true} data={ filterData } selected={selectedFilter} onSelect={selectFilterHandler}/>
                </Col>
            </Row>
            <Col paddingTop="2rem">
                <QuestionnaireFields
                    questionnaireData={questionnaireData}
                    inputs={inputs}
                    onChange={onChange}
                    filter={filter}
                />
                {
                    (questionnaireData.length > 0) && (
                        <>
                            {/* Save results */}
                            <Row justifycontent="start">
                                <Button
                                    text="Submit"
                                    type={showSubmit ? "primary" : "disable"}
                                    grow={deviceType === "mobile" ? "1" : "0"}
                                    onClick={() => submit(true)}
                                    size={deviceType === "mobile" ? "large" : "default"}
                                    isLoading={loading}
                                />
                            </Row>

                            {/* Review + Save */}
                            {
                                (!loading) && (
                                    <Row justifycontent="start" paddingTop="1rem">
                                        <Button
                                            text="Review + Save"
                                            type={showSubmit ? "secondary" : "disable"}
                                            grow={deviceType === "mobile" ? "1" : "0"}
                                            onClick={() => submit(false)}
                                            size={deviceType === "mobile" ? "large" : "default"}
                                        />
                                    </Row>
                                )
                            }
                        </>
                    )
                }
            </Col>
        </Col>
    )
});

export {QuestionnaireFormPage};
