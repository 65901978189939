import { FC } from 'react';
import { Row } from 'lavaa';
import { Logo } from '../../Logo/Logo.component';
import css from './Header.module.scss';
import {inIframe} from "../../../Tools/iframe";

// Header
const HeaderPublic: FC<any> = () => {
    
    const isIframe = inIframe();
    if(isIframe) return null;

    return <Row className={ css.Header } shrink="0">

        {/* Logo */}
        <Logo/>

    </Row>;
};

export { HeaderPublic };