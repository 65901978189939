import React, {FC, useCallback, useContext, useEffect} from "react";
import {Grid} from 'lavaa';
import {ModalsCtx} from "../../../../Context/Modals.context";
import {
	closestCenter,
	DndContext,
	MouseSensor,
	TouchSensor,
	useSensor,
	useSensors
} from "@dnd-kit/core";
import type {DragEndEvent} from "@dnd-kit/core/dist/types";
import {arrayMove, rectSortingStrategy, SortableContext} from "@dnd-kit/sortable";
import {DashboardDesktopCard} from "./DashboardDesktopCard.com";
import css from "../Dashboard.module.scss";

type Props = {
	filterModuleId: string | undefined,
}

// Dashboard Desktop Grid
const DashboardDesktopGrid: FC<Props> = ({
	filterModuleId
}) => {

	const { menuDashboardCards, setMenuDashboardCards } = useContext(ModalsCtx);
	const menuDashboardCardsRef = React.useRef(menuDashboardCards);
	const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));


	useEffect(() => {
		menuDashboardCardsRef.current = menuDashboardCards;
	}, [menuDashboardCards]);

	const handleDragEnd = useCallback((event: DragEndEvent) => {
		const { active, over } = event;
		
		if(!active || !over || !active.hasOwnProperty('id') || !over?.hasOwnProperty('id')) return;


		if (active.id !== over?.id) {
			const oldIndex = menuDashboardCardsRef.current.indexOf(active.id as string);
			const newIndex = menuDashboardCardsRef.current.indexOf(over!.id as string);

			const newCardsSort = arrayMove(menuDashboardCardsRef.current, oldIndex, newIndex);
			setMenuDashboardCards(newCardsSort);
		}
	}, []);

	return (
		<DndContext
			sensors={sensors}
			collisionDetection={closestCenter}
			onDragEnd={handleDragEnd}
		>
			<SortableContext items={menuDashboardCards} strategy={rectSortingStrategy}>
				<Grid className={css.Grid}>
					{
						menuDashboardCards.map((cardKey: string) => (
							<DashboardDesktopCard cardKey={cardKey} filterModuleId={filterModuleId} key={cardKey}/>
						))
					}
				</Grid>
			</SortableContext>
		</DndContext>
	);

};

export {DashboardDesktopGrid};