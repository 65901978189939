export type QuestionType =  (QuestionnaireTypeDropdown | QuestionnaireTypeBasic);

type QuestionnaireTypeDefault = {
    id: string,
    label: string,
    multiple?: boolean
}

type QuestionnaireTypeBasic = {
    type: 'text' | 'number' | 'boolean' | 'date',
} & QuestionnaireTypeDefault;

type QuestionnaireTypeDropdown = {
    type: 'dropdown',
    options: QuestionnaireTypeDropdownOption[]
} & QuestionnaireTypeDefault;

export type QuestionnaireTypeDropdownOption = {
    id: string,
    value: string
}

export type QuestionnaireInputTypes = 'text' | 'number' | 'boolean' | 'date' | 'dropdown' | 'multiselect';

export function generateDefaultValue(type: string){
    let val = null;
    switch (type) {
        case 'text':
            val = '';
            break;
        case 'number':
            val = undefined;
            break;
        case 'boolean':
            val = undefined;
            break;
        case 'date':
            val = undefined;
            break;
        case 'dropdown':
            val = undefined;
            break;
        case 'multiselect':
            val = undefined;
            break;
    }

    return val;
}

export function parseQuestionnaireType(typeRaw: string): QuestionnaireInputTypes | null {
    let type: QuestionnaireInputTypes | null  = null;
    switch (typeRaw) {
        case 'text':
            type = 'text';
            break;
        case 'number':
            type = 'number';
            break;
        case 'boolean':
            type = 'boolean';
            break;
        case 'date':
            type = 'date';
            break;
        case 'select':
            type = 'dropdown';
            break;
        case 'multiselect':
            type = 'multiselect';
            break;
    }

    return type;
}

export function parseQuestionnaireSelectOptions(optionsRaw: string): QuestionnaireTypeDropdownOption[] {
    const optionsArr = optionsRaw.split(';');

    const options: QuestionnaireTypeDropdownOption[] = optionsArr.map((optionRaw: string) => {
        const optionArr = optionRaw.split(':');
        return {
            id: optionArr[0],
            value: optionArr[1]
        }
    });

    return options;
}