import mixpanel from 'mixpanel-browser';

export const useMixPanel = () => {
    const track = (event: string, data?: any) => {
        if (window.location.hostname !== 'hms.lavaa.health') return;
        try {
            mixpanel.track(event, data);
        }
        catch (e) {
            console.error(e);
        }
    };
    
    return { track };
};