import React, {FC} from "react";
import {Row, Text} from "lavaa";
import {useDemo} from "../../../../../Hooks/useDemo";
import {useClients} from "../../../../../Hooks/UseClients";
import {convertValueWithScheme} from "../../../../../Tools/schemeData";
import css from "./QuestionnairesPathway.module.scss";
import {classNames} from "../../../../../Tools";
import {QuestionnairesPathwayEmpty} from "./QuestionnairesPathwayEmpty.com";

interface IData {
	label: string,
	value: string,
}

const QuestionnairesPathway: FC = React.memo(() => {

	const {activeClient} = useDemo();
	const {clientScheme} = useClients();

	if (
		!activeClient?.client
		|| !activeClient?.client?.QuestionnairesPathway || !activeClient?.client?.QuestionnairesPathway.length
		|| !clientScheme?.QuestionnairesPathway
	) return <QuestionnairesPathwayEmpty/>;

	const records = activeClient?.client?.QuestionnairesPathway[0];

	const data: IData[] = [];
	Object.entries(records).forEach(([key, value]) => {
		const scheme = clientScheme.QuestionnairesPathway.subfields[key];
		if (!scheme) return;

		data.push(
			{
				label: scheme.label,
				value: convertValueWithScheme(scheme, value),
			}
		)
	});

	if (data.length === 0) return <QuestionnairesPathwayEmpty/>;

	return (
		<div className={css.Grid}>
			{
				data.map((item: any, index: number) => (
					<Row key={index} className={classNames(css.Item, index >= data.length - 2 ? css.Last : "")}>
						<Text text={item.label} size="x2" ellipsis={true} className={css.ItemLabel} bold="true"/>
						<Text text={item.value} textAlign="right" size="x2" className={css.ItemValue}/>
					</Row>
				))
			}
		</div>
	)
});

export {QuestionnairesPathway};
