import { NavLink } from "react-router-dom";
import { Row, Text, Element, withoutPaddings, Icon, iconsTypes, Cell, Col } from 'lavaa';
import { classNames } from "../../Tools";
import { useContext, useState } from "react";
import { AppCtx } from "../../Context/App.context";
import { useMixPanel } from "../../Hooks/UseMixPanel";
import { useAccount } from "../../Hooks/UseAccount";
import css from './MainMenu.module.scss';

// MainMenu
const MainMenu = () => {

    // Hooks
    let {deviceType} = useContext(AppCtx);
    const { track } = useMixPanel();
    const { availableReports } = useAccount();

    //States
    const [dropdownActive, setDropdownActive] = useState(false);

    // Vars
    const isMobileAndActive = deviceType === 'mobile' && dropdownActive;

    // Is  Active
    const isActive = ({isActive}: any) => {
        return classNames(css.MenuItem, deviceType !== 'mobile' && isActive ? css.active : '')
    };

    function clickHandler() {
        setDropdownActive((prev) => !prev);
    }

    const navLinkHandler = (event: string) => {
        track(event);
        if(isMobileAndActive) {
            clickHandler();
        }
    }

    const MenuItems = () => (
        <>
            {/* Dashboard */}
            <NavLink to="/" className={isActive} onClick={() => navLinkHandler('Dashboard')}>Dashboard</NavLink>

            {/* Patients */}
            <NavLink to="patients" className={isActive} onClick={() => navLinkHandler('My Patients List')}>My Patients List</NavLink>

            {/* Upload Patients @DEPRECATED */}
            {/* <NavLink to="upload-patients" className={isActive} onClick={navLinkHandler}>Upload Patients</NavLink> */}

            {/* Reports */}
            {
                !availableReports ? null : <NavLink to="reports" className={isActive} onClick={() => navLinkHandler('Reports')}>Reports</NavLink>
            }
        </>
    )

    return (
        <>
            {
                isMobileAndActive && (
                    <div className={css.DropdownLayer} onClick={clickHandler}/>
                )
            }

            <Row className={ css.MainMenuWrapper }>
                {
                    deviceType === 'mobile' && (
                        <Row className={ css.MainMenuToggle } alignitems="center" onClick={clickHandler}>
                            <Cell alignitems='center' justifycontent='center'  {...withoutPaddings} paddingLeft='0.25rem' className={css.ArrowIcon}>
                                <Icon name={iconsTypes.menuHamburger}/>
                            </Cell>
                        </Row>
                    )
                }
                {
                    (deviceType !== 'mobile') && (
                        <Row className={css.MainMenu} alignitems="end">
                            <MenuItems/>
                        </Row>
                    )
                }
                {
                    (deviceType === 'mobile' && dropdownActive) && (
                        <Col className={css.MainMenu}>
                            <Row className={ css.MainMenuHeader } alignitems="center">
                                <Element>
                                    <Text text="Menu" size='x3' bold={true} className={css.HeaderText}/>
                                </Element>
                            </Row>
                            <MenuItems/>
                        </Col>
                    )
                }
            </Row>
        </>
    );
};

export { MainMenu };