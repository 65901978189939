import {FC, useContext, useEffect, useState} from "react";
import {useDemo} from "../../../Hooks/useDemo";
import {IModuleType} from "../../../Redux/Slices/Demo/Demo.data";
import {useStatistics} from "../../../Hooks/UseStatistics";
import {AppCtx} from "../../../Context/App.context";
import {DashboardMobile} from "./DashboardMobile.com";
import {useAccount} from "../../../Hooks/UseAccount";
import {MenuDashboardCards} from "../../Custom/Modals/MenuDashboardCards/MenuDashboardCards.com";
import {DashboardDesktop} from "./Desktop/DashboardDesktop.com";
import {ReportClientInfo} from "../../Custom/Modals/ReportClientInfo/ReportClientInfo.com";
import {useMixPanel} from "../../../Hooks/UseMixPanel";

// Dashboard
const Dashboard: FC<any> = () => {
	let {deviceType} = useContext(AppCtx);
	const {modules} = useDemo();
	const {HMSProjectId} = useAccount();
	const {fetchStatistics} = useStatistics();
	const { track } = useMixPanel();

	useEffect(() => {
		fetchStatistics(HMSProjectId);
	}, [HMSProjectId]);

	const [filter, setFilter] = useState<string>("all");

	// Reset filter on ProjectId change
	useEffect(() => {
		setFilter("all");
	}, [HMSProjectId]);

	const filterData: any = modules.map((module: IModuleType) => {
		return {
			id: module.ModuleUID,
			value: module.ModuleName
		}
	});
	filterData.push({id: 'all', value: 'All Modules'});

	const selectedFilter = filterData.find((item: any) => item.id === filter);

	function selectFilterHandler(data: any) {
		setFilter(data.id);

		if(data.hasOwnProperty('value')) {
			track("Modules Filter (Dashboard)", {moduleName: data.value});
		}
	}

	const filterModuleId = filter === 'all' ? undefined : filter;

	if(deviceType === 'mobile') {
		return (
			<>
				<DashboardMobile
					filterData={filterData}
					selectedFilter={selectedFilter}
					selectFilterHandler={selectFilterHandler}
					filterModuleId={filterModuleId}
				/>

				<MenuDashboardCards/>
				<ReportClientInfo/>
			</>
		)
	}

	return (
		<>
			<DashboardDesktop
				filterData={filterData}
				selectedFilter={selectedFilter}
				selectFilterHandler={selectFilterHandler}
				filterModuleId={filterModuleId}
			/>

			<MenuDashboardCards/>
			<ReportClientInfo/>
		</>
	)
};

export {Dashboard};