import { useDispatch, useSelector } from 'react-redux';
import { getTypesAction } from '../Redux/Slices/Types/Types.slice';

export const useTypes = (): any => {
    const types = useSelector((state: any) => state.types);
    const disorderTypes = useSelector((state: any) => state.types['Rules.RuleDisorder']);
    const dispatch = useDispatch();

    // Fetch Types
    const fetchTypes = () => {
        dispatch(getTypesAction([]));
    };

    // Get Disorder Type By Id
    const getDisorder = (id: number) => {
        return disorderTypes.find((item: any) => item.disorderTypeID === id);
    };

    return { fetchTypes, types, disorderTypes, getDisorder };
}