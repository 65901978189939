import cssField from "../../Questionnaire/style.module.scss";

export function calculateActiveElement(scrollRef: HTMLDivElement): string {
    if(scrollRef === null) return '';

    let activeIndex = '';
    const scrollElement = scrollRef;
    const elements = scrollElement.querySelectorAll(`.${cssField.Input}`);
    if(!elements || elements.length === 0) return '';

    const scrollBounds = scrollElement.getBoundingClientRect();
    const scrollTopBounds = scrollBounds.top;
    const scrollHeightBounds = scrollBounds.height;

    const scrollHeight = scrollElement.scrollHeight;
    const scrollTop = scrollElement.scrollTop;
    const middleScreen = scrollHeightBounds * 0.5;
    const quarterScreen = scrollHeightBounds * 0.25;


    // scroll less than 1/2 of screen
    if(scrollHeight < scrollHeightBounds * 2){
        return calculateActiveElementWithSmallScroll(scrollTop, scrollHeight - scrollHeightBounds, elements);
    }

    // first 1/4 of screen
    const isStart = scrollTop < quarterScreen;

    // last 1/4 of screen
    const isEnd = scrollTop + scrollHeightBounds > scrollHeight - quarterScreen;


    if(isStart){
        // Count elements that fit in 3/4 of first screen
        let countElements = 0;
        for (var i = 0; i < elements.length; i++) {
            const item = elements[i];
            const bounds = item.getBoundingClientRect();
            const top = bounds.top;

            const isFit = top - scrollTopBounds + scrollTop < scrollHeightBounds * 3 /4;
            if (isFit) {
                countElements = i;
            }
            else{
                break;
            }
        }

        // Divide the scroll by the height of 1/4 of the screen by the number of elements
        const elSpace = quarterScreen / countElements;
        const elementN = Math.max(Math.trunc((scrollTop + elSpace - 1) / elSpace), 0);
        activeIndex = getActiveIndex(elements, elementN)
    }

    if(isEnd && !isStart){
        // Count elements that fit in 3/4 of last screen
        let countElements = 0;
        for (var i = elements.length -1 ; i >= 0; i--) {
            const item = elements[i];
            const bounds = item.getBoundingClientRect();
            const top = bounds.top;

            const isFit = top - scrollTopBounds + scrollTop > scrollHeight - scrollHeightBounds * 3 /4;
            if (isFit) {
                countElements++;
            }
            else{
                break;
            }
        }

        // Divide the scroll by the height of 1/4 of the screen by the number of elements
        const elSpace = quarterScreen / countElements;
        const spaceN = Math.trunc((scrollTop - (scrollHeight - quarterScreen - scrollHeightBounds) + elSpace - 1) / elSpace);

        // Find element id
        const elementN = Math.min(elements.length - (countElements - spaceN) - 1, elements.length - 1);
        activeIndex = getActiveIndex(elements, elementN)
    }

    if(!isStart && !isEnd) {
        for (var i = 0; i < elements.length; i++) {
            const item = elements[i];
            const bounds = item.getBoundingClientRect();
            const top = bounds.top;

            const overMiddle = top - scrollTopBounds <= middleScreen;

            if(overMiddle){
                activeIndex = getActiveIndex(elements, i)
            }
            else{
                break;
            }
        }
    }

    return  activeIndex;
}

function calculateActiveElementWithSmallScroll(scrollTop: number, scrollHeightDiff: number, elements: NodeListOf<Element>): string{
    // Divide the scroll by the number of elements
    const elSpace = scrollHeightDiff / elements.length;
    const elementN = Math.min(Math.max(Math.trunc((scrollTop + elSpace - 1) / elSpace), 0), elements.length - 1);
    return getActiveIndex(elements, elementN)
}

function getActiveIndex(elements: NodeListOf<Element>, n: number): string{
    const item = elements[n];
    const activeIndex =  (item ? item.getAttribute('data-id') : '') || ''

    if(activeIndex === '' && n != 0) return getActiveIndex(elements, 0);
    return activeIndex;
}