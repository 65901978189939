import { useDispatch, useSelector } from 'react-redux';
import {getProjectStatisticsAction} from "../Redux/Slices/Statistics/Statistics.slice";

export const useStatistics = (): any => {

    const statisticsData = useSelector((state: any) => state.statistics.statistics);

    const dispatch = useDispatch();

    // Get Project Statistics
    const fetchStatistics = (projectId: string = "") => {
        dispatch(getProjectStatisticsAction([projectId]));
    };

    return {
        statisticsData,
        fetchStatistics
    };
}