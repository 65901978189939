import React, { FC, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Row, Container, Text, Paginator, Sorting, Cell, Col, Icon, iconsTypes } from "lavaa";
import { ReportLabel } from "../PatientPage/ReportLabel/ReportLabel.component";
import { IModuleType } from "../../Redux/Slices/Demo/Demo.data";
import { ModalsCtx } from "../../Context/Modals.context";
import { useDemo } from "../../Hooks/useDemo";
import { Table } from "../Custom/PatientsReport/Table/Table.component";
import { classNames } from "../../Tools";
import { SearchPatient } from "../Custom/SearchPatient";
import { BackButton } from "../Common/BackButton/BackButton.component";
import { useAccount } from "../../Hooks/UseAccount";
import { useMixPanel } from "../../Hooks/UseMixPanel";
import css from "./PatientList.module.scss";

interface IProps {
    modules: IModuleType[]
}

// Patients List
const PatientsList: FC<IProps> = (props) => {

    // Props
    const {modules} = props;

    // Hooks
    const {page, setPage, setSortBy, clients, sorting} = useDemo();
    const navigate = useNavigate();
    const {HMSProjectId, account} = useAccount();
    const {setPatientLabResultModalActive, setPatientLabResultModalData} = useContext(ModalsCtx);
    const {search} = useParams();
    const { track } = useMixPanel();

    // Vars
    const exportLink = `https://api.lavaa.health/getapprovedclients/${HMSProjectId}/${account.access_token}`;

    let title = "My Patients List";

    if(search) {
        title = `Search Results: ${search}`;
    }

    const currentItems = clients.length;

    function changePage(page: number) {
        setPage(page - 1);
    }

    function sortColumn(item: any) {
        setSortBy(item.ModuleUID);
    }

    // On Report Label Click
    const onReportLabelClick = (e: Event, moduleName: string, labResult: any, client: any) => {
        e.stopPropagation();

        setPatientLabResultModalData({moduleName, labResult, client});
        setPatientLabResultModalActive(true);
    };

    const sortBy = sorting.module, sortType = sorting.asc ? 'asc' : 'desc';

    // Grid Columns
    const columns = [
        {id: 'name', key: 'name', name: 'User name', frozen: true, width: 180},
        ...modules.map((module: any) => ({
            id: module.ModuleUID,
            key: module.ModuleUID,
            name: <Row className={css.Column} onClick={() => sortColumn(module)}>
                <Row className={css.ColumnName} grow="1" paddingRight="0.5rem">
                    <Text text={module.ModuleName} size="x3" ellipsis={true} />
                </Row>
                <Sorting active={sortBy === module.ModuleUID ? sortType : null}/>
            </Row>,
            width: 180
        }))
    ];

    // Grid Rows
    const rows = clients.map((patient: any) => {
        const {name, results} = patient;
        const row = {
            name: <Cell alignitems="center" className={classNames(css.Cell, css.PatientCell)} onClick={() => onPatientSelect( patient )}>{name}</Cell>,
            ...results.reduce((obj: any, item: any) => {
                const {moduleUID, moduleName, textColor, bgColor, resultGroupName} = item;
                return {
                    ...obj, [moduleUID]: (
                        <Cell alignitems="center" className={css.Cell}>
                            <ReportLabel type="button"
                                         onClick={(e: Event) => onReportLabelClick(e, moduleName, item, patient)}
                                         textColor={textColor} bgColor={bgColor}>{resultGroupName}</ReportLabel>
                        </Cell>
                    )
                }
            }, {})
        };

        return row;
    });

    // On Patient Select
    const onPatientSelect = ( item: any ) => {
        track('Patient Selected');
        navigate( "/patients/" + item.id );
    };

    return (
        <Container paddingLeft="1rem" paddingRight="1rem" paddingTop="1.5rem" paddingBottom="1.5rem" grow="1" className={css.PatientList}>
            
            {/* Title */}
            <Row paddingBottom="2rem" alignitems="center">
                
                {/* Back Button */}
                <Row paddingRight="1.5rem" paddingTop="1.5rem" paddingBottom="1.5rem">
                    <BackButton link={"/"}/>
                </Row>

                {/* Page Title */}
                <Row grow="1" paddingRight="2rem">
                    <Text text={title} size="x6" bold={true} className={css.Title} ellipsis={true}/>
                </Row>

                {/* Paging */}
                <Paginator page={page + 1} changePage={changePage} perPage={25} currentItems={currentItems}/>
            </Row>

            <Row justifycontent="space-between" alignitems="center">
                <Col grow="1">
                    <SearchPatient/>
                </Col>

                <Row className={css.ExportLink} paddingBottom="2rem" alignitems="center">
                    <Col className={css.ExportLinkIcon}>
                        <Icon name={iconsTypes.downloadDoc}/>
                    </Col>
                    <a href={exportLink}>Export approved patients</a>
                </Row>
            </Row>

            {/* Table */}
            <Row className={css.Table}>
                <Table columns={columns} rows={rows}/>
            </Row>

            {/* Paginator */}
            <Row justifycontent="end" paddingTop="1rem">
                <Paginator page={page + 1} changePage={changePage} perPage={25} currentItems={currentItems}/>
            </Row>
        </Container>
    )
};

export {PatientsList};
