import React, {FC} from "react";
import {iconsTypes, Dropdown} from "lavaa";
import {IModuleType} from "../../../Redux/Slices/Demo/Demo.data";
import {useDemo} from "../../../Hooks/useDemo";

interface IProps {
    modules: IModuleType[]
}

// Sort By Mobile
const SortByMob: FC<IProps> = React.memo((props) => {

    const {modules} = props;
    const {setSortBy, sorting} = useDemo();

    const sortData: any = [];

    modules.forEach((module: IModuleType) => {
        sortData.push({
            id: `${module.ModuleUID}-false`,
            moduleId: module.ModuleUID,
            value: `${module.ModuleName} ↓`,
            asc: false,
        });
        sortData.push({
            id: `${module.ModuleUID}-true`,
            moduleId: module.ModuleUID,
            value: `${module.ModuleName} ↑`,
            asc: true,
        });
    });

    const sortBy = sorting.module, sortAsc = sorting.asc;
    const selected = sortBy ? sortData.filter((item: any) => item.moduleId === sortBy && item.asc === sortAsc)[0] : undefined;

    function selectHandler(data: any){
        setSortBy(data.moduleId, data.asc);
    }

    return (
        <Dropdown
            icon={iconsTypes.sort}
            placeholder="Sort by"
            hidePlaceholder={false}
            data={sortData}
            onSelect={selectHandler}
            type="button"
            selected={selected}
        />
    )
});

export {SortByMob};
