import { useDispatch, useSelector } from 'react-redux';
import { setThemeAction } from '../Redux/Slices/Theme/Theme.slice';
import { ThemeType } from '../Redux/Slices/Theme/Theme.types';
import { getThemeData } from '../DataProviders/Themes/Themes.dataProvider';

export const useTheme = () => {
    const theme = useSelector((state: any) => state.theme.theme);
    const dispatch = useDispatch();

    // Set Theme
    const setTheme = (theme: ThemeType) => {
        applyTheme(theme);
        localStorage.setItem('theme', theme);
        dispatch(setThemeAction({theme}));
    };

    // Apply Theme Css Vars
    const applyTheme = async (theme: ThemeType) => {
        const themeData = getThemeData(theme);
        const root = document.documentElement;
        Object.entries(themeData).forEach(([key, value]) => {
            root.style.setProperty(key, value);
        });
    };

    return {
        theme,
        setTheme,
        applyTheme
    };
};