import React, { FC } from "react";
import {Col, Text} from "lavaa";
import css from "./QuestionnairesPathway.module.scss";

const QuestionnairesPathwayEmpty: FC = React.memo(() => {
  return (
      <Col>
          <Text text="No Pathway Surveys Available" size="x3" className={css.Empty}/>
      </Col>
  )
});

export { QuestionnairesPathwayEmpty };
