import {FC, RefObject} from 'react';
import DataGrid, {DataGridHandle} from 'react-data-grid';
import 'react-data-grid/lib/styles.css';
import type {SortColumn, SortStatusProps} from 'react-data-grid';
import css from './Table.module.scss';
import {Cell, Icon, iconsTypes} from "lavaa";

interface IProps {
    columns: any[],
    rows: any,
    rowHeight?: number,
    sortColumns?: SortColumn[],
    onSortColumnsChange?: (sortColumns: SortColumn[]) => void,
    innerRef?: RefObject<DataGridHandle>
}

// Table
const Table: FC<IProps> = (props) => {

    // Props
    const { columns = [], rows = [], rowHeight = 50, sortColumns, onSortColumnsChange, innerRef } = props;

    return <DataGrid
        className={css.Table}
        columns={columns}
        rows={rows}
        rowHeight={rowHeight}
        sortColumns={sortColumns}
        onSortColumnsChange={onSortColumnsChange}
        renderers={{sortStatus: renderSortStatus}}
        ref={innerRef}
    />
};

function renderSortStatus({ sortDirection, priority }: SortStatusProps) {
    return (
        <>
            {sortDirection !== undefined
                ? <Cell className={css.SortIcon}>
                    {
                        (sortDirection === 'ASC'
                                ? <Icon name={iconsTypes.arrowLongUp} size="x1"/>
                                : <Icon name={iconsTypes.arrowLongDown} size="x1"/>
                        )
                    }
                </Cell>
                : null}
            <div className={css.SortCell}>{priority}</div>
        </>
    );
}

export { Table };
export type {SortColumn};