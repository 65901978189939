import { createSlice } from '@reduxjs/toolkit';
import { IClinicalResearchType } from "./ClinicalResearch.data";

interface IDemoState {
  clinicalResearch: IClinicalResearchType[]
}

const initialState: IDemoState = {
  clinicalResearch: []
};

export const clinicalResearchSlice = createSlice({
  name: 'ClinicalResearch',
  initialState,
  reducers: {

    // Get Clinical Researches
    getClinicalResearchesAction: (state, action) => state,

    // Receive Clinical Researches
    receiveClinicalResearchesAction: (state, action) => {
      state.clinicalResearch = action.payload.data;
    },

    // Update Clinical Research Status
    updateClinicalResearchStatusAction: (state, action) => state,

    // Receive Update Clinical Research Status
    receiveUpdateClinicalResearchStatusAction: (state, action) => {
      const clinicalResearch = action.payload.data;
      const researches = state.clinicalResearch.map((item: any) => {
        if(
            item.ModuleUID === clinicalResearch.ModuleUID
            && item.ClientID === clinicalResearch.ClientID
            && item.ProjectUID === clinicalResearch.ProjectUID
        ){
          return clinicalResearch;
        }
        return item;
      });
      state.clinicalResearch = researches;
    },

    // Reset
    resetClinicalResearchAction: () => {
      return initialState;
    },
  }
});

export const {
  getClinicalResearchesAction,
  receiveClinicalResearchesAction,
  updateClinicalResearchStatusAction,
  receiveUpdateClinicalResearchStatusAction,
  resetClinicalResearchAction
} = clinicalResearchSlice.actions;
export default clinicalResearchSlice.reducer;