import { FC, CSSProperties, useState, ChangeEvent, useEffect } from 'react';
import css from './Pagination.module.scss';
import { Icon, Row, iconsTypes } from 'lavaa';

type Props = {
    page: number;
    pageSize?: number;
    pageSizes?: number[];
    totalPages: number;
    onChangePage: (page: number, pageSize: number) => void;
    style?: CSSProperties;
    className?: string;
};

// Pagination Component
export const Pagination: FC<Props> = (props: Props) => {
    const { page = 1, onChangePage, pageSizes = [10, 25, 50, 100], totalPages = 1, style, className, ...rest } = props;

    // States
    const [firstItem, setFirstItem] = useState(1);
    const [lastItem, setLastItem] = useState(10);
    const [totalItems, setTotalItems] = useState(100);
    const [currentPage, setCurrentPage] = useState(page);
    const [pageSize, setPageSize] = useState(10);

    // On Page Size Change
    const handlePageSizeChange = (event: ChangeEvent<HTMLSelectElement>) => {
        const newPageSize = Number(event.target.value);
        const pageNum = 1;
        setPageSize(newPageSize);
        setCurrentPage(pageNum);
        onChangePage(pageNum, newPageSize);
    };

    // Handle Firt Page
    const handleFirstPage = () => {
        const pageNum = 1;
        setCurrentPage(pageNum);
        onChangePage(pageNum, pageSize);
    };

    // Handle Previous Page
    const handlePreviousPage = () => {
        const pageNum = currentPage - 1 || 1;
        setCurrentPage(pageNum);
        onChangePage(pageNum, pageSize);
    };

    // Handle Next Page
    const handleNextPage = () => {
        const pageNum = currentPage + 1 <= totalPages ? currentPage + 1 : totalPages;
        setCurrentPage(pageNum);
        onChangePage(pageNum, pageSize);
    };

    // Handle Last Page
    const handleLastPage = () => {
        const pageNum = totalPages;
        setCurrentPage(pageNum);
        onChangePage(pageNum, pageSize);
    };

    // Watch totalPages
    useEffect(() => {
        if (totalPages < currentPage) {
            setCurrentPage(page < currentPage ? page : currentPage);
        }
    }, [page, totalPages, currentPage]);

    return (
        <Row {...rest} className={css.Pagination} style={{...style}} justifycontent="end" paddingRight="1rem">

            {/* Page Size Selector */}
            <Row alignitems="center">
                <Row>
                    <span style={{marginRight: '0.5rem'}}>Items:</span>
                </Row>
                <Row>
                    <select className={css.Select} onChange={handlePageSizeChange} value={pageSize}>
                        {
                            pageSizes.map((size: number, index: number) => <option key={index} value={size}>{size}</option>)
                        }
                    </select>
                </Row>
            </Row>
            
            {/* Range of items and the total number of items */}
            {/* <Row grow="0" alignitems="center" paddingLeft="2rem">
                <strong>{firstItem}</strong>
                <span style={{margin: '0 0.5rem'}}>to</span> 
                <strong>{lastItem}</strong>
                <span style={{margin: '0 0.5rem'}}>of</span> 
                <strong>{totalItems}</strong>
            </Row> */}

            {/* Controlls */}
            <Row grow="0" paddingLeft="2rem">

                {/* First Page */}
                <Row grow="0" alignitems="center" onClick={handleFirstPage}>
                    <Icon name={iconsTypes.firstPage} style={{fontSize: 18, display: 'flex', cursor: 'pointer'}} />
                </Row>

                {/* Previous Page */}
                <Row grow="0" paddingLeft="0.5rem" alignitems="center" onClick={handlePreviousPage}>
                    <Icon name={iconsTypes.prevPage} style={{fontSize: 18, display: 'flex', cursor: 'pointer'}} />
                </Row>

                {/* Page Number */}
                <Row grow="0" alignitems="center" paddingLeft="0.5rem">
                    <span>Page</span> 
                    <strong style={{marginLeft: '0.5rem'}}>{currentPage <= totalPages ? currentPage : totalPages}</strong> 
                    <span style={{marginLeft: '0.5rem'}}>of</span> 
                    <strong style={{marginLeft: '0.5rem'}}>{totalPages}</strong>
                </Row>
                
                {/* Next Page */}
                <Row grow="0" paddingLeft="0.5rem" alignitems="center" onClick={handleNextPage}>
                    <Icon name={iconsTypes.nextPage} style={{fontSize: 18, display: 'flex', cursor: 'pointer'}} />
                </Row>
                
                {/* Last Page */}
                <Row grow="0" paddingLeft="0.5rem" alignitems="center" onClick={handleLastPage}>
                    <Icon name={iconsTypes.lastPage} style={{fontSize: 18, display: 'flex', cursor: 'pointer'}} />
                </Row>
            </Row>
        </Row>
    )
};