import {useStatistics} from "../../../../../Hooks/UseStatistics";
import {IStatisticType} from "../../../../../Redux/Slices/Statistics/Statistics.data";
import {BarChartDataType, BarChartLabelType} from "lavaa";

export type GenderDataType = {
	all: {
		women: number,
		men: number,
	},
	risk: {
		women: number,
		men: number,
	}
}

export function useGenderStat(moduleId?: string): [BarChartDataType[], BarChartLabelType[]] {
	const {statisticsData} = useStatistics();
	const data =  prepareData(statisticsData, moduleId);
	const labels = prepareLabels();
	return [data, labels];
}

function prepareData(statisticsData: IStatisticType[], moduleId?: string): BarChartDataType[]{
	// Skip if no data
	if(statisticsData.length === 0) return [];

	const data = filterData(statisticsData, moduleId);

	// Skip if no data
	if(data.all.men + data.all.women === 0) return [];

	return dataFormatter(data);
}

function filterData(statisticsData: IStatisticType[], moduleId?: string): GenderDataType{
	// Filter by Group type and Gender
	const data: GenderDataType = {
		all: {
			women: 0,
			men: 0,
		},
		risk: {
			women: 0,
			men: 0,
		}
	};

	statisticsData.forEach((item: IStatisticType) => {
		// Get data
		const moduleUID = item.ModuleUID;
		const gender = item.Gender;
		const count = item.Amount;
		const riskPercentage = item.ResultPercentage;

		// Filter by module if moduleId is provided
		if(moduleId && moduleUID !== moduleId){
			return;
		}

		// Sum all by gender
		if(gender === "M"){
			data.all.men += count;
		}

		if(gender === "F"){
			data.all.women += count;
		}

		// If this group is high risk
		if(isHighRisk(riskPercentage)){
			if(gender === "M"){
				data.risk.men += count;
			}

			if(gender === "F"){
				data.risk.women += count;
			}
		}
	});

	return dataFormat(data);
}

function dataFormatter(data: GenderDataType): BarChartDataType[]{
	return [
		{
			name: "All Patients",
			data: {
				men: data.all.men,
				women: data.all.women
			}
		},
		{
			name: "High-Risk Patients",
			data: {
				men: data.risk.men,
				women: data.risk.women
			}
		}
	];
}

function prepareLabels(): BarChartLabelType[]{
	return [
		{
			key: 'men',
			name: "Men",
			color: "#2962FF"
		},
		{
			key: 'women',
			name: "Women",
			color: "#FFA726"
		}
	];
}

export function isHighRisk(riskPercentage: number): boolean {
	return riskPercentage === 100;
}

export function dataFormat(data: GenderDataType): GenderDataType {
	const totalPatients = data.all.men + data.all.women;
	const totalRiskPatients = data.risk.men + data.risk.women;

	return {
		all: {
			men: rounded(data.all.men / totalPatients * 100),
			women: rounded(data.all.women / totalPatients * 100)
		},
		risk: {
			men: rounded(data.risk.men / totalRiskPatients * 100),
			women: rounded(data.risk.women / totalRiskPatients * 100)
		}
	};
}

function rounded(number: number){
	if(isNaN(number)) return 0;
	return +number.toFixed(2);
}