import React, {FC} from 'react';
import {Cell, Icon, Row, Text} from 'lavaa';
import css from './style.module.scss';
import {useDemo} from "../../../../../Hooks/useDemo";
import {IClientGuidelinesType} from "../../../../../Redux/Slices/Demo/Demo.data";
import {getTreatmentPriorityIcon} from "../../Treatment/treatmentsPriority";

const Treatments: FC = React.memo(() => {
    const {activeClientGuidelines} = useDemo();

    return (
        <div className={css.List}>
            {
                activeClientGuidelines.slice(0, 3)
                .filter((guideline: IClientGuidelinesType) => !guideline.isChecked)
                .map((guideline: IClientGuidelinesType) => {
                    let iconPriority = getTreatmentPriorityIcon(guideline.priority);

                    return (
                        <Row alignitems="center" className={css.Item} key={guideline.guidelineUID}>
                            <Cell shrink="0" className={css.Icon}>
                                <Icon name={iconPriority}/>
                            </Cell>
                            <Cell>
                                <Text size="x2" ellipsis={true} maxLines={3}>{guideline.text}</Text>
                            </Cell>
                        </Row>
                    );
                })
            }
        </div>
    )
});

export {Treatments};
