import React, {FC} from "react";
import css from "./style.module.scss";
import {Cell, Table, TableColumn, TableText} from "lavaa";
import {Treatment} from "./treatment";
import {useDemo} from "../../../../Hooks/useDemo";
import {IClientGuidelinesType, IResultType} from "../../../../Redux/Slices/Demo/Demo.data";

interface IProps {
    filter: string
}

// Guideline By Priority
const TreatmentByPriority: FC<IProps> = React.memo((props) => {

    const {filter} = props;
    const {activeClient, activeClientGuidelines} = useDemo();

    const columns: TableColumn[] = [
        {id: 'name', label: 'Guideline Name'},
        {id: 'diagnoses', label: 'Diagnoses'},
        // {id: 'risk', label: 'Risk', width: 140, sortable: true}
    ];

    function sortingHandler(column: string, asc: boolean) {
        console.log(column, asc);
    }

    let guidelines;
    switch (filter) {
        case "checked":
            guidelines = activeClientGuidelines?.filter((guideline: IClientGuidelinesType) => guideline.isChecked);
            break;
        case "unchecked":
            guidelines = activeClientGuidelines?.filter((guideline: IClientGuidelinesType) => !guideline.isChecked);
            break;
        default:
            guidelines = activeClientGuidelines;
            break;
    }

    // Temporary
    // const reportLabelMedium = (
    //     <Cell alignitems="center" className={css.Cell}>
    //         <ReportLabel type="button" textColor={"#FFFFFF"} bgColor={"#FFA726"}>
    //             Medium-high risk
    //         </ReportLabel>
    //     </Cell>
    // )

    const rows: any = [];

    if(!guidelines) return null;

    guidelines?.map((guideline: IClientGuidelinesType) => {
        const results = activeClient?.results.filter((result: IResultType) => guideline.modules.includes(result.moduleUID));
        const modulesNames = results?.map((result: IResultType) => result.moduleName);
        const modulesNamesString = modulesNames?.join(", ");
        rows.push([
            <Cell alignitems="center" className={css.Cell} key={guideline.guidelineUID}>
                <Treatment guideline={guideline}/>
            </Cell>,
            <TableText maxLines={2}>{modulesNamesString}</TableText>,
            // reportLabelMedium,
        ]);
    });

    return (
        <Table columns={columns} rows={rows} title="Insights by Priority" style="lines" onSorting={sortingHandler}/>
    )
});

export {TreatmentByPriority};
