import React, {FC} from "react";
import {Card} from "../Card/Card.component";
import {Col} from "lavaa";
import {MedicalHistory} from "../Cards/MedicalHistory/MedicalHistory.component";
import {LabResults} from "../Cards/LabResults/LabResults.component";
import {Medications} from "../Cards/Medications/Medications.component";
import {DiagnosisCode} from "../Cards/DiagnosisCode/DiagnosisCode.component";
import {HighRisk} from "../Cards/HighRisk";

interface IProps {
}

// Health Card
const HealthCardPage: FC<IProps> = React.memo(() => {

    return (
        <Col paddingLeft="0.5rem" paddingRight="0.5rem">
            <Card title="High Risk Diagnoses">
                <HighRisk/>
            </Card>
            <Card title="Medical history">
                <MedicalHistory/>
            </Card>
            <Card title="Lab results" hasSearch={true} searchPlaceholder="Search by results">
                <LabResults/>
            </Card>
            <Card title="Medication">
                <Medications/>
            </Card>
            <Card title="Diagnoses code" hasSearch={true} searchPlaceholder="Search By Code">
                <DiagnosisCode/>
            </Card>
        </Col>
    )
});

export {HealthCardPage};
