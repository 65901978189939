import React, { FC } from "react";
import {Col, Text} from "lavaa";
import css from "./QuestionnairesPathway.module.scss";

const QuestionnairesPathwayEmpty: FC = React.memo(() => {
	return (
		<Col paddingTop="0.5rem" paddingBottom="1rem" paddingLeft="1.25rem" paddingRight="1.25rem">
			<Text size="x3" className={css.Empty}>No Pathway Surveys Available</Text>
		</Col>
	)
});

export { QuestionnairesPathwayEmpty };
