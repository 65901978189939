import {FC, useContext, useState} from "react";
import {Row, Splitter, Grid, Loading} from 'lavaa';
import {ModulesMenu} from '../../Menu/ModulesMenu/ModulesMenu.component';
import {PatientsList} from "../../PatientsList/PatientsList.component";
import {IModuleType} from "../../../Redux/Slices/Demo/Demo.data";
import {PatientLabResultModal} from "../../Custom/Modals/PatientLabResultModal/PatientLabResultModal.component";
import {AppCtx} from "../../../Context/App.context";
import {PatientsListMob} from "../../PatientsList/PatientsListMob.component";
import {useDemo} from "../../../Hooks/useDemo";
import css from './Patients.module.scss';

// Patients
const Patients: FC<any> = () => {
    let {deviceType} = useContext(AppCtx);
    const [selectedModules, setSelectedModules] = useState<IModuleType[]>([]);
    
    // Clients load watcher
    const { clientsLoading } = useDemo(false, true);

    // On Module Select
    const onModuleSelect = (data: IModuleType[]) => {
        setSelectedModules(data);
    };

    if (deviceType !== "mobile") {
        const paneSizes = deviceType === "desktop" ? ['17%', '83%'] : ['22%', '78%'];
        return (
            <>
                <Row grow="1" className={css.Patients}>
                    <Splitter resetLayoutKey={0} direction="vertical" collapsible={[0]} paneSizes={paneSizes}
                              name="PatientsLayout">

                        {/* Menu */}
                        <Grid scroll="true">
                            <ModulesMenu onModuleSelect={onModuleSelect}/>
                        </Grid>

                        {/* Center */}
                        <Grid>
                            <PatientsList modules={selectedModules}/>
                        </Grid>
                    </Splitter>

                    {/* Loading */}
                    {
                        clientsLoading === false ? null :
                        <Row className={css.Loading} alignitems="center" justifycontent="center">
                            <Loading/>
                        </Row>
                    }
                </Row>

                {/* Modals */}
                <PatientLabResultModal/>
            </>
        );
    }

    return (
        <>
            <PatientsListMob onModuleSelect={onModuleSelect} modules={selectedModules} clientsLoading={clientsLoading}/>

            {/* Modals */}
            <PatientLabResultModal/>
        </>
    );

};

export {Patients};