import React, {FC} from "react";
import css from "../Card/Card.module.scss";
import {CardItem} from "../Card/Card";
import {PersonalInfo} from "../Cards/PersonalInfo";
import {Vitals} from "../Cards/Vitals";
import {HighRisk} from "../Cards/HighRisk";
import {ClinicResearch} from "../Cards/ClinicResearch";

interface IProps {
}

// Health Card
const PersonalInfoPage: FC<IProps> = React.memo(() => {

    return (
        <div className={css.Grid}>
            <CardItem title="Personal Info">
                <PersonalInfo/>
            </CardItem>
            <CardItem title="Vitals">
                <Vitals/>
            </CardItem>
            <CardItem title="High Risk Diagnoses">
                <HighRisk/>
            </CardItem>
            <CardItem title="Clinic Research">
                <ClinicResearch/>
            </CardItem>
            {/*<CardItem title="Notes">*/}
            {/*</CardItem>*/}
        </div>
    )
});

export {PersonalInfoPage};
