import React, {FC} from 'react';
import {Checkbox, Col, Row, Text} from 'lavaa';
import css from './style.module.scss';
import {useDemo} from "../../../../../Hooks/useDemo";
import {convertValueWithScheme} from "../../../../../Tools/schemeData";
import {useClients} from "../../../../../Hooks/UseClients";
import {classNames} from "../../../../../Tools";

interface IData {
    label: string,
    value: string | boolean
}

const Vitals: FC = React.memo(() => {

    const { activeClient } = useDemo();
    const { clientScheme } = useClients();

    console.log('clientScheme', clientScheme)
    console.log('activeClient', activeClient)

    if (
        !activeClient?.client
        || !activeClient?.client?.Vitals || !activeClient?.client?.Vitals.length
        || !clientScheme?.Vitals
    ) return null;

    const vitals = activeClient?.client?.Vitals[0];


    const dataInfo: IData[] = [];

    Object.entries(vitals).forEach(([key, value]) => {
        const scheme = clientScheme.Vitals.subfields[key];
        if(!scheme) return;

        dataInfo.push(
            {
                label: scheme.label,
                value: convertValueWithScheme(scheme, value),
            }
        )
    });

    return (
        <Row className={css.Wrapper}>
            <div className={css.List}>
                {
                    dataInfo.map((item: any, index: number) => (
                        <Row className={css.Item} key={index} justifycontent="start">
                            <Col paddingRight="0.75rem" className={css.Label}>
                                <Text size="x2" ellipsis={true} maxLines={3}>{item.label}:</Text>
                            </Col>
                            <Col className={classNames(css.Value, typeof item.value === 'boolean' ? css.boolean : '')}>
                                {
                                    typeof item.value === 'boolean' ? (
                                        <Checkbox checked={item.value} />
                                    ) : (
                                        <Text size="x2" bold="true" ellipsis={true} maxLines={3} textAlign="left">{item.value}</Text>
                                    )
                                }
                            </Col>
                        </Row>
                    ))
                }
            </div>
        </Row>
    )
});

export {Vitals};
