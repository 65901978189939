import React, {FC, useContext, useEffect} from "react";
import { ModalsCtx } from "../../../../Context/Modals.context";
import {addSnackbar, Col, Icon, iconsTypes, Modal, Row, Text} from 'lavaa';
import {useAccount} from "../../../../Hooks/UseAccount";
import {useReports} from "../../../../Hooks/UseReports";
import {copyToClipboard} from "../../../../Tools/clipboard";

// Report Client Info Modal
const ReportClientInfo: FC<any> = () => {

    const { isReportClientInfoModalActive, setReportClientInfoModalActive, reportClientInfoId, setReportClientInfoId } = useContext(ModalsCtx);
    const { HMSProjectId } = useAccount();


    const { getClientDetailsById, clientDetails, clearClientDetails } = useReports();

    useEffect(() => {
        if(!isReportClientInfoModalActive) return;
        if(reportClientInfoId === "") return;

        // Get Client Details
        getClientDetailsById(reportClientInfoId, HMSProjectId);
    }, [HMSProjectId, isReportClientInfoModalActive, reportClientInfoId]);

    // Handle Close
    const handleClose = () => {
        setReportClientInfoId("");
        clearClientDetails();
        setReportClientInfoModalActive(false);
    };

    // Handle Copy
    async function handleCopy (value: string) {
        await copyToClipboard(value);
        addSnackbar('success', 'Copied!', '');
    }

    return <Modal 
        active={ isReportClientInfoModalActive }
        onClose={ handleClose }
        onOk={ handleClose }
        title="Reach out to the Patient"
        cancelDisabled={true}
        type="info"
    >
        <Col>

            {/* Phones */}
            <Row paddingBottom="0.75rem">
                <Row style={{minWidth: 120, fontWeight: 'bold'}}>Phones:</Row>
                <Col style={{overflow: 'hidden'}}>
                    {
                        clientDetails.phones.length ? clientDetails.phones.map((phone: string, index: number) => (
                            <Row key={index} alignitems="center">
                                <Text ellipsis={true}>
                                    <a href={`tel:${phone}`}>{phone}</a>
                                </Text>
                                <span style={{cursor: 'pointer', paddingLeft: '0.5rem'}}
                                      onClick={() => handleCopy(phone)}><Icon name={iconsTypes.copy}/>
                                </span>
                            </Row>
                        )) : 'No data'
                    }
                </Col>
            </Row>

            {/* Email */}
            <Row paddingBottom="0.75rem">
                <Row style={{minWidth: 120, fontWeight: 'bold'}}>Email:</Row>
                <Col style={{overflow: 'hidden'}}>
                    {
                        clientDetails.email.length ? clientDetails.email.map((email: string, index: number) => (
                            <Row key={index} alignitems="center">
                                <Text ellipsis={true}>
                                    <a href={`mailto:${email}`}>{email}</a>
                                </Text>
                                <span style={{cursor: 'pointer', paddingLeft: '0.5rem'}}
                                      onClick={() => handleCopy(email)}><Icon name={iconsTypes.copy}/>
                                </span>
                            </Row>
                        )) : 'No data'
                    }
                </Col>
            </Row>

            {/* Address */}
            <Row>
                <Row style={{minWidth: 120, fontWeight: 'bold'}}>Address:</Row>
                <Col style={{overflow: 'hidden'}}>
                    {
                        clientDetails.address .length ?
                            (
                                <Row alignitems="start">
                                    <Text ellipsis={true} maxLines={2} style={{display: '-webkit-inline-box'}}>
                                        {clientDetails.address || 'No data'}
                                    </Text>
                                    <span style={{cursor: 'pointer', paddingLeft: '0.5rem'}}
                                          onClick={() => handleCopy(clientDetails.address)}><Icon name={iconsTypes.copy}/>
                                    </span>
                                </Row>
                            ) : 'No data'
                    }
                </Col>
            </Row>
        </Col>
    </Modal>
};

export { ReportClientInfo };