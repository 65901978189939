export interface IRuleType {
    uid: string,
    primaryId: string,
    rulesetUID: string,
    moduleUID: string,
    projectUID: string,
    createdByCompany: string,
    score: null | number,
    jsonLogic: any,
    name: string,
    description: string,
    additionalInfo: string,
    ruleCategoryID: number,
    abstractionType: number,
    accessType: number,
    isEditEnabled: boolean
}

export const emptyRule: IRuleType = {
    uid: "",
    primaryId: "",
    rulesetUID: "",
    moduleUID: "",
    projectUID: "",
    createdByCompany: "",
    score: null,
    jsonLogic: {},
    name: "",
    description: "",
    additionalInfo: "",
    ruleCategoryID: 0,
    abstractionType: 0,
    accessType: 1,
    isEditEnabled: true
}

export const rulesAccessTypes = {
    'open': 1,
    'company': 2,
    'my': 3
}