import {getAccountAction, getUserProjects} from './Account.slice';
import {getLocalStorage, setLocalStorage} from '../../../Hooks/UseLocalStorage';

const receiveLoginCallback = ( store: any, payload: any) => {
    payload = payload[0];
    if(payload.errorMessage.length > 0) return;

    store.dispatch(getAccountAction([payload.data.access_token]));
}

const receiveUserInfoCallback = ( store: any, payload: any) => {
    payload = payload[0];
    if(payload.errorMessage.length > 0){
        setLocalStorage("refresh_token", "");
        // call logout
    }

    store.dispatch(getUserProjects([]));

    let theme = null;
    let availableReports = null;

    // Get HMSProjectId from local storage
    let HMSProjectId: string | null = getLocalStorage('HMSProjectId') ?? null;
    console.log('[HMSProject] Saved ProjectId', HMSProjectId);

    const details = payload.data.details ?? [];
    details.forEach((item: any) => {
        
        // Check if item has name, value and settings
        if(item.hasOwnProperty("name") && item.hasOwnProperty("value") && item.hasOwnProperty("settings")) {
            const { name, value, settings } = item;

            // Take ProjectId from account settings
            if(name === 'HMSProject') {
                if (value.length > 0 && HMSProjectId === '') {
                    HMSProjectId = value;
                    setLocalStorage("HMSProjectId", HMSProjectId);
                    console.log('[HMSProject] Take ProjectId from account settings', HMSProjectId);
                }
            }

            // Theme
            if(name === 'Theme') {
                theme = JSON.parse(settings);
            }

            // Available Reports
            if (name === 'AvailableReports') {
                availableReports = JSON.parse(settings);
            }
        }
    });

    payload.data.theme = theme;
    payload.data.HMSProjectId = HMSProjectId;
    payload.data.availableReports = availableReports;
}

const receiveRefreshTokenCallback = ( store: any, payload: any) => {
    payload = payload[0];
    if(payload.errorMessage.length > 0) {
        setLocalStorage("refresh_token", "");
        return;
    }

    if(!store.getState().account.isAuth){
        store.dispatch(getAccountAction([payload.data.access_token]));
    }
}

const receiveUserProjectsActionCallback = ( store: any, payload: any) => {
    let HMSProjectId = store.getState().account.HMSProjectId;
    console.log('[HMSProject] Projects List', payload.data);

    if(payload.data.length === 0) {
        payload.data.HMSProjectId = "";
        payload.data.projects = [];
        console.log('[HMSProject] Take empty project');
        return;
    }

    // Check if HMSProjectId is valid
    const found = payload.data.find((project: any) => project.ProjectUID === HMSProjectId);

    console.log('[HMSProject] Check ProjectId for validity', found);

    // If not found, set the first project as HMSProjectId
    if(!found){
        HMSProjectId = payload.data[0].ProjectUID;
        setLocalStorage("HMSProjectId", HMSProjectId);
        console.log('[HMSProject] Take first project', HMSProjectId);
    }

    payload.data.projects = payload.data;
    payload.data.HMSProjectId = HMSProjectId;
}

export { receiveLoginCallback, receiveUserInfoCallback, receiveRefreshTokenCallback, receiveUserProjectsActionCallback }