import React, {FC, useState} from "react";
import {Col} from "lavaa";
import {GuidelinesControls} from "./controls";
import css from "./style.module.scss";
import {GuidelinesByPriority} from "./byPriority";
import {GuidelinesByDiagnoses} from "./byDiagnoses";

interface IProps {
}

// Guidelines Page
const GuidelinesPage: FC<IProps> = React.memo(() => {

    const [guidelinesView, setGuidelinesView] = useState('diagnoses');
    const [guidelinesFilter, setGuidelinesFilter] = useState('all');

    return (
        <Col className={css.Guidelines} grow="1">
            <GuidelinesControls
                view={guidelinesView}
                changeView={setGuidelinesView}
                filter={guidelinesFilter}
                onChangeFilter={setGuidelinesFilter}
            />
            {
                guidelinesView === "priority"
                    ? <GuidelinesByPriority filter={guidelinesFilter}/>
                    : <GuidelinesByDiagnoses filter={guidelinesFilter}/>
            }
        </Col>
    )
});

export {GuidelinesPage};
