import {useStatistics} from "../../../../../Hooks/UseStatistics";
import {IStatisticType} from "../../../../../Redux/Slices/Statistics/Statistics.data";
import {ModuleFilterType} from "./Chart";
import {BarChartDataType, BarChartLabelType} from "lavaa";

type ChartDataType = {
	[key: string]: {
		name: string,
		count: number
	},
}

export function useModulesStat(modulesId: ModuleFilterType[], moduleSelected: boolean): [BarChartDataType[], BarChartLabelType[]]{
	const {statisticsData} = useStatistics();

	const dataFormatted = prepareData(statisticsData, modulesId, moduleSelected);

	const labels = prepareLabels(modulesId)

	return [dataFormatted, labels];
}

function prepareData(statisticsData: IStatisticType[], modulesId: ModuleFilterType[], moduleSelected: boolean): BarChartDataType[]{
	const data = filterData(statisticsData, modulesId);

	if(moduleSelected && !data.hasOwnProperty(modulesId[0].id)){
		return [];
	}

	return formatData(data, modulesId);
}

// Filter by Module and High Risk Percentage
function filterData(statisticsData: IStatisticType[], modulesId: ModuleFilterType[]): ChartDataType{
	const data: ChartDataType = {};

	statisticsData.forEach((item: IStatisticType) => {
		// Get data
		const moduleUID = item.ModuleUID;
		const count = item.Amount;
		const riskPercentage = item.ResultPercentage;

		// Filter by module if moduleId is provided
		const module = modulesId.find((selectedModule: ModuleFilterType) => selectedModule.id === moduleUID);
		if(!module){
			return;
		}

		// Create group if not exists
		if(!data.hasOwnProperty(moduleUID)){
			data[moduleUID] = {
				name: module.value,
				count: 0,
			}
		}

		if(isHighRisk(riskPercentage)){
			// Count if this group is high risk
			data[moduleUID].count += count;
		}
	});

	return data;
}

// Format data for chart
function formatData(data: ChartDataType, modulesId: ModuleFilterType[]): BarChartDataType[] {
	const dataFormatted: BarChartDataType[] = [];

	modulesId.forEach((selectedModule: ModuleFilterType) => {
		let moduleData = data[selectedModule.id];
		if(!moduleData){
			moduleData = {
				name: selectedModule.value,
				count: 0,
			}
		}

		dataFormatted.push({
			name: moduleData.name,
			data: {
				[selectedModule.id]: moduleData.count
			},
		});
	});

	return dataFormatted;
}

function prepareLabels(modulesId: ModuleFilterType[]): BarChartLabelType[]{
	const labels: BarChartLabelType[] = [];

	modulesId.forEach((selectedModule: ModuleFilterType, index) => {
		labels.push({
			key: selectedModule.id,
			name: selectedModule.value,
			color: colors[index % colors.length]
		});
	});

	return labels;
}

export function isHighRisk(riskPercentage: number): boolean {
	return riskPercentage === 100;
}

const colors = [
	"#FF3E1E", "#FFA726", "#FDD835",
	"#00E676", "#07B951","#14C9C9",
	"#3B54D0","#7C4DFF","#B1BAF4",
	"#90BE6D", "#6F767E"
];