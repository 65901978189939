import { FC } from 'react';
import { Row, Col, Text } from 'lavaa';

type Props = {
    value: string;
};

// TextCellRenderer Component
export const TextCellRenderer: FC<Props> = (props: Props) => {
    const { value } = props;
    // style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}
    return (
        <Row grow="1" shrink="1" justifycontent="center" alignitems="center" style={{whiteSpace: 'normal'}}>
            <Text size="x2" style={{textAlign: 'center'}}>{value}</Text>
        </Row>
    )
};