import React, {FC} from "react";
import css from "../Card/Card.module.scss";
import {CardItem} from "../Card/Card";
import {QuestionnairesPathway} from "../Cards/QuestionnairesPathway/QuestionnairesPathway.com";
import {LifeStyleCom} from "../Cards/LifeStyle/LifeStyle.com";

interface IProps {
}

// Surveys Page
const SurveysPage: FC<IProps> = React.memo(() => {

    return (
        <div className={css.Grid}>
            <CardItem title="Questionnaires Pathway" size='doubled'>
                <QuestionnairesPathway/>
            </CardItem>
            <CardItem title="Lifestyle" size='doubled'>
                <LifeStyleCom/>
            </CardItem>
        </div>
    )
});

export {SurveysPage};
