import React, {FC} from 'react';
import {Cell, Row, Text} from 'lavaa';
import {useDemo} from "../../../../../Hooks/useDemo";
import css from './style.module.scss';

const HighRisk: FC = React.memo(() => {

    let {activeClient} = useDemo();

    if (!activeClient || !activeClient?.results?.length) {
        return null;
    }

    // Risk Filtering
    const highRisk = activeClient.results.filter((item: any) => ['High risk', 'Extreme Risk'].includes(item.resultGroupName));

    return (
        <Row className={css.List}>
            {
                highRisk.map((item: any) => {
                    const risk = item.resultGroupName === 'High risk' ? 'high' : 'extreme';
                    return (
                        <Cell className={css.Item} data-risk={risk} key={item.moduleUID}>
                            <Text size="x1" className={css.Label} text={item.moduleName}/>
                        </Cell>
                    )
                })
            }

            {
                highRisk.length === 0 && (
                    <Text size="x3" className={css.Empty}>No High Risks Detected</Text>
                )
            }
        </Row>
    )
});

export {HighRisk};
