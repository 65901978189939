import { useState } from 'react';

export function useLocalStorage<T>(key: string, initial: (() => T) | T) {

    const isIframe = window !== window.parent;
    
    // Swith storage for iframe
    if (key === 'refresh_token' && isIframe) {
        key = 'refresh_token_iframe';
    }

    if (key === 'access_token' && isIframe) {
        key = 'access_token_iframe';
    }

    const [storedValue, setStoredValue] = useState<T>(() => {
        const initialValue: T = initial instanceof Function ? initial() : initial;

        try {
            const item = window.localStorage.getItem(key);
            return item ? JSON.parse(item) : initialValue;
        } catch (error) {
            return initialValue;
        }
    });

    const setValue = (value: T | ((val: T) => T)) => {
        const valueToStore = value instanceof Function ? value(storedValue) : value;
        setStoredValue(valueToStore);
        setLocalStorage(key, valueToStore);
    };

    const getValue = () => {
        const initialValue: T = initial instanceof Function ? initial() : initial;

        try {
            const item = window.localStorage.getItem(key);
            return item ? JSON.parse(item) : initialValue;
        } catch (error) {
            return initialValue;
        }
    };

    const getActualValue = () => {
        const value = getValue();
        setValue(value);
        return value;
    };

    return [storedValue, setValue, getActualValue] as const;
}

export function setLocalStorage(key: string, value: any){
    try {
        const isIframe = window !== window.parent;
        
        // Swith storage for iframe
        if (key === 'refresh_token' && isIframe) {
            key = 'refresh_token_iframe';
        }

        if (key === 'access_token' && isIframe) {
            key = 'access_token_iframe';
        }
        
        window.localStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
        console.log(error);
    }
}

export function getLocalStorage(key: string){
    try {
        const item = window.localStorage.getItem(key);
        return item ? JSON.parse(item) : undefined;
    } catch (error) {
        return undefined;
    }
}