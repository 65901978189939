import React, {FC} from "react";
import {useDemo} from "../../../../Hooks/useDemo";
import {IClientGuidelinesType} from "../../../../Redux/Slices/Demo/Demo.data";
import {Guideline} from "./guideline";
import css from "./style.module.scss";
import { Col } from "lavaa";

interface IProps {
    filter: string
}

// Guideline By Priority
const GuidelinesByPriority: FC<IProps> = React.memo((props) => {

    const {filter} = props;
    const {activeClientGuidelines} = useDemo();


    let guidelines;
    switch (filter) {
        case "checked":
            guidelines = activeClientGuidelines?.filter((guideline: IClientGuidelinesType) => guideline.isChecked);
            break;
        case "unchecked":
            guidelines = activeClientGuidelines?.filter((guideline: IClientGuidelinesType) => !guideline.isChecked);
            break;
        default:
            guidelines = activeClientGuidelines;
            break;
    }


    if(!guidelines) return null;

    return (
        <Col>
            <div className={css.List}>
                {
                    guidelines.map((guideline: IClientGuidelinesType) => <Guideline guideline={guideline}/>)
                }
            </div>
        </Col>
    )
});

export {GuidelinesByPriority};
