import React, {FC, useEffect, useRef, useState} from 'react';
import {Text, Row, Col, iconsTypes, Icon, Input} from "lavaa";
import css from './Card.module.scss';
import {classNames} from "../../../../Tools";

interface IProps {
    title: string,
    children: any,
    hasSearch?: boolean,
    searchPlaceholder?: string,
}

// Dashboard Card
const Card: FC<IProps> = (props) => {

    const {title, hasSearch = false, searchPlaceholder = "Search", children} = props;

    const timerRef = useRef<any>(0)
    const searchRef = useRef<any>(null)
    const [isActive, setIsActive] = useState(true);
    const [isOpen, setIsOpen] = useState(true);
    const [searchActive, setSearchActive] = useState(false);
    const [search, setSearch] = React.useState<string>('');

    useEffect(() => {
        if(searchActive){
            if(searchRef.current){
                searchRef.current.focus();
            }
        }
    }, [searchActive]);

    // Add search to the children props
    const renderChildren = () => {
        if (!hasSearch) {
            return children;
        }

        return React.Children.map(children, (child) => {
            return React.cloneElement(child, {
                search: search,
            });
        });
    };

    function openSearch(e: Event) {
        if(!hasSearch) return;
        e.stopPropagation();

        setSearchActive(true);
    }

    function toggle() {
        if(isActive) {
            clearTimeout(timerRef.current);
            timerRef.current = setTimeout(() => {
                setIsActive(false);

                timerRef.current = setTimeout(() => {
                    setSearchActive(false);
                    setSearch("");
                }, 600);

            }, 50);
            setIsOpen(false);
        }
        else {
            timerRef.current = setTimeout(() => {
                setIsOpen(true);
            }, 600);
            setIsActive(true);
        }

        //TODO - set state
    }

    function onSearchBlur() {
        if(search.length === 0 && searchActive) {
            setSearchActive(false);
            setSearch("");
        }
    }

    function onSearchClear() {
        setSearchActive(false);
        setSearch("");
    }

    return (
        <Col
            className={css.Card}
            marginBottom="1rem"
        >
            <Row justifycontent="space-between" alignitems="center" paddingBottom="1rem" paddingTop="1rem" paddingRight="1rem">
                {
                    !searchActive && (
                        <Row className={css.Header} onClick={toggle}>
                            <Text text={title} size="x5" bold="true"/>
                        </Row>
                    )
                }
                {
                    searchActive && (
                        <Row className={css.HeaderSearch}>
                            <Input
                                onChange={setSearch}
                                type="text"
                                variant="slim"
                                placeholder={searchPlaceholder}
                                icon={iconsTypes.search}
                                onBlur={onSearchBlur}
                                innerRef={searchRef}
                                onClear={onSearchClear}
                                autoFocusOnClear={false}
                            />
                        </Row>
                    )
                }
                <Row>
                    {
                        (isActive && hasSearch && !searchActive) && (
                            <Col className={classNames(css.SearchIcon)} marginRight="2rem" onClick={openSearch}>
                                <Icon name={iconsTypes.search} size="x2"/>
                            </Col>
                        )
                    }
                    <Col className={classNames(css.Arrow, isActive ? css.active : "")} onClick={toggle}>
                        <Icon name={iconsTypes.arrowDown} size="x2"/>
                    </Col>
                </Row>
            </Row>
            <Col className={classNames(css.Content, isActive ? css.active : "", isOpen ? css.open : "")}>
                {renderChildren()}
            </Col>
        </Col>
    )
};

export {Card};
