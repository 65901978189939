import React, {FC, useState} from "react";
import {Col, Container, Row, Text, TabsSingle} from "lavaa";
import css from "../style.module.scss";
import {SearchPatient} from "../../../Custom/SearchPatient";
import {GuidelineParams} from "../GuidelineParams/GuidelineParams";
import {TreatmentPage} from "../Treatment";
import {HealthCard} from "../HealthCard";
import {BackButton} from "../../../Common/BackButton/BackButton.component";
import {PersonalInfoPage} from "../PersonalInfo";
import {QuestionnairePage} from "../Questionnaire";
import {patientPageTabs} from "../tabs";
import { useMixPanel } from "../../../../Hooks/UseMixPanel";
import {SurveysPage} from "../Surveys/Surveys";

interface Props {
    activeTab: number,
    setActiveTab: (index: number) => void,
}

// Patient Page Content
const PatientPageContent: FC<Props> = React.memo((props) => {
    
    // Props
    const {activeTab, setActiveTab} = props;

    // States
    const [guidelinesByPriority, setGuidelinesByPriority] = useState(false);
    const [guidelinesFilter, setGuidelinesFilter] = useState('all');

    // Hooks
    const { track } = useMixPanel();

    const handleTabSwitch = (tab: any) => {
        const tabTitle = patientPageTabs[tab].title;
        setActiveTab(tab);
        track(tabTitle);
    };
    
    return (
        <Container paddingLeft="1rem" paddingRight="1rem" className={css.Dashboard}>
            <Col paddingTop="2rem" className={css.DashboardContent}>
                <Row alignitems="center" justifycontent="space-between" paddingBottom="0.25rem">
                    <Row justifycontent="start" alignitems="center" paddingTop="1.5rem" paddingBottom="1.5rem">
                        {/* Back Button */}
                        <Row paddingRight="1.5rem">
                            <BackButton link={"/patients"}/>
                        </Row>

                        <Row>
                            <Text text="Patient Page" size="x6" bold={true} className={css.Title}/>
                        </Row>
                    </Row>

                    <SearchPatient/>
                </Row>
                <Row alignitems="start" justifycontent="space-between" className={css.FilterWrapper}>
                    <Row paddingRight="2rem" paddingBottom="1rem" className={css.TabsWrapper}>
                        <TabsSingle content={patientPageTabs} onChange={handleTabSwitch}/>
                    </Row>
                    {
                        activeTab === 2 && (
                            <Row grow="1">
                                <GuidelineParams
                                    active={guidelinesByPriority}
                                    onChangeToggle={setGuidelinesByPriority}
                                    guidelinesFilter={guidelinesFilter}
                                    onChangeFilter={setGuidelinesFilter}
                                />
                            </Row>
                        )
                    }
                </Row>
                <Col paddingTop="1.5rem" paddingBottom="2rem" grow="1" className={css.PageContent}>
                    {
                        activeTab === 0 && (
                            <PersonalInfoPage/>
                        )
                    }
                    {
                        activeTab === 1 && (
                            <HealthCard/>
                        )
                    }
                    {
                        activeTab === 2 && (
                            <TreatmentPage activeByPriority={guidelinesByPriority} filter={guidelinesFilter}/>
                        )
                    }
                    {
                        activeTab === 3 && (
                            <QuestionnairePage/>
                        )
                    }
                    {
                        activeTab === 4 && (
                            <SurveysPage/>
                        )
                    }
                </Col>
            </Col>
        </Container>
    )
});

export {PatientPageContent};
