import React, {FC} from "react";
import css from "../style.module.scss";
import {Row, InputDate} from "lavaa";
import {classNames} from "../../../../Tools";


interface IProps {
    onChange: (data: any) => void,
    value: Date | undefined,
    onDelete?: () => void,
}

// Questionnaire Field Number
const QuestionnaireFieldDate: FC<IProps> = React.memo((props) => {
    const {onChange, value, onDelete} = props;

    const hasContent =  value !== undefined;
    const classes = classNames(
        css.Field,
        hasContent ? css._hasContent : "",
    );

    return (
        <Row className={css.InputRow}>
            <Row alignitems="center">
                <InputDate
                    className={classes}
                    onChange={onChange}
                    value={value}
                    size="large"
                />
                {/*{*/}
                {/*    onDelete ? (*/}
                {/*        <Col className={css.Delete} marginLeft="0.5rem" onClick={onDelete}>*/}
                {/*            <Icon name={iconsTypes.trash} size='x2'/>*/}
                {/*        </Col>*/}
                {/*    ) : (*/}
                {/*        <Col className={css.DeleteFiller} marginLeft="0.5rem"/>*/}
                {/*    )*/}
                {/*}*/}
            </Row>
        </Row>

    )
});

export {QuestionnaireFieldDate};
