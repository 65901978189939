import { useDispatch, useSelector } from 'react-redux';
import {
    addModuleAction,
    getModuleTemplateAction,
    getModuleAction,
    getModuleAndUpdateAction,
    getModulesAction, publishModuleAction,
    updateModuleAction,
    deleteModuleAction,
    fetchModuleReportAction,
    resetModulesAction, getModuleInfoAction
} from "../Redux/Slices/Modules/Modules.slice";
import { useParams } from 'react-router-dom';

export const useModules = (): any => {

    const { activeModuleId } = useParams();
    const dispatch = useDispatch();

    const modulesTemplates = useSelector((state: any) => state.modules.templates);
    const moduleTemplates = useSelector((state: any) => state.modules.templates);
    const healthModules = useSelector((state: any) => state.modules.templates.filter((item: any) => item.moduleTypeID === 1 && item.accessType === 1));
    const sourceModules = useSelector((state: any) => state.modules.templates.filter((item: any) => item.moduleTypeID === 2));
    const destinationModules = useSelector((state: any) => state.modules.templates.filter((item: any) => item.moduleTypeID === 3));
    const functionModules = useSelector((state: any) => state.modules.templates.filter((item: any) => item.moduleTypeID === 4));
    const myModules = useSelector((state: any) => state.modules.templates.filter((item: any) => item.moduleTypeID === 1 && (item.accessType === 3 || item.isEditEnabled === true)));
    const moduleNodes = useSelector(( state: any) => state.modules.nodes);
    const reports = useSelector(( state: any) => state.modules.reports);
    const modulesOnLoading = useSelector((state: any) => state.modules.onLoading);

    // Get Module Template
    const getModuleTemplate = (uid: any) => {
        return moduleTemplates.find((item: any) => item.primaryId === uid);
    };

    // Get Module Node
    const getModuleNode = (uid: any) => {
        return uid ? (moduleNodes[uid] || null) : null;
    };

    // Get Active Module Data
    const getActiveModuleData = () => {
        return getModuleNode(activeModuleId);
    };

    // Fetch Modules
    const fetchModules = (accessType: number = 1) => {
        dispatch(getModulesAction([null, accessType, 0, 0, 1000, 'name', false]));
    };

    // Fetch Module Template
    const fetchModuleTemplate = (moduleId: string) => {
        dispatch(getModuleTemplateAction([moduleId]));
    };

    // Fetch Module
    const fetchModule = (projectId: string, moduleId: string) => {
        dispatch(getModuleAction([projectId, moduleId]));
    };

    // Fetch Module Info
    const fetchModuleInfo = (moduleId: string) => {
        dispatch(getModuleInfoAction([moduleId]));
    };

    // Fetch Module & Save
    const fetchModuleAndSave = (projectId: string, moduleId: string) => {
        dispatch(getModuleAndUpdateAction([projectId, moduleId]));
    };

    // Fetch Module Configuration
    const fetchModuleConfiguration = (moduleId: string) => {
        console.log('Fetch Module Configuration');
    };

    // Save New Module
    const saveNewModule = (data: any, reportData: Array<any> = []) => {
        const { name, disorder, moduleTypeID = 1, description, additionalInfo, projectUID, jsonLogic = {}, createdByCompany = '0086ee63-e65f-4f7b-a243-155667de29d7'} = data;

        if (reportData.length) {
            reportData = reportData.map((item) => ({
                ...item,
                maxValue: (item.maxValue !== null && item.maxValue.length > 0) ? +item.maxValue : null,
                minValue: (item.minValue !== null && item.minValue.length > 0) ? +item.minValue : null,
            }));
        }

        // Dispatch Add Module
        dispatch(addModuleAction([{
            name,
            description,
            additionalInfo,
            disorderTypeID: disorder.disorderTypeID,
            moduleTypeID,

            accessType: 3, // 1 - open, 2 - company, 3 - my
            projectUID,
            jsonLogic,
            createdByCompany,
            reportConfigs: reportData
        }]));
    };

    // Update Module
    const updateModule = (data: any) => {
        let { reportConfigs } = data;
        if (reportConfigs && reportConfigs.length) {
            reportConfigs = reportConfigs.map((item: any) => ({
                ...item,
                maxValue: (item.maxValue !== null && item.maxValue.length > 0) ? +item.maxValue : null,
                minValue: (item.minValue !== null && item.minValue.length > 0) ? +item.minValue : null,
            }));
        }

        dispatch(updateModuleAction([{...data, reportConfigs}]));
    };

    // Fetch Module Report
    const fetchModuleReport = (moduleUID: string) => {
        if (moduleUID && !reports[moduleUID]) {
            dispatch(fetchModuleReportAction([moduleUID]));
        }
    };

    // Get Module Report
    const getModuleReport = (id: string) => {
        return reports[id];
    };

    // Publish Module
    const publishModule = (projectId: string, moduleId: string) => {
        dispatch(publishModuleAction([projectId, moduleId]));
    };

    // Delete Module
    const deleteModule = (uid: string) => {
        dispatch(deleteModuleAction([uid]));
    };

    // Reset Modules
    const resetModules = () => {
        dispatch(resetModulesAction());
    }

    // Get Destination Module Type
    const getDestinationModuleType = (primaryId: string) => {
        if (primaryId === '648ec5bd-834e-4a7b-b294-d5bcaf3a5f97') return 'realTime';
        if (primaryId === '06c8f1cc-2335-4e4c-ab58-71bbebd9abe6') return 'email';
        if (primaryId === '972855fc-005e-43c6-b7dc-ad6c9b640940') return 'slack';
        return null;
    }

    return {
        modulesTemplates,
        moduleNodes, modulesOnLoading,
        fetchModuleReport, getModuleReport,
        fetchModuleTemplate,
        fetchModules, fetchModule, fetchModuleConfiguration, fetchModuleAndSave, fetchModuleInfo,
        saveNewModule, updateModule, publishModule,
        healthModules, sourceModules, destinationModules, functionModules, myModules,
        getModuleTemplate, getModuleNode,
        deleteModule, getActiveModuleData,
        resetModules,
        getDestinationModuleType
    };
}
