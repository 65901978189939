import React, {FC, useContext} from "react";
import {Row, Title, Col, BarChart, Icon, iconsTypes} from 'lavaa';
import css from "../../Dashboard.module.scss";
import {useTotalGenderStat} from "./utils";
import {EmptyChart} from "../emptyChart";
import {AppCtx} from "../../../../../Context/App.context";
import {classNames} from "../../../../../Tools";

type Props = {
    moduleId?: string,
    dragHandleProps?: any,
    isDragging?: boolean
}

// Total Gender Statistics
const TotalGenderStat: FC<Props> = ({
    moduleId,
    dragHandleProps,
    isDragging = false
}) => {
    let {deviceType} = useContext(AppCtx);
    const isMobile = deviceType === 'mobile';

    const maxRows = isMobile ? 4 : 5;
    const [dataFormatted, labels] = useTotalGenderStat(moduleId, maxRows);

    const yAxisWidth = isMobile ? 70 : 105;
    const hasChartMargin = !isMobile;
    const yLabel = isMobile ? undefined : "Risk Level";

    return (
        <Col className={classNames(css.Widget, css._chart)}>
            {/* TODO - hided temporary */}
            {/*<Button icon={iconsTypes.trash} iconSize="x2" type="gray" onClick={() => {}} variant="text" className={css.DeleteIcon}/>*/}

            <Row className={css.WidgetHeader} alignitems="center" justifycontent="space-between">
                <Row className={classNames(css.HeaderMoveContainer, isDragging && css._dragging)} {...dragHandleProps} alignitems="center">
                    {
                        dragHandleProps && (
                            <Col className={css.WidgetMoveIcon}>
                                <Icon name={iconsTypes.move} size="x1"/>
                            </Col>
                        )
                    }
                    <Row className={css.WidgetTitle}>
                        <Title size="x3" maxLines={2} className={css.WidgetTitle}>Total Gender Statistics</Title>
                    </Row>
                </Row>
            </Row>
            <Row className={css.Chart} grow="1">
                {
                    dataFormatted.length === 0 ? (
                        <EmptyChart type="noData"/>
                    ) : (
                        <BarChart
                            data={dataFormatted}
                            stacked
                            horizontal
                            labels={labels}
                            yLabel={yLabel}
                            xLabel="Amount of patients, unit"
                            yAxisWidth={yAxisWidth}
                            trimLegend={false}
                            hasChartMargin={hasChartMargin}
                        />
                    )
                }
            </Row>
        </Col>
    );

};

export {TotalGenderStat};