import {FC} from 'react';
import {Row, Col, Text} from 'lavaa';
import css from './Medications.module.scss'
import {useDemo} from "../../../../../Hooks/useDemo";
import {IClientMedicationsType} from "../../../../../Redux/Slices/Demo/Demo.data";

interface IProps {
}

const Medications: FC<IProps> = () => {

    const {activeClient} = useDemo();

    if (!activeClient?.medications) return null;
    const medications = activeClient?.medications;

    return (
        <Col>
            {
                medications.map((medication: IClientMedicationsType, i: number) => (
                    <Row className={css.Item} justifycontent="space-between" key={i}>
                        <Col>
                            <Text size="x3">
                                {medication.name}
                            </Text>
                        </Col>
                        <Col className={css.Info} alignitems="end" shrink="0">
                            {
                                medication.dose && (
                                    <Row className={css.Data}>
                                        <Text size="x2">Dose:</Text>
                                        <Text size="x2" bold="true" className={css.Value}>{medication.dose}</Text>
                                    </Row>
                                )
                            }
                        </Col>
                    </Row>
                ))
            }
        </Col>
    )
};

export {Medications};
