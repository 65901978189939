import { FC, useContext, useEffect, useState } from "react";
import { ModalsCtx } from "../../../../Context/Modals.context";
import { PersonEditModule } from "./PersonEdit.component";
import { useDemo } from "../../../../Hooks/useDemo";
import {Modal} from "lavaa";

// Module Settings Modal
const ModuleSettingsModal: FC<any> = () => {

    const { isEditPersonModalActive, setEditPersonModalActive, setPersonEditOutputModalActive } = useContext(ModalsCtx);
    const { activeClient, runDemoClient } = useDemo();
    const [localData, setLocalData] = useState<any>(null);

    useEffect(() => {
        if(!isEditPersonModalActive) return;
        setLocalData(activeClient.client);
    }, [activeClient, isEditPersonModalActive]);

    function changeHandler(newData: any){
        setLocalData(newData);
    }

    function saveChanges(){
        const data = Object.assign({}, localData, {
            MedicalHistories: localData.MedicalHistories[0],
            LabResults: localData.LabResults[0],
            PersonalRecords: localData.PersonalRecords[0],
        });
        console.log(data);
        const client = JSON.stringify(data);
        runDemoClient(client);
        setPersonEditOutputModalActive(true);
    }

    // Handle Close
    const handleClose = () => {
        setEditPersonModalActive(false);
        setLocalData(null);
    };

    // Handle Cancel
    const handleCancel = () => {
        setEditPersonModalActive(false);
        setLocalData(null);
    };

    // Handle Ok
    const handleOk = () => {
        saveChanges();
        setEditPersonModalActive(false);
    };

    if(!localData) return null;

    return <Modal
      active={ isEditPersonModalActive }
      onClose={ handleClose }
      onCancel={ handleCancel }
      onOk={ handleOk }
      title="Edit info"
      okText="Save"
      cancelText="Cancel"
      type="large"
    >
        <PersonEditModule clientData={localData}  onChange={changeHandler}/>
    </Modal>
};

export { ModuleSettingsModal };