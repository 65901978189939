import React, {FC} from "react";
import css from "../../../Survey.module.scss";
import {Col, Row, Text} from "lavaa";

interface IProps {
}

// Questionnaire Error Page
const QuestionnaireErrorPage: FC<IProps> = React.memo(() => {

    return (
        <Col alignitems="center" className={css.ThanksWrapper}>
            <Row paddingBottom="0.5rem">
                <Text size="x6" bold={true} className={css.Title}>This page was not found</Text>
            </Row>
        </Col>
    )
});

export {QuestionnaireErrorPage};
