import React, {FC} from "react";
import css from "../../Questionnaire.module.scss";
import {Col, Row, Text} from "lavaa";
import {IQuestionnaireType} from "../../../../../../../Redux/Slices/Questionnaire/Questionnaire.data";
import {classNames} from "../../../../../../../Tools";
import {QuestionnaireField} from "../../../../../../PatientPage/Questionnaire/field";

interface IProps {
    inputs: any,
    onChange: (field: string, data: any) => void,
    questionnaireData: IQuestionnaireType[],
}

// Questionnaire Fields
const QuestionnaireFields: FC<IProps> = React.memo((props) => {
    const {inputs, onChange, questionnaireData} = props;

    if(questionnaireData.length === 0) return(
        <Row>
            <Text size="x4">Data is completed. Come back later.</Text>
        </Row>
    );

    return(
        <Col className={classNames(css.ModuleBox, css.Container)}>
            {
                questionnaireData.map((question: IQuestionnaireType, num) => {
                    const id = question.Parameter.ParameterID.toString();
                    const value = inputs[id] ?? undefined;
                    return (
                        <QuestionnaireField question={question} value={value} num={num} onChange={onChange} key={id}/>
                    )
                })
            }
        </Col>
    )
});

export {QuestionnaireFields};
