import { FC, memo, useEffect, useState } from "react";
import { addSnackbar, Col, Container, Icon, iconsTypes, Modal, Row, Text } from "lavaa";
import { DataTable } from "../../../../DataTable/DataTable.com";
import { noop } from "../../../../../Tools/Noop";
import { useReports } from "../../../../../Hooks/UseReports";
import { useAccount } from "../../../../../Hooks/UseAccount";
import { useParams } from "react-router-dom";
import { ReportFilterType } from "../../../Reports.types";
import { useMixPanel } from "../../../../../Hooks/UseMixPanel";
import { copyToClipboard } from "../../../../../Tools/clipboard";
import css from "./QMHtn.module.scss";

type Props = {
    setFilteredHeaders: (headers: any[]) => void;
    filteredHeaders: any[];
    filteredHeadersLastUpdate: number;
    onFilterUpdate: (data: any) => void;
    attributedFilter: any[];
};

// QMHtn Component
export const QMHtn: FC<Props> = memo((props) => {

    // Props
    const { setFilteredHeaders, filteredHeaders, filteredHeadersLastUpdate, onFilterUpdate, attributedFilter } = props;

    // Constants
    const reportName = 'ReportQMHtn';
    const filterTypes: any = {
        contains: 0,
        DateRange: 1,
        NumberRange: 2,
        startsWith: 3,
        endsWith: 4,
        equals: 5,
        notEqual: 6,
        notContains: 7,
        blank: 8,
        notBlank: 9
    };

    // public enum FilterType
    // {
    //     TextContain = 0,
    //     DateRange = 1,
    //     NumberRange = 2,
    //     TextStartWith = 3,
    //     TextEndWith = 4,
    //     Equal = 5,
    //     NotEqual = 6,
    //     NotContain = 7,
    //     Blank = 8,
    //     NotBlank = 9
    // }

    // Hooks
    const { requestReports, getReport, getClientDetailsById, clientDetails, clientDetailsLastUpdate, clearClientDetails } = useReports();
    const { HMSProjectId, account } = useAccount();
    const { reportType = 'qrda' } = useParams();
    const reportData = getReport(reportName);
    const { track } = useMixPanel();

    // States
    const [clientDetailsId, setClientDetailsId] = useState<number>(0);
    const [clientDetailsModalActive, setClientDetailsModalActive] = useState(false);
    const [reload, setReload] = useState(0);
    const [headers, setHeaders] = useState([]);
    const [reset, setReset] = useState(0);
    const [rows, setRows] = useState([]);
    const [totalPages, setTotalPages] = useState(1);
    const [columnModel, setColumnModel] = useState([
        {field: 'PatientIdFullName', headerName: 'Patient', pinned: true, width: 250, renderer: ['PatientCellRenderer', 'ClientDetailsCellRenderer'], setClientDetailsId: (clientId: number) => {setClientDetailsId(clientId); track('Get Client ID', {report: 'Daily Discharge Last 48H'});}, comparator: noop, /*floatingFilterComponent: PatientFilter*/},
        {field: 'G0031', headerName: 'G0031', renderer: 'bool'},
        {field: 'G2115', headerName: 'G2115', renderer: 'bool'},
        {field: 'G2116', headerName: 'G2116', renderer: 'bool'},
        {field: 'G2118', headerName: 'G2118', renderer: 'bool'},
        {field: 'G9231', headerName: 'G9231', renderer: 'bool'},
        {field: 'G9740', headerName: 'G9740', renderer: 'bool'},
        {field: 'G9910', headerName: 'G9910', renderer: 'bool'},
    ]);

    // Table Filter
    const [filter, setFilter] = useState<ReportFilterType>({
        page: 1,
        pageSize: 10,
        search: [],
        sort: {
            sortedByField: '',
            isAscending: true,
            sortingType: 0, // 0 - any; 1 - string; 2 - number; 999 - other;
        },
    });

    // Handle Table Data Changed
    const handleTableDataChanged = (data: any = {}) => {
        const { headers = [], rows = [], totalPages = 1  } = data;
        
        // Prepare Headers
        const indexedHeaders = headers
        .filter((row: any) => row.isShownInAppReport)
        .map((header: any) => {
            return {
                field: header.columnName, 
                headerName: header.displayName, 
                index: header.index
            };
        })
        .sort((a: any, b: any) => a.index - b.index);
        
        // Prepare Column Model
        const updatedColumnModel = indexedHeaders.map((header: any) => {
            const { field, headerName } = header;
            const colModel = columnModel.find((col: any) => col.field === field);
            return colModel ? {...colModel, headerTooltip: headerName} : {field, headerTooltip: headerName};
        });
        
        setColumnModel(updatedColumnModel);
        setTotalPages(totalPages);
        setHeaders(indexedHeaders);
        setRows(rows);

        // Set filtered headers
        if (filteredHeaders.length === 0 && indexedHeaders.length > 0) {
            setFilteredHeaders(indexedHeaders);
            setReset(performance.now());
        }
    };

    // Handle Action
    const handleAction = (actionType: string, data: any) => {
        let updated = false;
        let isFilterChanged = false;
        let updatedFilter = {...filter};

        // Pagination Changed
        if (actionType === 'paginationChanged') {
            const { pageNumber, pageSize } = data;
            updated = true;
            updatedFilter = {...updatedFilter, page: pageNumber, pageSize: pageSize};
        }

        // Filter Changed
        if (actionType === 'filterChanged') {
            const fields: string[] = [];
            const search: any[] = Object.keys(data).map((key: string) => {
                const field = data[key];
                fields.push(field);

                return {
                    filteredByField: key,
                    filterType: filterTypes[field.type],
                    filterTerm: field.filter,
                    filterFrom: null,
                    filterTo: null
                };
            });
            
            updated = true;
            isFilterChanged = true;
            updatedFilter = {...updatedFilter, search};

            // Track Filtering
            track('Filtering', {report: reportName, action: 'filtering', columns: Object.keys(data).join(', ')});
        }
        
        // Sort Changed
        if (actionType === 'sortChanged') {
            updated = true;
            updatedFilter = {
                ...updatedFilter, 
                sort: {
                    ...filter.sort,
                    sortedByField: data ? data.colId : '',
                    isAscending: data ? data.sort === 'asc' : true
                }
            };

            // Track Sorting
            data && track('Sorting', {report: reportName, action: 'sorting', columnName: data.colId});
        }

        // Update Filter
        if (updated) {
            if (isFilterChanged == true) {
                updatedFilter = {...updatedFilter, page: 1};
            }

            setFilter({...updatedFilter});
            onFilterUpdate({...updatedFilter});
        }
    };

    // Handle Client Details Modal Close
    const handleClientDetailsModalClose = () => {
        clearClientDetails();
        setClientDetailsId(0);
        setClientDetailsModalActive(false);
    };

    // Handle Copy
    const handleCopy = async (value: string) => {
        await copyToClipboard(value);
        addSnackbar('success', 'Copied!', '');
    };
    
    // Watch HMSProjectId
    useEffect(() => {
        requestReports(HMSProjectId, reportName, filter.sort, [...filter.search, ...attributedFilter], filter.page, filter.pageSize);
    }, [HMSProjectId, reportName, filter, attributedFilter]);

    // Watch Report Data
    useEffect(() => {
        if (reportData?.lastUpdated) {
            handleTableDataChanged(reportData);
        }
    }, [reportData?.lastUpdated]);

    // Watch HMSProjectId & clientDetailsId
    useEffect(() => {
        if (clientDetailsId && HMSProjectId) {
            // Open Modal
            setClientDetailsModalActive(true);

            // Get Client Details
            getClientDetailsById(clientDetailsId, HMSProjectId);
        }
    }, [HMSProjectId, clientDetailsId]);

    return (
        <Container grow="1" className={css.DailyHuddleDischarge48h}>
            <DataTable 
                headers={filteredHeaders} 
                rows={rows} 
                columnModel={columnModel} 
                minColumnWidth={150} 
                totalPages={totalPages} 
                onAction={handleAction} 
                useServer={true}
                filteredHeadersLastUpdate={filteredHeadersLastUpdate}
            />

            {/* User Detail Modal */}
            <Modal active={clientDetailsModalActive} onClose={handleClientDetailsModalClose} onOk={handleClientDetailsModalClose} cancelDisabled={true} title="Reach out to the Patient">
                {
                    <Col>

                        {/* Phones */}
                        <Row paddingBottom="0.75rem">
                            <Row style={{minWidth: 120, fontWeight: 'bold'}}>Phones:</Row>
                            <Col>
                                {
                                    clientDetails.phones.length ? clientDetails.phones.map((phone: string, index: number) => (
                                        <Row key={index} alignitems="center">
                                            <Text>
                                                <a href={`tel:${phone}`}>{phone}</a>
                                                <span style={{cursor: 'pointer', paddingLeft: '0.5rem'}} onClick={() => handleCopy(phone)}><Icon name={iconsTypes.copy} /></span>
                                            </Text>
                                        </Row>
                                    )) : 'No data'
                                }
                            </Col>
                        </Row>

                        {/* Email */}
                        <Row paddingBottom="0.75rem">
                            <Row style={{minWidth: 120, fontWeight: 'bold'}}>Email:</Row>
                            <Col>
                            {
                                clientDetails.email.length ? clientDetails.email.map((email: string, index: number) => (
                                    <Row key={index} alignitems="center">
                                        <Text>
                                            <a href={`mailto:${email}`}>{email}</a>
                                            <span style={{cursor: 'pointer', paddingLeft: '0.5rem'}} onClick={() => handleCopy(email)}><Icon name={iconsTypes.copy} /></span>
                                        </Text>
                                    </Row>
                                )) : 'No data'
                            }
                            </Col>
                        </Row>

                        {/* Address */}
                        <Row>
                            <Row style={{minWidth: 120, fontWeight: 'bold'}}>Address:</Row>
                            <Row alignitems="center">
                                <Text>
                                    {clientDetails.address || 'No data'}
                                    {
                                    !clientDetails.address ? null : 
                                    <span style={{cursor: 'pointer', paddingLeft: '0.5rem'}} onClick={() => handleCopy(clientDetails.address)}>
                                        <Icon name={iconsTypes.copy} />
                                    </span>
                                }
                                </Text>
                            </Row>
                        </Row>
                    </Col>
                }
            </Modal>
        </Container>
    )
});