import {FC, useEffect, useRef, useState} from "react";
import { useClients } from "../../../../Hooks/UseClients";
import { JsonLogicFieldType, prepareJsonLogicInputFields } from "../../../../Tools/parseJsonLogic";
import {Col, iconsTypes, Input, Row, TestFieldsGroup} from "lavaa";
import css from "./PersonEdit.module.scss";
import {classNames} from "../../../../Tools";

interface IProps {
    clientData: any,
    onChange: (newClientData: any) => void
}

// Person Edit Module
const PersonEditModule: FC<IProps> = ( props ) => {

    const { clientData, onChange } = props;
    const { clientScheme } = useClients();
    const [inputs, setInputs] = useState<any>({});
    const [searchValue, setSearchValue] = useState<string>( "" );
    const [isFocusSearch, setIsFocusSearch] = useState<boolean>( false );

    // Refs
    const jsonLogicUsedFields = useRef<JsonLogicFieldType[]>([]);

    useEffect(() => {
        jsonLogicUsedFields.current = prepareJsonLogicInputFields(clientScheme);
        const updateInputs: object = {};

        jsonLogicUsedFields.current.forEach((logicField) => {
            if(logicField.field !== null){
                Object.assign(updateInputs, {
                    [logicField.key]: undefined
                });
            }
            else{
                const group:object = {}
                Object.keys(logicField.fields).forEach((key) => Object.assign(group, {
                    [key]: undefined
                }));

                return Object.assign(updateInputs, {
                    [logicField.key]: [group]
                });
            }
        });

        Object.assign(updateInputs, clientData);
        setInputs(updateInputs);

    }, [clientScheme]);

    useEffect(() => {
        onChange(inputs);
    }, [inputs]);

    function changeHandler(key: string, name: string | null, value: any){
        let update, updateInputs;

        if(name){
            update = Object.assign({}, inputs[key][0]);
            Object.assign( update, {
                [name]: value
            });

            updateInputs = Object.assign({}, inputs, {
                [key]: [update]
            });
        }
        else{
            update = value;
            updateInputs = Object.assign({}, inputs, {
                [key]: update
            });
        }

        setInputs(updateInputs);
    }

    // Handle Search
    const onSearch = ( value: string ) => {
        setSearchValue( value.toLowerCase() );
    };

    const onFocusSearch = () => {
        setIsFocusSearch( true );
    }

    const onBlurSearch = () => {
        setIsFocusSearch( false );
    }

    const isSearchActive = searchValue.length > 0 || isFocusSearch;

    return (
      <Col paddingTop="1rem" paddingBottom="1rem" className={css.Box}>
          <Row grow="1" paddingBottom="1rem" justifycontent="end">
              <Col className={css.Search}>
                  <Input type="text" icon={iconsTypes.search} className={classNames(css.SearchInput, isSearchActive && css.SearchActive)} value={searchValue} placeholder="Search by Module Name" onChange={onSearch} onFocus={onFocusSearch} onBlur={onBlurSearch} variant={isSearchActive ? undefined: "flat"}/>
              </Col>
          </Row>

          {/* Dynamic Form */}
          {
              jsonLogicUsedFields.current.map((logicField) =>
                  <TestFieldsGroup key={logicField.key} logicField={logicField} onChange={changeHandler} inputs={inputs} searchValue={searchValue} autoChangeBooleanFields={false}/>
              )
          }
      </Col>
    )
};

export { PersonEditModule };