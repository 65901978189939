import {Row, Cell, Col, Icon, iconsTypes, Text, Button} from 'lavaa';
import React, {FC, useRef, useState} from 'react';
import css from './style.module.scss';
import {classNames} from "../../Tools";
import {IUserProjectType} from "../../Redux/Slices/Account/Account.data";
import {useAccount} from "../../Hooks/UseAccount";

interface IProps {
    company: string,
    projects: IUserProjectType[],
    search: string,
    onSelectProject: () => void
}

const linkToIDE = "https://ide.lavaa.health/myprojects/";

// Choose Project Clinic List
const ClinicList: FC<IProps> = (props) => {
    const { company, projects, search } = props;

    const { HMSProjectId, selectProject } = useAccount();
    const hasActiveProject = projects.some((project: IUserProjectType) => project.ProjectUID === HMSProjectId);

    const [isOpen, setIsOpen] = useState(() => hasActiveProject);
    const [isToggled, setIsToggled] = useState(() => isOpen);
    const timerRef = useRef<any>(0);

    function toggle() {
        if(isToggled) {
            clearTimeout(timerRef.current);
            timerRef.current = setTimeout(() => {
                setIsToggled(false);

            }, 50);
            setIsOpen(false);
        }
        else {
            timerRef.current = setTimeout(() => {
                setIsOpen(true);
            }, 600);
            setIsToggled(true);
        }
    }

    function goToIDE(projectId: string) {
        window.open(`${linkToIDE}${projectId}`, '_blank');
    }

    function onSelectProject(projectId: string) {
        selectProject(projectId);
        props.onSelectProject();
    }

    function searchCheck(val: string) {
        return val.toLowerCase().includes(search.toLowerCase());
    }

    const isSearchCompany = searchCheck(company);
    let isSearchProject = false;
    const filteredProjects = [];

    // If search is not empty && company name is not found
    if(!isSearchCompany && search.length > 0) {
        projects.forEach((project: IUserProjectType) => {
            if(searchCheck(project.ProjectName || "")) {
                isSearchProject = true;
                filteredProjects.push(project);
            }
        });
    }
    else {
        filteredProjects.push(...projects);
    }

    if(!isSearchCompany && !isSearchProject && search.length > 0) return null;

    return (
        <Col
            className={classNames(css.Clinic, hasActiveProject ? css._active : "", isToggled ? css._toggled : "", isOpen ? css._open : "")}
            paddingLeft='1rem' paddingRight='1rem' paddingBottom='1rem'
        >
            <Row
                className={css.ClinicHeader}
                paddingTop="0.75rem" paddingBottom="0.75rem" paddingLeft="0.75rem" paddingRight="0.25rem"
                alignitems="center"
                onClick={toggle}
            >
                <Cell alignitems='center' justifycontent='center' className={css.ClinicIcon}>
                    <Icon name={iconsTypes.clinic} size="x2"/>
                </Cell>
                <Row grow="1" className={css.ClinicTitleWrapper}>
                    <Text text={company} size='x3' ellipsis={true} className={css.ClinicTitle}/>
                </Row>
                <Cell alignitems='center' justifycontent='center' paddingLeft='1rem' className={css.ClinicArrowIcon}>
                    <Icon name={iconsTypes.arrowDown} size="x2" />
                </Cell>
            </Row>
            <Row className={css.Projects}>
                <Col className={classNames(css.List, css._border)}>
                    {
                        filteredProjects.map((project: IUserProjectType) => {
                            const isActive = project.ProjectUID === HMSProjectId;
                            
                            return (
                                <Row
                                    key={project.ProjectUID}
                                    className={classNames(css.Project, isActive ? css._active : "")}
                                    alignitems="center"
                                    onClick={() => onSelectProject(project.ProjectUID)}
                                >
                                    <Row grow="1" paddingRight="0.5rem" className={css.ProjectTitleWrapper}>
                                        <Text text={project.ProjectName || "-"} size='x2' ellipsis={true} className={css.ProjectTitle}/>
                                    </Row>
                                    <Button
                                        text="IDE"
                                        type="secondary"
                                        size="small"
                                        className={css.IDEButton}
                                        onClick={()=> goToIDE(project.ProjectUID)}
                                    />
                                </Row>
                            );
                        })
                    }
                </Col>
            </Row>
        </Col>
    );
};

export { ClinicList };