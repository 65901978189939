import { createSlice } from '@reduxjs/toolkit';
import { NotificationData } from '../../../DataProviders/Notifications/Notifications.data';

export interface INotificationState {
    id: number,
    label: string,
    text: string,
    isNew: boolean,
    expanded: boolean
}

const initialState: INotificationState[] = []

export const notificationsSlice = createSlice({
    name: 'Notifications',
    initialState,
    reducers: {

        // Get Notifications
        getNotificationsAction: (state, action) => {
            console.log('Get Notifications Action --- ', action);
            return [...state, ...NotificationData];
        },
    }
});

export const {
    getNotificationsAction
} = notificationsSlice.actions;
export default notificationsSlice.reducer;