import React, {FC, useEffect} from "react";
import {Cell, Checkbox, Icon, Row, Text} from "lavaa";
import {classNames} from "../../../../Tools";
import css from "./style.module.scss";
import {IClientGuidelinesType} from "../../../../Redux/Slices/Demo/Demo.data";
import {getGuidelinePriorityIcon} from "./guidelinesPriority";
import {useDemo} from "../../../../Hooks/useDemo";

interface IProps {
    guideline: IClientGuidelinesType
}

// Treatment
const Guideline: FC<IProps> = React.memo((props) => {

    const { guideline } = props;
    const { changeClientGuidelineStatus, changeGuidelineStatus } = useDemo();
    const [isDone, setIsDone] = React.useState<boolean>(guideline.isChecked);

    // const isDone = guideline.isChecked;

    useEffect(() => {
        setIsDone(guideline.isChecked);
    }, [guideline.isChecked]);

    function changeHandler(value: boolean){

        // If clientGuidelineUID exists, change status by this field
        if(guideline.hasOwnProperty('clientGuidelineUID')){
            const cGUID = guideline.clientGuidelineUID;
            changeClientGuidelineStatus(cGUID);
            setIsDone(value);
            return;
        }

        if(guideline.hasOwnProperty('guidelineUID') && guideline.hasOwnProperty('projectUID') && guideline.projectUID){
            const gUID = guideline.guidelineUID;
            const projectUID = guideline.projectUID;
            changeGuidelineStatus(gUID, projectUID);
            setIsDone(value);
            return;
        }
    }

    let iconPriority = getGuidelinePriorityIcon(guideline.priority);

    return (
        <Row alignitems="center">
            <Cell shrink="0">
                <Icon name={iconPriority}/>
            </Cell>
            <Cell shrink="0" paddingLeft="0.75rem" paddingRight="0.5rem">
                <Checkbox checked={isDone} disabled={isDone} onChange={changeHandler}/>
            </Cell>
            <Cell>
                <Text size="x2" ellipsis={true} maxLines={3} className={classNames(css.TreatmentText, isDone ? css.done : "")}>{guideline.text}</Text>
            </Cell>
        </Row>
    )
});

export {Guideline};
