import React, {FC} from "react";
import {Col, iconsTypes, ITabsSingleContent, Row, TabsSingle, Text} from "lavaa";
import css from "./PatientPageMenu.module.scss";
import {useDemo} from "../../../../Hooks/useDemo";
import {BackButton} from "../../../Common/BackButton/BackButton.component";
import {LastUpdate} from "./LastUpdate";

interface IProps {
    setActiveTab: (arg0: number) => void
}

const tabs: ITabsSingleContent[] = [
    {
        title: 'Personal Info',
        icon: iconsTypes.person
    },
    {
        title: 'Health Card',
        icon: iconsTypes.healthCard
    },
    {
        title: 'Insights',
        icon: iconsTypes.treatment
    },
    {
        title: 'Basic Assessment',
        icon: iconsTypes.basicAssessment
    },
    {
        title: 'Surveys',
        icon: iconsTypes.queryStats
    },
];

// Patient Page Menu
const PatientPageMenu: FC<IProps> = React.memo((props) => {

    const {setActiveTab} = props;
    const { activeClient } = useDemo();

    if ( !activeClient?.client ) return null;

    const name = ((activeClient.client?.FirstName ?? "") + " " + (activeClient.client?.LastName ?? "")).trim();


    return (
        <Col>
            <Col paddingLeft="0.5rem" paddingRight="0.5rem" paddingTop="2rem">
                <Row justifycontent="start" alignitems="center" paddingBottom="1.25rem">
                    {/* Back Button */}
                    <Row paddingRight="0.75rem">
                        <BackButton link={"/patients"}/>
                    </Row>
                    <Row>
                        <Text text="Patient Page" size="x6" bold={true}/>
                    </Row>
                </Row>
                <Col className={css.UserInfo}>
                    <Row marginBottom="0.75rem">
                        <Text size="x5" bold="true">{name}</Text>
                    </Row>
                    <Row>
                        <Col>
                            <Text size="x3">#{activeClient.client?.SourceClientID}</Text>
                        </Col>
                        <LastUpdate/>
                    </Row>
                </Col>
                <Row className={css.UserData} marginBottom="0.75rem">
                    <Row marginRight="2rem">
                        <Col paddingRight="0.75rem">
                            <Text size="x2">Gender:</Text>
                        </Col>
                        <Col>
                            <Text size="x2" bold="true">{activeClient.client?.Gender === "F" ? "Female" : "Male"}</Text>
                        </Col>
                    </Row>
                    <Row>
                        <Col paddingRight="0.75rem">
                            <Text size="x2">Age:</Text>
                        </Col>
                        <Col>
                            <Text size="x2" bold="true">{activeClient.client?.FullYears}</Text>
                        </Col>
                    </Row>
                </Row>
            </Col>
            <Row className={css.Tabs} paddingLeft="0.5rem" paddingTop="0.5rem" paddingBottom="0.5rem" paddingRight="0.5rem">
                <TabsSingle content={tabs} onChange={setActiveTab}/>
            </Row>
        </Col>
    )
});

export {PatientPageMenu};
