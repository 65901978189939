import {FC, useContext} from 'react';
import {Row, Container, Text, Col} from 'lavaa';
import {CSVLoader} from "../Common/FileLoader/FileLoader.component";
import {useDemo} from '../../Hooks/useDemo';
import css from './PatientsUpload.module.scss';
import {AppCtx} from "../../Context/App.context";
import { BackButton } from '../Common/BackButton/BackButton.component';

// PatientsUpload
const PatientsUpload: FC = () => {


    let {deviceType} = useContext(AppCtx);
    const {runDemoClients} = useDemo();

    let Wrap: any = deviceType === 'mobile' ? Col : Row;

    function uploadHandler(rawData: string[]) {
        const data = rawData.map((row: string) => JSON.stringify(row));
        runDemoClients(data);
    }

    return (
        <Container paddingLeft="1rem" paddingRight="1rem" paddingTop="1.5rem" paddingBottom="1.5rem">
            <Wrap paddingBottom="2rem">
                <Row>
                    {/* Back Button */}
                    <Row paddingLeft="1.5rem" paddingRight="1.5rem" alignitems="center">
                        <BackButton />
                    </Row>

                    <Row justifycontent="center" alignitems="center">
                        <Text text="Add file with the list of patients" size="x5" bold={true} className={css.Text}/>
                        <a href="/test_clients.csv" target="_blank" download>
                            <Text text="(Download example)" size="x5" bold={true} />
                        </a>
                    </Row>
                </Row>
            </Wrap>
            <Row justifycontent="center">
                <CSVLoader onUpload={uploadHandler}/>
            </Row>
        </Container>
    );
};

export {PatientsUpload};