import {createSlice} from '@reduxjs/toolkit';
import {IQuestionnaireType} from "./Questionnaire.data";

export type IQuestionnaireStatisticsType = {
    QuestionsAmount: number,
    AnsweredQuestionsAmount: number,
    ModulesQuestionsPercentage: any[]
}

interface IQuestionnaireTypeState {
    questionnaire: IQuestionnaireType[],
    statistics?: IQuestionnaireStatisticsType
}

const initialState: IQuestionnaireTypeState = {
    questionnaire: [],
    statistics: undefined
};

export const questionnaireSlice = createSlice({
    name: 'Questionnaire',
    initialState,
    reducers: {

        // Get Questionnaire
        getQuestionnaireAction: (state, action) => {
        },

        // Receive Questionnaire
        receiveQuestionnaireAction: (state, action) => {
            state.questionnaire = action.payload.data;
        },

        // Get Questionnaire Statistics
        getQuestionnaireStatisticsAction: (state, action) => {
        },

        // Receive Questionnaire Statistics
        receiveQuestionnaireStatisticsAction: (state, action) => {
            // console.log('Receive Questionnaire Statistics Action --- ', action.payload.data);
            state.statistics = {
                QuestionsAmount: action.payload.data.QuestionsAmount,
                AnsweredQuestionsAmount: action.payload.data.AnsweredQuestionsAmount,
                ModulesQuestionsPercentage: action.payload.data.ModulesQuestionsPercentage
            }
        },

        // Add Questionnaire Answers
        addQuestionnaireAnswersAction: (state, action) => {
        },

        // Receive Questionnaire Answers
        receiveAddQuestionnaireAnswersAction: (state, action) => {
        },

        // Clear Questionnaire
        clearQuestionnaireAction: (state, action) => {
            state.questionnaire = [];
            state.statistics = undefined;
        },

        // Get Open Questionnaire
        getOpenQuestionnaireAction: (state, action) => {
        },

        // Receive Open Questionnaire
        receiveOpenQuestionnaireAction: (state, action) => {
            state.questionnaire = action.payload.data;
            console.log('Receive Open Questionnaire Action --- ', action.payload.data);
        },

        // Add Open Questionnaire Answers
        addOpenQuestionnaireAnswersAction: (state, action) => {
        },

        // Receive Open Questionnaire Answers
        receiveAddOpenQuestionnaireAnswersAction: (state, action) => {
        },
    }
});

export const {
    getQuestionnaireAction,
    receiveQuestionnaireAction,
    getQuestionnaireStatisticsAction,
    receiveQuestionnaireStatisticsAction,
    addQuestionnaireAnswersAction,
    receiveAddQuestionnaireAnswersAction,
    clearQuestionnaireAction,
    getOpenQuestionnaireAction,
    receiveOpenQuestionnaireAction,
    addOpenQuestionnaireAnswersAction,
    receiveAddOpenQuestionnaireAnswersAction
} = questionnaireSlice.actions;
export default questionnaireSlice.reducer;