import {useStatistics} from "../../../../../Hooks/UseStatistics";
import {IStatisticType} from "../../../../../Redux/Slices/Statistics/Statistics.data";
import {PieChartDataType} from "lavaa";

type RiskDataType = {
	count: number,
	riskPercentage: number,
}

type PatientsDataType = {
	[key: string]: RiskDataType
}

export function usePatientsStat(moduleId?: string, maxRows: number = 5): PieChartDataType[] {
	const {statisticsData} = useStatistics();
	return prepareData(statisticsData, maxRows, moduleId);
}

function prepareData(statisticsData: IStatisticType[], maxRows: number, moduleId?: string): PieChartDataType[]{
	const data = filterData(statisticsData, moduleId);

	const dataSorted = sortData(data);

	return dataFormatter(dataSorted, maxRows);
}

// Merge data by Risk Group type
// Filter by module if moduleId is provided
function filterData(statisticsData: IStatisticType[], moduleId?: string): PatientsDataType{
	const data: PatientsDataType = {};
	statisticsData.forEach((item: IStatisticType) => {
		// Get data
		const moduleUID = item.ModuleUID;
		const risk = item.ResultGroupName;
		const count = item.Amount;
		const riskPercentage = item.ResultPercentage;

		// Filter by module if moduleId is provided
		if(moduleId && moduleUID !== moduleId){
			return;
		}

		// Create risk group if not exists
		if(!data.hasOwnProperty(risk)){
			data[risk] = {
				count: 0,
				riskPercentage: 0,
			}
		}

		// Increase risk group count
		data[risk].count += count;

		// Update risk percentage if it is higher
		if(riskPercentage > data[risk].riskPercentage){
			data[risk].riskPercentage = riskPercentage;
		}
	});

	return data;
}

// Sort by risk percentage
function sortData(data: PatientsDataType): [string, RiskDataType][] {
	return Object.entries(data).sort((a: any, b: any) => {
		return b[1].riskPercentage - a[1].riskPercentage || b[1].count - a[1].count;
	});
}

function dataFormatter(dataSorted: [string, RiskDataType][], maxRows: number): PieChartDataType[]{
	const dataFormatted: PieChartDataType[] = [];

	dataSorted.forEach(([riskGroup, riskData]: [string, RiskDataType], index) => {
		const {count} = riskData;

		// Skip if index is higher than maxRows
		if(index >= maxRows){
			return;
		}

		// Add data to chart
		dataFormatted.push(
			{
				name: riskGroup,
				value: count,
				color: colors[index % colors.length]
			}
		)
	});

	return dataFormatted;
}

const colors = [
	"#FF3E1E", "#FFA726", "#FDD835",
	"#00E676", "#07B951","#14C9C9",
	"#3B54D0","#7C4DFF","#B1BAF4",
	"#90BE6D", "#6F767E"
];