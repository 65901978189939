import { createContext, FC, useState } from 'react';
import {DeviceType} from "./App.types";

export const AppCtx = createContext<{
	[key: string]: any;
}>({});

export const AppContext: FC<any> = (props) => {

    const { children } = props;

    // States
    const [ deviceType, setDeviceType ] = useState<DeviceType>('desktop');
    const [ performanceNow, setPerformanceNow ] = useState(0);

    const context = {

        // Device Type
        deviceType,
        setDeviceType,

        // Performance Now
        performanceNow,
        setPerformanceNow,

        // Is In Iframe
        isInIframe: () => window.top !== window,
    };

    return <AppCtx.Provider value={ {...context} }>
        { children }
    </AppCtx.Provider>
};
