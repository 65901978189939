import { FC, useContext, useEffect, useState } from 'react';
import { ModalsCtx } from '../../../../Context/Modals.context';
import { Modal, Row, Title, Col, iconsTypes, Icon, Text } from 'lavaa';
import parse from 'html-react-parser';
import css from './HelpCenterModal.module.scss';
import { useNavigate } from 'react-router-dom';
import { AppCtx } from '../../../../Context/App.context';
import { useHelpCenter } from '../../../../Hooks/UseHelpCenter';

const toursData = [ // Mock
    {
        name: 'Create Project',
        steps: [
            {disableBeacon: true, hideFooter: true, spotlightClicks: true, title: 'title', content: parse('<div><b>asd</b></div>'), target: '[data-userpilot="CreateProjectActionButton"]'},
            {disableBeacon: true, title: 'title', content: 'content', target: '[data-userpilot="ProjectNameInput"]'}
        ],
        route: 'openprojects'
    }
];

interface IProps {}

// Help Center Modal
const HelpCenterModal: FC<IProps> = (props) => {

    // Contexts
    const { setUserTour, deviceType } = useContext(AppCtx);

    // Hooks
    const { helpCenterData } = useHelpCenter();

    // States
    const [faqData, setFaqData] = useState<any[]>([]);
    const [category, setCategory] = useState<any[]>([]);
    const [toursData, setToursData] = useState<any[]>([]);
    const [faqItemSelected, setFaqItemSelected] = useState<any>(null);

    let { helpModal, setHelpModal } = useContext(ModalsCtx);

    const navigate = useNavigate();

    // Handle Close
    const handleClose = () => {
        setHelpModal(false);
    };

    // Handle go back
    const onGoBack = () => {
        setFaqItemSelected(null);
    };

    // On FAQ Item Selected
    const onFaqItemSelected = (item: any) => {
        setFaqItemSelected(item);
    };

    // On Tour Selected
    const tourSelected = (item: any) => {
        navigate(item.route);
        setUserTour(item.steps);
    };

    // Watch Help Center Data
    useEffect(() => {
        const { faQs = [], tours = [] } = helpCenterData;

        setFaqData([...faQs]);
        // setCategory(Array.from(new Set(faQs.map((item: any) => item.category))));
        setToursData(tours);
    }, [helpCenterData.faQs, helpCenterData.tours]);

    // Watch Window active state
    useEffect(() => {
        if (helpModal === false && faqItemSelected !== null) {
            setFaqItemSelected(null);
        }
    }, [helpModal]);

    return <Modal
        active={ helpModal }
        overlay={ false }
        width={deviceType === 'mobile' ? '100%' : '360px'}
        height={deviceType === 'mobile' ? '100%' : '90vh'}
        positionRight={deviceType === 'mobile' ? '0' : '1rem'}
        positionBottom={deviceType === 'mobile' ? '0' : '1rem'}
        onClose={ handleClose }
        title="Help center"
        centerTitle={true}
        backButton={faqItemSelected ? true : false}
        onGoBack={onGoBack}
        type={deviceType === 'mobile' ? 'fullscreen' : 'small'}
        scroll={true}
        footerDisabled={true}
        draggable={true}
        bodyStyle={{paddingLeft: 0, paddingRight: 0, backgroundColor: 'var(--primary-bg-color)'}}
    >
        {/* Main Content */}
        {
            faqItemSelected === null && <>

                {/* FAQs */}
                {
                    faqData.length === 0 ? null :
                    <>
                        {/* Title */}
                        <Row paddingBottom="1rem" paddingLeft="1rem" paddingRight="1rem">
                            <Title>FAQ</Title>
                        </Row>

                        {/* List Of FAQs */}
                        <Col className={css.List}>
                            {
                                faqData.map((item: any, index: number) => <Row key={'faq'+index} onClick={() => onFaqItemSelected(item)} className={css.ListItem} alignitems="center" paddingBottom="0.75rem" paddingTop="0.75rem" paddingLeft="1rem" paddingRight="1rem">
                                    <Col grow="1">
                                        <Row className={css.Category} justifycontent="end">{item.category}</Row>
                                        <Row>
                                            <Row>
                                                <Icon name={iconsTypes.doc} size="x2" />
                                            </Row>
                                            <Row paddingLeft="0.25rem">
                                                <Text size="x3" className={css.Text}>{item.name}</Text>
                                            </Row>
                                        </Row>
                                    </Col>
                                    

                                    {/* <span className={css.Category}>{item.category}</span> */}
                                </Row>)
                            }
                        </Col>
                    </>
                }

                {/* Tours */}
                {
                    toursData.length === 0 ? null :
                    <>
                        {/* Title */}
                        <Row paddingTop="1rem" paddingBottom="1rem" paddingLeft="1rem" paddingRight="1rem">
                            <Title>User Tours</Title>
                        </Row>

                        {/* List Of Tours */}
                        <Col className={css.List}>
                            {
                                toursData.map((item: any, index: number) => <Row key={'tour'+index} onClick={() => tourSelected(item)} className={css.ListItem} alignitems="center" paddingBottom="0.75rem" paddingTop="0.75rem" paddingLeft="1rem" paddingRight="1rem">
                                    <Row>
                                        <Icon name={iconsTypes.play} size="x2" />
                                    </Row>
                                    <Row paddingLeft="0.25rem">
                                        <Text size="x4" className={css.Text}>{item.name}</Text>
                                    </Row>
                                </Row>)
                            }
                        </Col>
                    </>
                }
            </>
        }

        {/* FAQ Item Selected Content */}
        {
            faqItemSelected !== null && <>
                <Row paddingLeft="1rem" paddingRight="1rem" className={css.InsideItem}>
                    {parse(faqItemSelected.content)}
                </Row>
            </>
        }
    </Modal>
};

export { HelpCenterModal };
