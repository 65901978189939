import React, {FC, useState} from "react";
import css from "../style.module.scss";
import {Row, InputNumber} from "lavaa";
import {classNames} from "../../../../Tools";

interface IProps {
    onChange: (data: any) => void,
    value: number | undefined,
    onDelete?: () => void,
    placeholder: string | undefined,
    min?: number,
    max?: number,
    step?: number,
}

// Questionnaire Field Number
const QuestionnaireFieldNumber: FC<IProps> = React.memo((props) => {
    const {onChange, value, onDelete} = props;
    const {placeholder, min, max, step} = props;
    const [focus, setFocus] = useState<boolean>(false);

    const hasContent =  value !== undefined;

    const classes = classNames(
        css.Field,
        hasContent ? css._hasContent : "",
        focus ? css._focus : "",
    );

    return (
        <Row className={css.InputRow}>
            <InputNumber
                className={classes}
                onChange={onChange}
                value={value}
                onFocus={() => {setFocus(true)}}
                onBlur={() => {setFocus(false)}}
                placeholder={placeholder || "Type your answer here"}
                min={min}
                max={max}
                step={step}
                size="large"
            />
            {/*{*/}
            {/*    onDelete ? (*/}
            {/*        <Col className={css.Delete} marginLeft="0.5rem" onClick={onDelete}>*/}
            {/*            <Icon name={iconsTypes.trash} size='x2'/>*/}
            {/*        </Col>*/}
            {/*    ) : (*/}
            {/*        <Col className={css.DeleteFiller} marginLeft="0.5rem"/>*/}
            {/*    )*/}
            {/*}*/}
        </Row>
    )
});

export {QuestionnaireFieldNumber};
