import React, {FC} from 'react';
import { Row, Text } from 'lavaa';
import { useDemo } from "../../../../../Hooks/useDemo";
import { useClients } from "../../../../../Hooks/UseClients";
import { convertValueWithScheme } from "../../../../../Tools/schemeData";
import css from './PersonalInfo.module.scss';

interface IData {
  label: string,
  value: string
}

const PersonalInfo: FC = React.memo(() => {

  const { activeClient } = useDemo();
  const { clientScheme } = useClients();

  if(!activeClient?.client?.PersonalRecords || !clientScheme?.PersonalRecords) return null;

  const
    data = activeClient.client,
    info = data.PersonalRecords[0];

  const dataInfo: IData[] = [
    {
      label: "Age",
      value: data.FullYears,
    },
    {
      label: "Gender",
      value: data.Gender,
    }
  ];

  Object.entries(info).forEach(([key, value]) => {
    const scheme = clientScheme.PersonalRecords.subfields[key];
    if(!scheme) return;

    dataInfo.push(
      {
        label: scheme.label,
        value: convertValueWithScheme(scheme, value),
      }
    )
  });

  return (
    <Row paddingTop="0.5rem" paddingBottom="1rem" paddingLeft="1.25rem" paddingRight="1.25rem">
      <div className={css.UserInfo}>
        {
          dataInfo.map((item: any, index: number) => (
              <Row key={ index } className={css.UserInfoItem}>
                <Text text={item.label} size="x3" ellipsis={true} className={css.ItemLabel}/>
                <Text text={item.value} size="x3" bold="true" className={css.ItemValue}/>
              </Row>
          ))
        }
      </div>
    </Row>
  )
});

export { PersonalInfo };
