import { FC } from 'react';
import { Row, Col, iconsTypes, Icon } from 'lavaa';

type Props = {
    value: string;
    data: any;
    colDef: any;
};

// AWVCurrentYearCellRenderer Component
export const AWVCurrentYearCellRenderer: FC<Props> = (props: Props) => {
    const { value } = props;
    
    return (
        <Col grow="1" shrink="1" justifycontent="center" alignitems="center" style={{whiteSpace: 'normal'}}>
            
            {/* Icons */}
            <Row paddingBottom="0.25rem">
                {/* Checked */}
                {
                    value !== 'checkmark' ? null : <Icon name={iconsTypes.awvChecked} />
                }

                {/* Warning */}
                {
                    value !== 'warning' ? null : <Icon name={iconsTypes.awvWarning} />
                }

                {/* Clock */}
                {
                    value !== 'clock' ? null : <Icon name={iconsTypes.awvClock} />
                }
            </Row>
        </Col>
    )
};