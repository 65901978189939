import { createSlice } from '@reduxjs/toolkit';
import { IOutput } from "../../../Components/FlowOutput/FlowOutput.types";
import { formatDate } from "../../../Tools/date";

interface IProjectDataState {
    output: IOutput[]
}

const initialState: IProjectDataState = {
    output: []
}

export const moduleDataSlice = createSlice({
    name: 'ModuleData',
    initialState,
    reducers: {

        // Add Project Data Output
        addModuleDataOutputAction: (state, action) => {
            const color = action.payload[1] || 'default';
            const output: IOutput = {
                message: action.payload[0],
                date: formatDate(new Date()),
                color: color
            }
            state.output.push(output);
        },

        // Reset Project Data
        resetModuleDataAction: (state, action) => {
            return initialState;
        },
    }
});

export const {
    addModuleDataOutputAction,
    resetModuleDataAction
} = moduleDataSlice.actions;
export default moduleDataSlice.reducer;