export const reportLinks: any = {
	"QMDiabetis": {
		link: 'QMDiabetis',
		tab: 0,
	},
	"QMHtn": {
		link: 'QMHtn',
		tab: 1,
	},
	"QMDep": {
		link: 'QMDep',
		tab: 2,
	}
}