import { createSlice } from '@reduxjs/toolkit';

const initialState = {} as any;

export const typesSlice = createSlice({
    name: 'Types',
    initialState,
    reducers: {

        // Get Types
        getTypesAction: (state, action) => state,

        // Receive Types
        receiveTypesAction: (state, action) => {
            console.log('Receive Types Action --- ', action.payload.data);
            return action.payload.data;
        },

        // Get Type
        getTypeAction: (state, action) => {
            console.log('Get Type Action --- ');
        },

        // Receive Type
        receiveTypeAction: (state, action) => {
            console.log('Receive Type Action --- ');
        }
    }
});

export const { 
    getTypesAction,
    getTypeAction,
    receiveTypesAction,
    receiveTypeAction
} = typesSlice.actions;
export default typesSlice.reducer;