import { createSlice } from '@reduxjs/toolkit';
import {IUserProjectType} from "./Account.data";

interface AccountStateType {
    isAuth: boolean | null,
    id: any,
    name: string,
    position: string,
    access_token: string,
    refresh_token: string,
    logo: string,
    poweredBy: string,
    HMSProjectId: string | null,
    projects: IUserProjectType[],
    availableReports: null | string[]
}

const initialState: AccountStateType = {
    isAuth: null,
    id: null,
    name: '',
    position: '',
    access_token: '',
    refresh_token: '',
    logo: '',
    poweredBy: '',
    HMSProjectId: null,
    projects: [],
    availableReports: null
};

export const accountSlice = createSlice({
    name: 'Account',
    initialState,
    reducers: {

        // Login Account
        loginAccountAction: (state, action) => {
            console.log('Login Account Action --- ');
        },

        // Receive Login
        receiveLoginAction: (state, action) => {
            console.log('Receive Login Action --- ');

            const payload = action.payload[0];
            if(payload.errorMessage.length > 0) return state;

            return {...state, ...{
                access_token: payload.data.access_token,
                refresh_token: payload.data.refresh_token
            }};
        },

        // Get User Info
        getAccountAction: (state, action) => {
            console.log('Get User Info Action --- ');
        },

        // Receive User Info
        receiveUserInfoAction: (state, action) => {
            const payload = action.payload[0];
            if(payload.errorMessage.length > 0) return state;
            
            const { theme } = payload.data;
            const logo = theme?.logo ?? '';
            const poweredBy = theme?.poweredBy ?? '';
            return {...state, ...payload.data, logo, poweredBy, ...{isAuth: true}};
        },

        // Refresh Token
        refreshTokenAction: (state, action) => {
            console.log('Refresh Token Action --- ');
        },

        // Receive Refresh Token
        receiveRefreshTokenAction: (state, action) => {
            const payload = action.payload[0];
            if(payload.errorMessage.length > 0){
                state.isAuth = false;
                return state;
            }

            console.log('Receive Refresh Token Action --- ', payload.data.refresh_token.slice(-5));

            state.access_token = payload.data.access_token;
            state.refresh_token = payload.data.refresh_token;
        },

        // Registration Account
        registrationAccountAction: (state, action) => {
            console.log('Registration Account Action --- ');
        },

        // Logout Account
        logoutAccountAction: (state, action) => {
            console.log('Logout Account Action --- ');
            return {...initialState, ...{isAuth: false}};
        },

        // Get User Projects
        getUserProjects: (state, action) => {
            console.warn('Get User Projects Action --- ');
        },

        // Receive User Projects
        receiveUserProjectsAction: (state, action) => {
            const payload = action.payload;
            if(payload.errorMessage.length > 0) return state;

            state.projects = payload.data.projects;
            state.HMSProjectId = payload.data.HMSProjectId;
        },

        // Select User Projects
        selectUserProjectsAction: (state, action) => {
            state.HMSProjectId = action.payload[0];
        },
    }
});

export const {
    getAccountAction,
    receiveUserInfoAction,
    loginAccountAction,
    refreshTokenAction,
    registrationAccountAction,
    logoutAccountAction,
    getUserProjects,
    receiveUserProjectsAction,
    selectUserProjectsAction
} = accountSlice.actions;
export default accountSlice.reducer;