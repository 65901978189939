import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, iconsTypes } from 'lavaa';

interface IProps{
    link?: string
}

// Back Button
const BackButton: FC<IProps> = (props) => {

    const { link } = props;

    const navigate = useNavigate();

    // On Go Back
    const goBack = () => {
        if(link){
            navigate(link);
            return;
        }

        navigate(-1);
    };

    return <Button icon={iconsTypes.arrowBack} iconSize="x2" type="secondary" onClick={goBack} variant="text" />
};

export { BackButton };