import React, {FC, useContext} from "react";
import {Row, Title, Dropdown, iconsTypes, Container, ScrollContainer, Cell, Button} from 'lavaa';
import {DashboardDesktopGrid} from "./DashboardDesktopGrid.com";
import css from "../Dashboard.module.scss";
import {ModalsCtx} from "../../../../Context/Modals.context";

type Props = {
	filterData: any,
	selectedFilter: any,
	selectFilterHandler: (data: any) => void,
	filterModuleId: string | undefined,
}

// Dashboard Desktop
const DashboardDesktop: FC<Props> = ({
	filterData,
	selectedFilter,
	selectFilterHandler,
	filterModuleId
}) => {

	const { setMenuDashboardCardsModalActive } = useContext(ModalsCtx);

	function clickSelectCardsHandler(){
		setMenuDashboardCardsModalActive(true)
	}

	return (
		<ScrollContainer>
			<Container
				paddingLeft="1rem"
				paddingRight="1rem"
				paddingTop="1.5rem"
				paddingBottom="1.5rem"
				className={css.Dashboard}
			>

				<Row alignitems="center" paddingBottom="2rem">

					{/* Title */}
					<Row grow="1" style={{whiteSpace: 'nowrap'}}>
						<Title className={css.Title} size='x6'>Dashboard</Title>
					</Row>

					{/* Filter */}
					<Row alignitems="center" grow="0">

						{/* Drop Down */}
						<Dropdown
							icon={iconsTypes.filter}
							placeholder="Module type"
							hasSearch={true}
							data={filterData}
							selected={selectedFilter}
							onSelect={selectFilterHandler}
							hidePlaceholder={true}
						/>

						<Cell marginLeft="0.5rem">
							<Button
								type="secondary"
								text="Shown Charts"
								size="medium"
								onClick={clickSelectCardsHandler}
							/>
						</Cell>
					</Row>
				</Row>
				<DashboardDesktopGrid filterModuleId={filterModuleId}/>
			</Container>
		</ScrollContainer>
	);

};

export {DashboardDesktop};