import { FC, memo, useEffect, useState } from "react";
import { addSnackbar, Col, Container, Icon, iconsTypes, Modal, Row, Text } from "lavaa";
import { DataTable } from "../../../../DataTable/DataTable.com";
import { noop } from "../../../../../Tools/Noop";
import { useReports } from "../../../../../Hooks/UseReports";
import { useAccount } from "../../../../../Hooks/UseAccount";
import { useParams } from "react-router-dom";
import { ReportFilterType } from "../../../Reports.types";
import { useMixPanel } from "../../../../../Hooks/UseMixPanel";
import { dateStrComparator } from "../../../../DataTable/Comparators/Comparators";
import { formatTimestamp } from "../../../../../Tools/date";
import { copyToClipboard } from "../../../../../Tools/clipboard";
import css from "./DischargeTCM.module.scss";

const reportConfig: any[] = [
    // {
    //     name: 'ReportDischargeTCM',
    //     actions: ['snooze'],
    //     headers: [
    //         {
    //             columnName: 'Snoozed',
    //             dataType: 'text',
    //             displayName: 'Snooze Patient',
    //             isShownInAppReport: true,
    //             isShownInFileReport: false
    //         }
    //     ],
    //     columnModel: {
    //         field: 'Snoozed', 
    //         headerTooltip: 'Snooze Patient', 
    //         filter: false, 
    //         renderer: 'button', 
    //         action: 'snoozePatientConfirmOnly', 
    //         rendererOptions: {
    //             text: 'Snooze Patient', 
    //             type: 'secondary'
    //         }, 
    //         cellStyle: {
    //             border: 'none'
    //         }
    //     }
    // }
];

type Props = {
    setFilteredHeaders: (headers: any[]) => void;
    filteredHeaders: any[];
    filteredHeadersLastUpdate: number;
    onFilterUpdate: (data: any) => void;
    attributedFilter: any[];
};

// DischargeTCM Component
export const DischargeTCM: FC<Props> = memo((props) => {

    // Props
    const { setFilteredHeaders, filteredHeaders, filteredHeadersLastUpdate, onFilterUpdate, attributedFilter } = props;

    // Constants
    const reportName = 'ReportDischargeTCM';
    const filterTypes: any = {
        contains: 0,
        dateRange: 1,
        numberRange: 2,
        startsWith: 3,
        endsWith: 4,
        equals: 5,
        notEqual: 6,
        notContains: 7,
        blank: 8,
        notBlank: 9,
        lessThan: 10,
        greaterThan: 11,
        lessThanOrEqual: 12,
        greaterThanOrEqual: 13,
        lessThanOrNull: 14,
        greaterThanOrNull: 15
    };

    // public enum FilterType
    // {
    //     TextContain = 0,
    //     DateRange = 1,
    //     NumberRange = 2,
    //     TextStartWith = 3,
    //     TextEndWith = 4,
    //     Equal = 5,
    //     NotEqual = 6,
    //     NotContain = 7,
    //     Blank = 8,
    //     NotBlank = 9
    // }

    // Hooks
    const { requestReports, getReport, getClientDetailsById, clientDetails, clientDetailsLastUpdate, clearClientDetails, runReportAction, snoozeUpdated } = useReports();
    const { HMSProjectId, account } = useAccount();
    const { reportType = 'tcm' } = useParams();
    const reportData = getReport(reportName);
    const { track } = useMixPanel();

    // States
    const [clientDetailsId, setClientDetailsId] = useState<number>(0);
    const [clientDetailsModalActive, setClientDetailsModalActive] = useState(false);
    const [reload, setReload] = useState(0);
    const [headers, setHeaders] = useState([]);
    const [reset, setReset] = useState(0);
    const [rows, setRows] = useState([]);
    const [totalPages, setTotalPages] = useState(1);
    const [columnModel, setColumnModel] = useState([
        {field: 'PatientIdFullName', headerName: 'Patient', pinned: true, width: 250, renderer: ['PatientCellRenderer', {name : 'SnoozeRendererConfirmOnly', options: {useHandleAction: true}, props: {modalTitle: 'Remove Discharge', modalBodyText: 'Are you sure you want to remove the Discharge?', useReasons: true}}, 'ClientDetailsCellRenderer'], setClientDetailsId: (clientId: number) => {setClientDetailsId(clientId); track('Get Client ID', {report: 'Report Discharge TCM'});}, comparator: noop, /*floatingFilterComponent: PatientFilter*/},
        {field: 'DateOfBirth', comparator: dateStrComparator},
        {field: 'DischargeDate', comparator: dateStrComparator},
        {field: 'PhoneCallDate', comparator: dateStrComparator},
        {field: 'InPersonVisitDate', comparator: dateStrComparator},
        {field: 'BillingDate', comparator: dateStrComparator},
    ]);

    const snoozeSearch = [
        {filteredByField: 'StartDateForSnooze', filterType: filterTypes.greaterThanOrNull, filterTerm: null, filterFrom: formatTimestamp(+new Date()), filterTo: null},
        {filteredByField: 'FinishDateForSnooze', filterType: filterTypes.lessThanOrNull, filterTerm: null, filterFrom: formatTimestamp(+new Date()), filterTo: null, operator: 1}
    ];

    // Table Filter
    const [filter, setFilter] = useState<ReportFilterType>({
        page: 1,
        pageSize: 10,
        search: [],
        sort: {
            sortedByField: '',
            isAscending: true,
            sortingType: 0, // 0 - any; 1 - string; 2 - number; 999 - other;
        },
    });

    // Handle Table Data Changed
    const handleTableDataChanged = (data: any = {}) => {
        const { headers = [], rows = [], totalPages = 1  } = data;
        
        // Prepare Headers
        const indexedHeaders = headers
        .filter((row: any) => row.isShownInAppReport)
        .map((header: any) => {
            return {
                field: header.columnName, 
                headerName: header.displayName, 
                index: header.index
            };
        })
        .sort((a: any, b: any) => a.index - b.index);

        // Max Index
        let maxIndex = indexedHeaders.reduce((max: number, obj: any) => obj.index > max ? obj.index : max, 0);

        // Mix reportConfig with headers
        const reportConfigHeaders = reportConfig.find((config: any) => config.name === reportName)?.headers;
        if (reportConfigHeaders && headers.length > 0) {
            reportConfigHeaders.forEach((configHeader: any) => {
                const { columnName, displayName } = configHeader;
                const found = indexedHeaders.find((header: any) => header.field === columnName);
                if (!found) {
                    maxIndex++;
                    indexedHeaders.push({field: columnName, headerName: displayName, index: maxIndex});
                }
            });
        }
        
        // Prepare Column Model
        const updatedColumnModel = indexedHeaders.map((header: any) => {
            const { field, headerName } = header;
            let colModel: any = columnModel.find((col: any) => col.field === field);
            const reportConfigColumnModel = reportConfig.find((config: any) => config.name === reportName)?.columnModel;

            // Mix reportConfig with columnModel
            if (reportConfigColumnModel && reportConfigColumnModel.field === field) {
                colModel = {...colModel, ...reportConfigColumnModel};
            }
            
            return colModel ? {...colModel, headerTooltip: colModel.headerTooltip ? colModel.headerTooltip : headerName} : {field, headerTooltip: headerName};
        });
        
        setColumnModel(updatedColumnModel);
        setTotalPages(totalPages);
        setHeaders(indexedHeaders);
        setRows(rows);

        // Set filtered headers
        if (filteredHeaders.length === 0 && indexedHeaders.length > 0) {
            setFilteredHeaders(indexedHeaders);
            setReset(performance.now());
        }
    };

    // Handle Action
    const handleAction = (actionType: string, data: any) => {
        let updated = false;
        let isFilterChanged = false;
        let updatedFilter = {...filter};

        // Pagination Changed
        if (actionType === 'paginationChanged') {
            const { pageNumber, pageSize } = data;
            updated = true;
            updatedFilter = {...updatedFilter, page: pageNumber, pageSize: pageSize};
        }

        // Filter Changed
        if (actionType === 'filterChanged') {
            const fields: string[] = [];
            const search: any[] = Object.keys(data).map((key: string) => {
                const field = data[key];
                fields.push(field);

                return {
                    filteredByField: key,
                    filterType: filterTypes[field.type],
                    filterTerm: field.filter,
                    filterFrom: null,
                    filterTo: null
                };
            });
            
            updated = true;
            isFilterChanged = true;
            updatedFilter = {...updatedFilter, search};

            // Track Filtering
            track('Filtering', {report: reportName, action: 'filtering', columns: Object.keys(data).join(', ')});
        }
        
        // Sort Changed
        if (actionType === 'sortChanged') {
            updated = true;
            updatedFilter = {
                ...updatedFilter, 
                sort: {
                    ...filter.sort,
                    sortedByField: data ? data.colId : '',
                    isAscending: data ? data.sort === 'asc' : true
                }
            };

            // Track Sorting
            data && track('Sorting', {report: reportName, action: 'sorting', columnName: data.colId});
        }

        // Snooze Patient
        if (actionType === 'snoozePatient' && data) {
            const { clientId, from, to, reason } = data;
            
            const parameters = [
                {name: 'clientid', type: 'number', value: clientId},
                {name: 'start_date_for_snooze', type: 'datetime', value: formatTimestamp(from)},
                {name: 'finish_date_for_snooze', type: 'datetime', value: formatTimestamp(to)},
                {name: 'snooze_reason', type: 'text', value: reason}
            ];

            runReportAction(HMSProjectId, 'sp_report_discharge_action_snooze', parameters);
        }

        // Update Filter
        if (updated) {
            if (isFilterChanged == true) {
                updatedFilter = {...updatedFilter, page: 1};
            }

            setFilter({...updatedFilter});
            onFilterUpdate({...updatedFilter});
        }
    };

    // Handle Client Details Modal Close
    const handleClientDetailsModalClose = () => {
        clearClientDetails();
        setClientDetailsId(0);
        setClientDetailsModalActive(false);
    };

    // Handle Copy
    const handleCopy = async (value: string) => {
        await copyToClipboard(value);
        addSnackbar('success', 'Copied!', '');
    };
    
    // Watch HMSProjectId
    useEffect(() => {
        requestReports(HMSProjectId, reportName, filter.sort, [...filter.search, ...snoozeSearch, ...attributedFilter], filter.page, filter.pageSize);
    }, [HMSProjectId, reportName, filter, attributedFilter, snoozeUpdated, attributedFilter]);

    // Watch Report Data
    useEffect(() => {
        if (reportData?.lastUpdated) {
            handleTableDataChanged(reportData);
        }
    }, [reportData?.lastUpdated]);

    // Watch HMSProjectId & clientDetailsId
    useEffect(() => {
        if (clientDetailsId && HMSProjectId) {
            // Open Modal
            setClientDetailsModalActive(true);

            // Get Client Details
            getClientDetailsById(clientDetailsId, HMSProjectId);
        }
    }, [HMSProjectId, clientDetailsId]);

    return (
        <Container grow="1" className={css.DischargeTCM}>
            <DataTable 
                headers={filteredHeaders} 
                rows={rows} 
                columnModel={columnModel} 
                minColumnWidth={150} 
                totalPages={totalPages} 
                onAction={handleAction} 
                useServer={true}
                filteredHeadersLastUpdate={filteredHeadersLastUpdate}
            />

            {/* User Detail Modal */}
            <Modal active={clientDetailsModalActive} onClose={handleClientDetailsModalClose} onOk={handleClientDetailsModalClose} cancelDisabled={true} title="Reach out to the Patient">
                {
                    <Col>

                        {/* Phones */}
                        <Row paddingBottom="0.75rem">
                            <Row style={{minWidth: 120, fontWeight: 'bold'}}>Phones:</Row>
                            <Col>
                                {
                                    clientDetails.phones.length ? clientDetails.phones.map((phone: string, index: number) => (
                                        <Row key={index} alignitems="center">
                                            <Text>
                                                <a href={`tel:${phone}`}>{phone}</a>
                                                <span style={{cursor: 'pointer', paddingLeft: '0.5rem'}} onClick={() => handleCopy(phone)}><Icon name={iconsTypes.copy} /></span>
                                            </Text>
                                        </Row>
                                    )) : 'No data'
                                }
                            </Col>
                        </Row>

                        {/* Email */}
                        <Row paddingBottom="0.75rem">
                            <Row style={{minWidth: 120, fontWeight: 'bold'}}>Email:</Row>
                            <Col>
                            {
                                clientDetails.email.length ? clientDetails.email.map((email: string, index: number) => (
                                    <Row key={index} alignitems="center">
                                        <Text>
                                            <a href={`mailto:${email}`}>{email}</a>
                                            <span style={{cursor: 'pointer', paddingLeft: '0.5rem'}} onClick={() => handleCopy(email)}><Icon name={iconsTypes.copy} /></span>
                                        </Text>
                                    </Row>
                                )) : 'No data'
                            }
                            </Col>
                        </Row>

                        {/* Address */}
                        <Row>
                            <Row style={{minWidth: 120, fontWeight: 'bold'}}>Address:</Row>
                            <Row alignitems="center">
                                <Text>
                                    {clientDetails.address || 'No data'}
                                    {
                                    !clientDetails.address ? null : 
                                    <span style={{cursor: 'pointer', paddingLeft: '0.5rem'}} onClick={() => handleCopy(clientDetails.address)}>
                                        <Icon name={iconsTypes.copy} />
                                    </span>
                                }
                                </Text>
                            </Row>
                        </Row>
                    </Col>
                }
            </Modal>
        </Container>
    )
});