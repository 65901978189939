import React, {FC, useContext} from "react";
import {Row, Title, Col, PieChart, Icon, iconsTypes} from 'lavaa';
import css from "../../Dashboard.module.scss";
import {usePatientsStat} from "./utils";
import {EmptyChart} from "../emptyChart";
import {AppCtx} from "../../../../../Context/App.context";
import {classNames} from "../../../../../Tools";

type Props = {
    moduleId?: string,
    dragHandleProps?: any,
    isDragging?: boolean
}

// Patients Statistics
const PatientsStat: FC<Props> = ({
    moduleId,
    dragHandleProps,
    isDragging = false
}) => {
    let {deviceType} = useContext(AppCtx);
    const isMobile = deviceType === 'mobile';
    const isTablet = deviceType === 'tablet';

    const maxRows = isMobile ? 6 : 5;
    const dataFormatted = usePatientsStat(moduleId, maxRows);

    const legendAlign = isMobile ? 'center' : 'right';
    const legendVerticalAlign = isMobile ? 'bottom' : 'middle';
    const chartPosition = isMobile ? 'center' : 'left';
    const legendGrid = isMobile;
    const styles = isMobile ? {height: '320px'} : undefined;
    const hasChartMargin = !(isMobile || isTablet);

    return (
        <Col className={classNames(css.Widget, css._chart)}>
            {/* TODO - hided temporary */}
            {/*<Button icon={iconsTypes.trash} iconSize="x2" type="gray" onClick={() => {}} variant="text" className={css.DeleteIcon}/>*/}

            <Row className={css.WidgetHeader} alignitems="center" justifycontent="space-between">
                <Row className={classNames(css.HeaderMoveContainer, isDragging && css._dragging)} {...dragHandleProps} alignitems="center">
                    {
                        dragHandleProps && (
                            <Col className={css.WidgetMoveIcon}>
                                <Icon name={iconsTypes.move} size="x1"/>
                            </Col>
                        )
                    }
                    <Row className={css.WidgetTitle}>
                        <Title size="x3" maxLines={2} className={css.WidgetTitle}>Patients' Statistics</Title>
                    </Row>
                </Row>
            </Row>
            <Row className={css.Chart} style={styles} grow="1">
                {
                    dataFormatted.length === 0 ? (
                        <EmptyChart type="noData"/>
                    ) : (
                        <PieChart
                            data={dataFormatted}
                            legendAlign={legendAlign}
                            legendVerticalAlign={legendVerticalAlign}
                            chartPosition={chartPosition}
                            legendGrid={legendGrid}
                            hasChartMargin={hasChartMargin}
                        />
                    )
                }
            </Row>
        </Col>
    );

};



export {PatientsStat};