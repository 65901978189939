import React, {FC} from "react";
import {useSortable} from "@dnd-kit/sortable";
import {DashboardCards} from "../Dashboard.data";
import {AgeStat} from "../Charts/ageStat";
import {ModulesStat} from "../Charts/modulesStat";
import {GenderStat} from "../Charts/genderStat";
import {PatientsStat} from "../Charts/patientsStat";
import {TotalAgeStat} from "../Charts/totalAgeStat";
import {TotalGenderStat} from "../Charts/totalGenderStat";
import {DashboardReport} from "../Charts/reports/DashboardReport.com";
import {DashboardReportNames} from "../Charts/reports/DashboardReport.data";
import {classNames} from "../../../../Tools";
import css from "../Dashboard.module.scss";

type Props = {
	cardKey: string,
	filterModuleId: string | undefined,
}

// Dashboard Desktop Card
const DashboardDesktopCard: FC<Props> = ({
	cardKey,
	filterModuleId,
}) => {

	const {
		isSorting,
		isDragging,
		attributes,
		listeners,
		setNodeRef,
		transform,
		transition
	} = useSortable({ id: cardKey });

	const style = {
		transform: transform ? `translate3d(${transform.x}px, ${transform.y}px, 0)`: '',
		transition: transition || undefined,
	};

	const dragProps = {
		dragHandleProps: {
			...attributes,
			...listeners,
		},
		isDragging: isDragging,
		isSorting: isSorting,
	}

	const cardsComponents = {
		[DashboardCards.AgeStat.key]: <AgeStat moduleId={filterModuleId} {...dragProps}/>,
		[DashboardCards.ModulesStat.key]: <ModulesStat moduleId={filterModuleId} {...dragProps}/>,
		[DashboardCards.GenderStat.key]: <GenderStat moduleId={filterModuleId} {...dragProps}/>,
		[DashboardCards.PatientsStat.key]: <PatientsStat moduleId={filterModuleId} {...dragProps}/>,
		[DashboardCards.TotalAgeStat.key]: <TotalAgeStat moduleId={filterModuleId} {...dragProps}/>,
		[DashboardCards.TotalGenderStat.key]: <TotalGenderStat moduleId={filterModuleId} {...dragProps}/>,
		[DashboardCards.DischargeLast48HReport.key]: <DashboardReport reportName={DashboardReportNames.DischargeLast48H} {...dragProps}/>,
		[DashboardCards.HighWorryScoreReport.key]: <DashboardReport reportName={DashboardReportNames.HighWorryScore} initialSorting={{sortedByField: 'WorryScore', isAscending: false}} {...dragProps}/>,
		[DashboardCards.LongOverdueReport.key]: <DashboardReport reportName={DashboardReportNames.LongOverdue} initialSorting={{sortedByField: 'Overdue', isAscending: false}} {...dragProps}/>,
		[DashboardCards.NoAwvThisYearReport.key]: <DashboardReport reportName={DashboardReportNames.NoAwvThisYear} {...dragProps}/>,
		[DashboardCards.NoAwvThisYearLastYearReport.key]: <DashboardReport reportName={DashboardReportNames.NoAwvThisYearLastYear} {...dragProps}/>,
		[DashboardCards.GeneralReport.key]: <DashboardReport reportName={DashboardReportNames.General} reportLink="/reports/generalReport" {...dragProps}/>,
		[DashboardCards.DischargeTCMReport.key]: <DashboardReport reportName={DashboardReportNames.DischargeTCM} reportLink="/reports/tcm/DischargeTCM" {...dragProps}/>,
		[DashboardCards.AdmissionTCMReport.key]: <DashboardReport reportName={DashboardReportNames.AdmissionTCM} reportLink="/reports/tcm/AdmissionTCM" {...dragProps}/>,
	}

	if (!cardsComponents[cardKey]) return null;

	return (
		<div
			className={classNames(css.Card, isDragging && css._dragging)}
			style={style}
			ref={setNodeRef}
		>
			{cardsComponents[cardKey]}
		</div>
	);

};

export {DashboardDesktopCard};