import { FC } from 'react';
import { Routes, Route } from 'react-router-dom';
import { ExtensionSync } from '../Extension/ExtensionSync.component';
import { AuthRoute } from './Auth.route';
import { AuthProvider } from './AuthProvider.component';
import { FlowRoutes } from './Flow.route';
import {PublicRoutes} from "./Public.route";

// Routes Provider
const RoutesProvider: FC = () => {

    return (
        <Routes>
            
            {/* Extension */}
            <Route key="/extension/:refreshToken/:accessToken" path="/extension/:refreshToken/:accessToken" element={ <ExtensionSync /> } />
            
            {/* App */}
            <Route path="/" element={<AuthProvider/>}>
                { AuthRoute }
                { PublicRoutes }
                { FlowRoutes }
            </Route>
        </Routes>
    )
};

export { RoutesProvider };