import React, {FC} from "react";
import {Module} from "./index";
import {QuestionnaireField} from "../../../Questionnaire/field";
import {IQuestionnaireType} from "../../../../../Redux/Slices/Questionnaire/Questionnaire.data";
import {IModuleType} from "../../../../../Redux/Slices/Demo/Demo.data";
import {useDemo} from "../../../../../Hooks/useDemo";
import {Row, Text} from "lavaa";

interface IProps {
    filter: string,
    inputs: any,
    onChange: (field: string, data: any) => void,
    questionnaireData: IQuestionnaireType[],
}

// Questionnaire Fields
const QuestionnaireFields: FC<IProps> = React.memo((props) => {
    const {filter, inputs, onChange, questionnaireData} = props;

    const { modules } = useDemo();
    const isAll = filter === 'all';

    if(questionnaireData.length === 0 ) return(
        <Row>
            <Text size="x4">Data is completed. Come back later.</Text>
        </Row>
    );

    if(isAll){
        return(
            <>
                {
                    questionnaireData.map((question: IQuestionnaireType, num) => {
                        const id = question.Parameter.ParameterID.toString();
                        const value = inputs[id] ?? undefined;
                        return (
                            <QuestionnaireField question={question} key={id} num={num} value={value} onChange={onChange}/>
                        )
                    })
                }
            </>
        )
    }

    const module = modules.find((module: IModuleType) => module.ModuleUID === filter);
    const moduleName = module?.ModuleName ?? "";
    const moduleUID = module?.ModuleUID ?? "";

    const questionnaireFilteredData = questionnaireData.filter((row: IQuestionnaireType) => row.ModuleUIDs.includes(moduleUID));

    const count = questionnaireFilteredData.length;

    return(
        <Module title={moduleName} questions={count}>
            {
                (count === 0) && (
                    <Row>
                        <Text size="x4">Module data is completed. Come back later.</Text>
                    </Row>
                )
            }
            {
                questionnaireFilteredData.map((question: IQuestionnaireType, num) => {
                    const id = question.Parameter.ParameterID.toString();
                    const value = inputs[id] ?? undefined;

                    return (
                        <QuestionnaireField question={question} key={id} num={num} value={value} onChange={onChange}/>
                    );
                })
            }
        </Module>
    )

});

export {QuestionnaireFields};
