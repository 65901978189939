import { createSlice } from '@reduxjs/toolkit';
import { rulesetAccessType } from "./Rulesets.data";

interface IRulesetTypeState {
    templates: {
        open: any[],
        company: any[],
        my: any[],
    },
    nodes: {
        [key: string]: any
    },
}

const initialState:IRulesetTypeState = {
    templates: {
        open: [],
        company: [],
        my: [],
    },
    nodes: {},
};

export const rulesetsSlice = createSlice({
    name: 'Rulesets',
    initialState,
    reducers: {

        // Get Rulesets
        getRulesetsAction: (state, action) => {
            console.log('Get Rulesets Action --- ');
        },

        // Receive Rulesets
        receiveRulesetsAction: (state, action) => {
            const { type, results } = action.payload.data;

            switch (type) {
                case rulesetAccessType.open:
                    state.templates.open = results;
                    break;
                case rulesetAccessType.company:
                    state.templates.company = results;
                    break;
                case rulesetAccessType.my:
                    state.templates.my = results;
                    break;
            }
        },

        // Get Ruleset
        getRulesetAction: (state, action) => {
            console.log('Get Ruleset Action --- ');
        },

        // Receive Ruleset
        receiveRulesetAction: (state, action) => {
            const ruleset = action.payload.data;
            if(typeof ruleset !== 'object') {
                return state;
            }

            ruleset['jsonLogic'] = (ruleset['jsonLogic'] && ruleset['jsonLogic'].length > 0) ? JSON.parse(ruleset['jsonLogic']): "";

            if (Array.isArray(ruleset['jsonLogic']) === false) {
                ruleset['jsonLogic'] = [];
            }
            state.nodes[ruleset.nodeId] = ruleset;
        },

        // Add Ruleset
        addRulesetAction: (state, action) => {
            console.log('Add Ruleset Action --- ');
        },

        // Receive Add Ruleset
        receiveAddRulesetAction: (state, action) => {
            const ruleset = action.payload.data;
            state.templates.my = [...state.templates.my, ruleset];
        },

        // Update Ruleset
        updateRulesetAction: (state, action) => {
            console.log('Update Ruleset Action --- ');
            const ruleset = action.payload[0];
            ruleset['jsonLogic'] = (ruleset['jsonLogic'] && ruleset['jsonLogic'].length > 0) ? JSON.parse(ruleset['jsonLogic']): "";
            
            if (Array.isArray(ruleset['jsonLogic']) === false) {
                ruleset['jsonLogic'] = [];
            }
            state.nodes[ruleset.nodeId] = ruleset;
        },

        // Receive Update Ruleset
        receiveUpdateRulesetAction: (state, action) => {
            console.log('Receive Update Ruleset Action --- ');
        },

        // Publish Ruleset
        publishRulesetAction: (state, action) => {
            console.log('Publish Ruleset Action --- ');
        },

        // Receive Publish Ruleset
        receivePublishRulesetAction: (state, action) => {
            console.log('Receive Publish Ruleset Action --- ');
        },

        // Delete Ruleset
        deleteRulesetAction: (state, action) => {
            console.log('Delete Ruleset Action --- ');
        },

        // Receive Delete Ruleset
        receiveDeleteRulesetAction: (state, action) => {
            console.log('Receive Delete Ruleset Action --- ');
        }
    }
});

export const { 
    getRulesetsAction,
    getRulesetAction,
    addRulesetAction,
    updateRulesetAction,
    publishRulesetAction,
    deleteRulesetAction,
    receiveRulesetsAction,
    receiveRulesetAction,
    receiveAddRulesetAction,
    receiveUpdateRulesetAction,
    receivePublishRulesetAction,
    receiveDeleteRulesetAction
} = rulesetsSlice.actions;
export default rulesetsSlice.reducer;