import React, {FC} from "react";
import {Card} from "../Card/Card.component";
import {Col} from "lavaa";
import {QuestionnairesPathway} from "../Cards/QuestionnairesPathway/QuestionnairesPathway.com";
import {LifeStyle} from "../Cards/LifeStyle/LifeStyle.com";

interface IProps {
}

// Surveys Page
const SurveysPage: FC<IProps> = React.memo(() => {

    return (
        <Col paddingLeft="0.5rem" paddingRight="0.5rem">
            <Card title="Questionnaires Pathway">
                <QuestionnairesPathway/>
            </Card>
            <Card title="Lifestyle">
                <LifeStyle/>
            </Card>
        </Col>
    )
});

export {SurveysPage};
