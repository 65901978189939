import React, {FC} from 'react';
import {Col, Row, Text, Title} from 'lavaa';
import css from './style.module.scss';
import {useDemo} from "../../../../../Hooks/useDemo";

const LastUpdate: FC = React.memo(() => {

    const { activeClient } = useDemo();

    if ( !activeClient?.client || !activeClient?.client?.LastUpdateDate ) return null;


    const lastUpdateRaw = activeClient?.client?.LastUpdateDate;
    const lastUpdate = new Date(lastUpdateRaw);

    let month: string | number = lastUpdate.getMonth() + 1;
    if(month < 10) month = `0${month}`;

    let day: string | number = lastUpdate.getDate();
    if(day < 10) day = `0${day}`;

    const lastUpdateString = `${month}.${day}.${lastUpdate.getFullYear()}`;

    return (
        <Col className={css.Widget}>
            <Row className={css.Title}>
                <Title size='x4'>Last updates</Title>
            </Row>
            <Row className={css.Wrapper}>
                <Text size="x3">{lastUpdateString}</Text>
            </Row>
        </Col>
    )
});

export {LastUpdate};
