import React, {FC} from "react";
import {GuidelinesByDiagnoses} from "./byDiagnoses";
import {TreatmentByPriority} from "./byPriority";

interface IProps {
    activeByPriority: boolean,
    filter: string
}

// Treatment Page
const TreatmentPage: FC<IProps> = React.memo((props) => {
    let { activeByPriority, filter } = props;

    return (
        activeByPriority
            ? <TreatmentByPriority filter={filter}/>
            : <GuidelinesByDiagnoses filter={filter}/>
    )
});

export {TreatmentPage};
