import { FC, useContext } from 'react';
import { Row, iconsTypes, Button } from 'lavaa';
import { AppCtx } from '../../Context/App.context';

interface IProps { }

// Link To Desktop Version
const LinkToDesktopVersion: FC<IProps> = () => {

    let { isInIframe } = useContext(AppCtx);

    // Open New Window
    const onClick = () => {
        window.open(window.location.href, '_blank');
    };

    return isInIframe() && <Row alignitems="center" paddingRight="1rem">
        <Button onClick={onClick} style={{color: 'var(--alternative-primary-text-color)'}} icon={iconsTypes.fullScreen} iconSize="x2" type="secondary" variant="text" />
    </Row>
};

export { LinkToDesktopVersion };