import { useDispatch, useSelector } from 'react-redux';
import {
    addOpenQuestionnaireAnswersAction,
    addQuestionnaireAnswersAction,
    clearQuestionnaireAction, getOpenQuestionnaireAction,
    getQuestionnaireAction, getQuestionnaireStatisticsAction
} from "../Redux/Slices/Questionnaire/Questionnaire.slice";

export const useQuestionnaire = (): any => {

    const questionnaireData = useSelector((state: any) => state.questionnaire.questionnaire);
    const questionnaireStatistics = useSelector((state: any) => state.questionnaire.statistics);

    const dispatch = useDispatch();

    // Get Questionnaire
    const fetchQuestionnaire = (clientId: string, projectId: string = "") => {
        dispatch(getQuestionnaireAction([clientId, projectId]));
        dispatch(getQuestionnaireStatisticsAction([clientId, projectId]));
    };

    // Add Questionnaire Answers
    const addQuestionnaireAnswers = (clientId: string, projectId: string = "", answers: any) => {
        const preparedAnswers = prepareQuestionnaireAnswers(answers);
        dispatch(addQuestionnaireAnswersAction([clientId, projectId, preparedAnswers]));
    };

    // Clear Questionnaire
    const clearQuestionnaire = () => {
        dispatch(clearQuestionnaireAction([]));
    };

    function prepareQuestionnaireAnswers(answers: any): any {
        const answersArray: any = [];

        Object.entries(answers).forEach(([key, value]) => {
            answersArray.push({
                parameterID: Number(key),
                value: JSON.stringify({'Value':value})
            });
        });

        return answersArray;
    }

    // Get Open Questionnaire
    const fetchOpenQuestionnaire = (questionnaireKey: string) => {
        dispatch(getOpenQuestionnaireAction([questionnaireKey]));
    };

    // Add Open Questionnaire Answers
    const addOpenQuestionnaireAnswers = (questionnaireKey: string, answers: any) => {
        const preparedAnswers = prepareQuestionnaireAnswers(answers);
        dispatch(addOpenQuestionnaireAnswersAction([questionnaireKey, preparedAnswers]));
    };

    return {
        fetchQuestionnaire,
        addQuestionnaireAnswers,
        clearQuestionnaire,
        questionnaireData, questionnaireStatistics,
        fetchOpenQuestionnaire,
        addOpenQuestionnaireAnswers
    };
}