import React, { FC } from "react";
import {Col, Text} from "lavaa";
import css from "./DiagnosisCode.module.scss"

const CodesEmpty: FC = React.memo(() => {
  return (
      <Col paddingTop="0.5rem" paddingBottom="1rem" paddingLeft="1.25rem" paddingRight="1.25rem">
          <Text text="No diagnoses codes" size="x3" className={css.Empty}/>
      </Col>
  )
});

export { CodesEmpty };
