import React, {FC, useState} from "react";
import css from "../style.module.scss";
import {Row, Dropdown, DropdownMulti} from "lavaa";
import {QuestionnaireTypeDropdownOption} from "../type";
import {classNames} from "../../../../Tools";

interface IProps {
    onChange: (data: any) => void,
    value: any,
    options: QuestionnaireTypeDropdownOption[],
    isMultiple: boolean
}

// Questionnaire Field Dropdown
const QuestionnaireFieldDropdown: FC<IProps> = React.memo((props) => {
    const {onChange, value, options, isMultiple} = props;
    const [focus, setFocus] = useState<boolean>(false);

    let hasContent;
    if(isMultiple){
        hasContent = value && value.length > 0;
    }
    else{
        hasContent =  value !== undefined && value !== null;
    }

    const classes = classNames(
        css.Field,
        hasContent ? css._hasContent : "",
        focus ? css._focus : "",
    );

    function changeHandler(newValue: any) {
        onChange(newValue?.value);
    }


    if(isMultiple) return (
        <Row className={css.InputRow}>
            <DropdownMulti
                className={classes}
                chipsClassName={css.Chips}
                data={options}
                onSelect={onChange}
                initialSelected={value}
                showCountSelected={true}
                onFocus={() => setFocus(true)}
                onBlur={() => setFocus(false)}
                wrap="col"
                type="chips"
                size="middle"
            />
        </Row>
    )

    const selected = options.find((option) => option.value === value);

    return (
        <Row className={css.InputRow}>
            <Dropdown
                className={classes}
                data={options}
                onSelect={changeHandler}
                selected={selected}
                hasSearch={false}
                onFocus={() => setFocus(true)}
                onBlur={() => setFocus(false)}
                wrap="col"
                type="inline"
                size="large"
                hasDelete={true}
            />
        </Row>
    )
});

export {QuestionnaireFieldDropdown};
