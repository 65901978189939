import React, {FC} from "react";
import css from "../../../Survey.module.scss";
import {Col, Row, Text} from "lavaa";

interface IProps {
}

// Questionnaire Thanks Page
const QuestionnaireThanksPage: FC<IProps> = React.memo(() => {

    return (
        <Col alignitems="center" className={css.ThanksWrapper}>
            <Row paddingBottom="0.5rem">
                <Text size="x6" bold={true} className={css.Title}>Thank you! We received your response.</Text>
            </Row>
            <Text size="x3">Your form is successfully submitted</Text>
        </Col>
    )
});

export {QuestionnaireThanksPage};
