import {FC} from 'react';
import css from '../Table.module.scss';
import {Col, Text} from "lavaa";
import { Link } from 'react-router-dom';

interface IProps {
	name: string,
	id: string,
}

// NameCell
const NameCell: FC<IProps> = (props) => {

	const {name, id} = props;

	return(
		<Col alignitems="start" justifycontent="center" className={css.Cell}>
			{/* Id */}
			<Text
				size="x1"
				bold={true}
				maxLines={1}
				className={css.NameCellLink}
			>
				<Link to={`https://app.elationemr.com/patient/${id}`} target="_blank">{id}</Link>
			</Text>

			{/* Patient Full Name */}
			<Text
				size="x1"
				bold={true}
				ellipsis={true}
				maxLines={2}
				className={css.NameCellLink}
			>
				<Link to={`/patients/${id}`} target="_blank">{name}</Link>
			</Text>
		</Col>
	)
};

export {NameCell};