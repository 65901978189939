import { isObject } from "../../../Tools/IsObject";
import {receiveReportsAction} from "./Reports.slice";

export const receiveProjectStatisticsActionCallback = ( store: any, payload: any) => {
    if(payload?.resultCode !== 'OK') return;
    // console.log('payload.data.rows', payload.data.rows);
    
    const rows = payload.data.rows.map((row: any) => {
        const result: any = {};
        const rowKeys = Object.keys(row);
        rowKeys.map((key: any) => {
            if (
                    row[key] === '' || 
                    (isObject(row[key]) && Object.keys(row[key]).length === 0)
                ) {
                result[key] = null;
            } else {
                result[key] = row[key];
            }
        });

        return result;
    });
    
    payload.data.rows = rows;
    return payload;
}