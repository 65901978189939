export const reportLinks: any = {
	"DischargeLast48H": {
		link: 'Discharge48h',
		tab: 0,
	},
	"HighWorryScore": {
		link: 'HighWS',
		tab: 1,
	},
	"LongOverdue": {
		link: 'Overdue',
		tab: 2,
	},
	"NoAwvThisYear": {
		link: 'NoAwvThisYear',
		tab: 3,
	},
	"NoAwvThisYearLastYear": {
		link: 'NoAwvThisYearLastYear',
		tab: 4,
	},
}