import { FC } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { Container } from 'lavaa';
import { Header } from '../Layout/Header/Header.component';
import { useAccount } from '../../Hooks/UseAccount';
import { HelpCenterModal } from '../Custom/Modals/HelpCenterModal/HelpCenterModal.component';
import {authPages, publicPages} from "./pages.config";
import {HeaderPublic} from "../Layout/HeaderPublic/Header.component";

// Auth Provider
const AuthProvider: FC = () => {
    const { isAuth } = useAccount();
    const location: any = useLocation();

    function checkPathPermission(currentPath: string, pages: string[]){
        return pages.some((mask) => {
            if(mask === currentPath) return true;
            if(!mask.includes("*")) return false;

            const pathParts = currentPath.split("/");
            const maskParts = mask.split("/");

            if(maskParts.length != pathParts.length) return false;

            for(let i = 0; i < maskParts.length; i++){
                if(maskParts[i] === "*") continue;
                if(maskParts[i] !== pathParts[i]) return false;
            }

            return true;
        });

    }

    const currentPath = location.pathname;
    const isAuthPage = checkPathPermission(currentPath, authPages);
    const isPublicPage = checkPathPermission(currentPath, publicPages);

    if(!isAuth){
        if(!isAuthPage && !isPublicPage){
            return <Navigate to="/login" state={{ from: location }} replace />;
        }
    }

    if(isAuth){
        if(isAuthPage){
            let from = (location.state && location.state.hasOwnProperty('from')) ? location.state.from.pathname : "/";
            return <Navigate to={from} replace />;
        }
    }

    return(
        // App Container
        <Container>

            {
                isAuth && (
                    // Header
                    <Header />
                )
            }

            {
                isPublicPage && !isAuth && (
                    // Header
                    <HeaderPublic />
                )
            }

            {/* Pages */}
            <Outlet />

            {
                isAuth && <HelpCenterModal />
            }

        </Container>
    );
};

export { AuthProvider };