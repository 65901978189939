import React, {FC} from "react";
import {CardItem} from "../Card/Card";
import {MedicalHistory} from "../Cards/MedicalHistory/MedicalHistory.component";
import {LabResults} from "../Cards/LabResults/LabResults.component";
import {Medications} from "../Cards/Medications/Medications.component";
import {DiagnosisCode} from "../Cards/DiagnosisCode/DiagnosisCode.component";
import css from "../Card/Card.module.scss";

interface IProps {
}

// Health Card
const HealthCard: FC<IProps> = React.memo(() => {

    return (
        <div className={css.Grid}>
            <CardItem title="Medical History">
                <MedicalHistory/>
            </CardItem>
            <CardItem title="Lab Results" hasSearch={true} searchPlaceholder="Search by results">
                <LabResults/>
            </CardItem>
            <CardItem title="Medication">
                <Medications/>
            </CardItem>
            <CardItem title="Diagnoses Code" hasSearch={true} searchPlaceholder="Search By Code">
                <DiagnosisCode/>
            </CardItem>
        </div>
    )
});

export {HealthCard};
