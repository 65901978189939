import { Row, Cell, Icon, iconsTypes, Text } from 'lavaa';
import { useTheme } from '../../../Hooks/UseTheme';
import css from './ThemeSwitcher.module.scss';

// ThemeSwitcher
const ThemeSwitcher = () => {

    // Hooks
    const { theme, setTheme } = useTheme();

    return (
        <Row paddingRight='1.5rem' paddingLeft='1.5rem' paddingTop='1rem' justifycontent='space-between' className={css.ThemeSwitcher} data-theme={theme}>
            <Row grow='1' justifycontent="center" className={css.Panel} paddingTop='0.5rem' paddingBottom='0.5rem' paddingLeft='1.25rem' paddingRight='1.25rem' onClick={() => setTheme('light')}>
                <Cell paddingTop='0' paddingBottom='0' paddingLeft='0' paddingRight='0.75rem' className={css.Icon}>
                    <Icon name={iconsTypes.sun} size="x1"/>
                </Cell>
                <Text text='Light' size='x2' bold='true' className={css.Label}/>
            </Row>
            <Row grow='1' justifycontent="center" className={css.Panel} paddingTop='0.5rem' paddingBottom='0.5rem' paddingLeft='1.25rem' paddingRight='1.25rem' onClick={() => setTheme('dark')}>
                <Cell paddingTop='0' paddingBottom='0' paddingLeft='0' paddingRight='0.75rem' className={css.Icon}>
                    <Icon name={iconsTypes.moon} size="x1"/>
                </Cell>
                <Text text='Dark' size='x2' bold='true' className={css.Label}/>
            </Row>
        </Row>
    );
};

export { ThemeSwitcher };