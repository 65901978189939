import { FC, memo, useEffect, useState } from "react";
import { classNames } from "../../../../Tools";
import { Button, Modal, Row, Col, Input, iconsTypes, Icon, Text, Toggle } from "lavaa";
import DatePicker from "react-datepicker";
import css from './SnoozeRenderer.module.scss';

type Props = {
    data: any;
    colDef: any;
    value: any;
    handleAction: (actionType: string, data: any) => void;
    eParentOfValue: any;
    eGridCell: any;
};

// Reasons
const reasons = [
    'Admission',
    'Hospice',
    'SNF',
    'Therapy Period',
    'CWS by Physician',
    'Patient\'s request'
];

// SnoozeRenderer Component New
export const SnoozeRendererNew: FC<Props> = memo((props: Props) => {
    
    // Props
    const { data, colDef, handleAction, eParentOfValue, eGridCell } = props;

    const startDate = !!data.StartDateForSnooze ? new Date(data.StartDateForSnooze) : null;
    const endDate = !!data.FinishDateForSnooze ? new Date(data.FinishDateForSnooze) : null;
    const now = new Date();    
    const snooze = startDate !== null && endDate !== null ? now >= startDate && now <= endDate : false;
    
    const znoozeBtnProp = {
        ...props,
        colDef: {
            ...colDef,
            rendererOptions: {
                ...colDef.rendererOptions,
                text: snooze ? 'Activate' : 'Snooze'
            }
        }
    };
    
    // States
    const [snoozeConfirm, setSnoozeConfirmation] = useState<boolean>(false);
    const [clientId, setSnoozeClientId] = useState<string | null>(null);
    const [snoozeDays, setSnoozeDays] = useState('');
    const [from, setFrom] = useState<number>();
    const [to, setTo] = useState<number>();
    const [reason, setReason] = useState('');
    const [isFormFalid, setFormFalid] = useState(false);

    // Handle Snooze Patient
    const handleSnoozePatient = (id: string) => {
        
        // Show Snooze Confirmation
        setSnoozeConfirmation(true);

        // Set Snooze Client Id
        setSnoozeClientId(id);
    };

    // Handle Unsnooze Client
    const handleUnsnoozePatient = (id: string) => {
        handleAction('unSnoozePatient', {clientId: id});
    };

    // Handle Snooze Confirmation Close
    const handleSnoozeConfirmationClose = () => {

        // Hide Snooze Confirmation
        setSnoozeConfirmation(false);

        // Reset Snooze Patient Id
        setSnoozeClientId(null);

        // Reset Snooze Date
        setSnoozeDays('');

        setFrom(undefined);
        setTo(undefined);
        setReason('');
    };

    // Handle Snooze Confirmation Apply
    const handleSnoozeConfirmationApply = () => {

        // Hide Snooze Confirmation
        setSnoozeConfirmation(false);
        setSnoozeDays('');

        // Snooze Client
        // setLocalRows(row => row.filter(row => row[primaryKey] !== snoozePatientId));
        setSnoozeClientId(null);
        // setSnoozeDate(null);

        handleAction('snoozePatient', {clientId, from, to, reason});
        setFrom(undefined);
        setTo(undefined);
        setReason('');
    };

    // Handle From Date Change
    const handleFromDateChange = (date: any) => {
        setFrom(date.getTime());
    };

    // Handle To Date Change
    const handleToDateChange = (date: any) => {
        setTo(date.getTime());
    };

    // Handle Date Range
    const handleDateRangeChange = (days: number) => {
        const from = new Date();
        const to = new Date(from.getTime() + days * 24 * 60 * 60 * 1000);

        // Update States
        setFrom(from.getTime());
        setTo(to.getTime());
    };

    // Handle Reason Change
    const handleReasonChange = (value: any) => {
        setReason(value);
    };

    // Handle Mouse Over
    const handleMouseOver = (e: any) => {
        const wrapperEl = eGridCell.querySelector('.ag-cell-wrapper');
        const valueEl = eGridCell.querySelector('.ag-cell-value');
        
        wrapperEl.style.overflow = 'visible';
        valueEl.style.overflow = 'visible';
    };

    // Handle Mouse Leave
    const handleMouseLeave = (e: any) => {
        const wrapperEl = eGridCell.querySelector('.ag-cell-wrapper');
        const valueEl = eGridCell.querySelector('.ag-cell-value');
        
        wrapperEl.style.overflow = 'hidden';
        valueEl.style.overflow = 'hidden';
    };

    // Validate
    useEffect(() => {
        if(from && to && reason){
            setFormFalid(true);
        } else {
            setFormFalid(false);
        }
    }, [from, to, reason]);
    
    return (
        <>

            {/* Snooze Button */}
            <Row alignitems="center">
                <Row alignitems="center" justifycontent="center" grow="1">
                    <Toggle
                        // label="Snooze"
                        // labelPosition="right"
                        size="middle"
                        onToggle={() => snooze ? handleUnsnoozePatient(data.ClientID) : handleSnoozePatient(data.ClientID)}
                        active={snooze ? true : false}
                    />
                    <Row paddingLeft="0.5rem">
                        <Text size="x2">Snooze</Text>
                    </Row>
                </Row>

                {/* Reason Tooltip */}
                {/* {
                    snooze && data && data.SnoozeReason ? <Row paddingLeft="0.5rem" alignitems="center">
                        <span className={css.ReasonTooltip} data-tooltip={data.SnoozeReason} onMouseOver={handleMouseOver} onMouseLeave={handleMouseLeave}>
                            <Icon name={iconsTypes.infoOutlined} />
                        </span>
                    </Row> : null
                } */}
                
            </Row>

            {/* Snooze Modal */}
            <Modal 
                active={snoozeConfirm} 
                title="Snooze Mode" 
                width="468px" 
                height="710px" 
                onClose={handleSnoozeConfirmationClose} 
                onCancel={handleSnoozeConfirmationClose} 
                onOk={handleSnoozeConfirmationApply}
                okText="Submit"
                footerStyle={{borderTop: '1px solid var(--alternative-secondary-bg-color)'}}
                isFormValid={isFormFalid}
                usePortal={true}>
                    <Col className={css.SnoozeRenderer}>

                        {/* Period Block */}
                        <Col paddingBottom="1.75rem">

                            {/* Block Title */}
                            <Row className={css.BlockTitle} paddingBottom="0.75rem">Snooze Period</Row>

                            {/* Period Buttons */}
                            <Row grow="1">

                                {/* One Day */}
                                <Row>
                                    <Button className={css.PeriodButton} type={snoozeDays === '1' ? 'primary' : 'secondary'} onClick={() => handleDateRangeChange(1)}>One Day</Button>
                                </Row>

                                {/* One Week */}
                                <Row paddingLeft="0.5rem">
                                    <Button className={css.PeriodButton} type={snoozeDays === '7' ? 'primary' : 'secondary'} onClick={() => handleDateRangeChange(7)}>One Week</Button>
                                </Row>

                                {/* One Month */}
                                <Row paddingLeft="0.5rem">
                                    <Button className={css.PeriodButton} type={snoozeDays === '30' ? 'primary' : 'secondary'} onClick={() => handleDateRangeChange(30)}>One Month</Button>
                                </Row>
                            </Row>
                        </Col>

                        {/* Date Range Block */}
                        <Row paddingBottom="1.75rem">

                            {/* From */}
                            <Col>

                                {/* Block Title */}
                                <Row className={css.BlockTitle} paddingBottom="0.75rem">From</Row>

                                {/* Date Picker */}
                                <Row>
                                    <DatePicker dateFormat="MM/dd/yyyy" selected={from ? new Date(from) : undefined} onChange={handleFromDateChange} placeholderText="MM/DD/YYYY" className={css.DatePicker} />
                                </Row>
                            </Col>

                            {/* To */}
                            <Col paddingLeft="1rem">

                                {/* Block Title */}
                                <Row className={css.BlockTitle} paddingBottom="0.75rem">To</Row>

                                {/* Date Picker */}
                                <Row>
                                    <DatePicker dateFormat="MM/dd/yyyy" selected={to ? new Date(to) : undefined} onChange={handleToDateChange} placeholderText="MM/DD/YYYY" className={css.DatePicker} />
                                </Row>
                            </Col>
                        </Row>

                        {/* Reason Block */}
                        <Col>
                        
                            {/* Block Title */}
                            <Row className={css.BlockTitle} paddingBottom="0.75rem">Snooze Reason</Row>

                            {/* Reasons */}
                            <Col>

                                {
                                    reasons.map((item: string) => <Row paddingBottom="1rem" key={item}>
                                        <Button className={classNames(css.ReasonButton, item.toLowerCase() === reason.toLowerCase() ? css.Active : '')} onClick={() => setReason(item)}>{item}</Button>
                                    </Row>)
                                }

                                {/* Another Reason */}
                                <Row>
                                    <Input type="text" placeholder="Type your reason" onChange={handleReasonChange} value={reason} />
                                </Row>
                            </Col>
                        </Col>

                    </Col>
            </Modal>
        </>
    )
});