import React, {FC, useEffect, useState} from "react";
import {QuestionnaireFormPage} from "./views/form";
import {QuestionnaireSubmitPage} from "./views/submit";
import {useQuestionnaire} from "../../../../Hooks/UseQuestionnaire";
import {useParams} from "react-router-dom";
import {useAccount} from "../../../../Hooks/UseAccount";

enum StepType {
    Form,
    Submit,
    Waiting,
    ImmediatelyWaiting
}

interface IProps {}

// Questionnaire Page
const QuestionnairePage: FC<IProps> = React.memo(() => {

    const [inputs, setInputs] = useState<any>({});
    const [filledInputs, setFilledInputs] = useState<{[k: string]: any}>({});
    const [step, setStep] = useState<StepType>(StepType.Form);

    const {patientId} = useParams();
    const {HMSProjectId} = useAccount();
    const {addQuestionnaireAnswers, questionnaireData} = useQuestionnaire();

    useEffect(() => {
        setInputs({});
        setStep(StepType.Form);
        setFilledInputs({});
    }, [questionnaireData]);

    function changeHandler(field: string, value: any){
        setInputs((inputs: any) => {
            return Object.assign({}, inputs, {
                [field]: value
            });
        });
    }

    function onSubmitForm(filledInputs: any, confirmImmediately: boolean) {
        setFilledInputs(filledInputs);

        if(confirmImmediately) {
            confirmHandler(filledInputs);
        } else {
            setStep(StepType.Submit);
        }
    }

    function onSubmitReturn(){
        setStep(StepType.Form);
    }

    function confirmHandler(filledInputsLocal: any = undefined){
        addQuestionnaireAnswers(patientId, HMSProjectId, filledInputsLocal || filledInputs);

        // If filledInputsLocal -> it's Immediately submit
        if(filledInputsLocal){
            setStep(StepType.ImmediatelyWaiting);
        }
        else{
            setStep(StepType.Waiting);
        }
    }

    switch (step) {
        case StepType.Form:
        case StepType.ImmediatelyWaiting:
            return(
                <QuestionnaireFormPage
                    onSubmit={onSubmitForm}
                    inputs={inputs}
                    onChange={changeHandler}
                    loading={step === StepType.ImmediatelyWaiting}
                />
            );
        case StepType.Submit:
        case StepType.Waiting:
            return(
                <QuestionnaireSubmitPage
                    inputs={filledInputs}
                    onReturn={onSubmitReturn}
                    onConfirm={confirmHandler}
                    loading={step === StepType.Waiting}
                />
            );
    }

    return null;

});

export {QuestionnairePage};
