import React, {FC} from "react";
import css from "../style.module.scss";
import {Row, InputBoolean} from "lavaa";


interface IProps {
    onChange: (data: any) => void,
    value: boolean
}

// Questionnaire Field Boolean
const QuestionnaireFieldBoolean: FC<IProps> = React.memo((props) => {
    const {onChange, value} = props;

    return (
        <Row className={css.InputRow}>
            <InputBoolean onChange={onChange} value={value}/>
        </Row>
    )
});

export {QuestionnaireFieldBoolean};
