import { FC } from 'react';
import { Row } from 'lavaa';
import css from './ReportLabel.module.scss';
import {classNames} from "../../../Tools";

interface IProps {
  textColor: string,
  bgColor: string,
  children: any,
  onClick?: (e: Event) => void,
  type?: 'default' | 'button'
}

// Report Label
const ReportLabel: FC<IProps> = (props) => {

  const { textColor = '#ffffff', bgColor = '#000000', children, onClick = () => {}, type = 'default'} = props;

    return <Row onClick={ onClick } grow="0" className={ classNames(css.ReportLabel, type === 'button' ? css.btn : '' )} style={{color: textColor, background: bgColor}} paddingTop="0.5rem" paddingLeft="1rem" paddingRight="1rem" paddingBottom="0.5rem">
        { children }
  </Row>
};

export { ReportLabel };
