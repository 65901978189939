import React, {FC, useContext, useEffect, useRef, useState} from "react";
import {Row, Title, iconsTypes, Col, DropdownMulti, BarChart, Icon} from 'lavaa';
import css from "../../Dashboard.module.scss";
import {useModulesStat} from "./utils";
import {EmptyChart} from "../emptyChart";
import {useDemo} from "../../../../../Hooks/useDemo";
import {IModuleType} from "../../../../../Redux/Slices/Demo/Demo.data";
import {useLocalStorage} from "../../../../../Hooks/UseLocalStorage";
import {AppCtx} from "../../../../../Context/App.context";
import {useAccount} from "../../../../../Hooks/UseAccount";
import {classNames} from "../../../../../Tools";

type Props = {
    maxModules: number,
    moduleId?: string,
    dragHandleProps?: any,
    isDragging?: boolean
}

export type ModuleFilterType = {
    id: string,
    value: string
}

// Modules Statistics
const ModulesStatChart: FC<Props> = ({
    maxModules ,
    moduleId,
    dragHandleProps,
    isDragging = false
}) => {
    let {deviceType} = useContext(AppCtx);
    const isMobile = deviceType === 'mobile';

    const { modules } = useDemo();
    const {HMSProjectId} = useAccount();

    const [filterData, setFilterData] = useState<ModuleFilterType[]>(() => generateFilterData());
    const [selectedModules, setSelectedModules ] = useLocalStorage<ModuleFilterType[]>("dashboard-modules-statistics", () => {
        return filterData.slice(0, maxModules - 1);
    });

    const initRef = useRef<boolean>(false);

    // Check if project changed
    const initProjectRef = useRef<boolean>(false);
    const projectChangedRef = useRef<boolean>(false);

    // Ref filter data
    const filterDataRef = useRef<ModuleFilterType[]>(filterData);

    const chartModules = prepareChartModules();
    const [dataFormatted, labels] = useModulesStat(chartModules, (moduleId != undefined && moduleId !== 'all'));
    const dropdownResetRef = useRef<number>(0);
    const prevModuleId = useRef<string | undefined>(moduleId);

    useEffect(() => {
        if(initRef.current){
            const localFilterData = generateFilterData();
            filterDataRef.current = localFilterData;
            setFilterData(localFilterData);
            setSelectedModules(generateSelectedModules);
        }
        initRef.current = true;
        prevModuleId.current = moduleId;
        validateSelectedModules();
    }, [modules, moduleId]);

    useEffect(() => {
        // Skip first HMSProjectId init
        if(!initProjectRef.current){
            if(HMSProjectId != ''){
                initProjectRef.current = true;
            }
            return;
        }

        projectChangedRef.current = true;
    }, [HMSProjectId]);

    // Check if selected modules are valid
    function validateSelectedModules(){
        let localSelectedModules: ModuleFilterType[] = [];

        // Check if module exists
        if(!projectChangedRef.current) {
            selectedModules.forEach((module: ModuleFilterType) => {
                // Check if module exists
                if (modules.find((m: IModuleType) => m.ModuleUID === module.id)) {
                    // Add module to selected modules
                    localSelectedModules.push(module);
                }
            });
        }
        else { // Project changed - reset selected modules
            localSelectedModules = filterDataRef.current.slice(0, maxModules - 1);
            projectChangedRef.current = false;
        }

        // Update selected modules
        setSelectedModules(localSelectedModules);
    }

    function generateFilterData() {
        const localFilterData: ModuleFilterType[] = [];

        modules.forEach((module: IModuleType) => {
            if(module.ModuleUID === moduleId) return;

            const local = {
                id: module.ModuleUID,
                value: module.ModuleName
            }

            localFilterData.push(local);
        });

        return localFilterData;
    }

    function generateSelectedModules(selectedModulesPrev: ModuleFilterType[]): ModuleFilterType[]{
        dropdownResetRef.current += 1;
        if(!moduleId) {
            // Check if prev module is not selected or the same as current
            if(prevModuleId.current === 'all' || prevModuleId.current === undefined || prevModuleId.current === moduleId) return selectedModulesPrev;

            // Find prev module
            const prevModule = findModuleById(prevModuleId.current);

            if(!prevModule) return selectedModulesPrev;

            // Add prev module to selected modules
            const newSelectedModules = [...selectedModulesPrev];
            newSelectedModules.unshift(prevModule!);

            return newSelectedModules.slice(0, maxModules - 1);
        }

        const modulesLocal = selectedModulesPrev.filter((module) => module.id != moduleId).slice(0, maxModules - 1);

        // Check if prev module is not selected or the same as current
        if(prevModuleId.current === 'all' || prevModuleId.current === undefined || prevModuleId.current === moduleId) return modulesLocal;

        // Find prev module
        const prevModule = findModuleById(prevModuleId.current);

        if(!prevModule) return modulesLocal;

        // Add prev module to selected modules
        const newSelectedModules = [...modulesLocal];
        newSelectedModules.unshift(prevModule!);

        return newSelectedModules.slice(0, maxModules - 1);
    }

    function findModuleById(id: string): ModuleFilterType | undefined {
        const module = modules.find((module: IModuleType) => module.ModuleUID === id);
        if(!module) return undefined;

        return {
            id: module.ModuleUID,
            value: module.ModuleName
        }
    }

    function prepareChartModules(): ModuleFilterType[] {
        if(!moduleId || moduleId === 'all') return selectedModules;

        // Find module
        const module = modules.find((module: IModuleType) => module.ModuleUID === moduleId);
        if(!module) return selectedModules;

        const moduleData = {
            id: module.ModuleUID,
            value: module.ModuleName
        }
        const otherModules = selectedModules.filter((data: ModuleFilterType) => data.id !== moduleId).slice(0, maxModules - 1);

        return [
            moduleData,
            ...otherModules
        ];
    }

    function selectModulesHandler(data: any) {
        setSelectedModules(data);
    }

    const yAxisWidth = isMobile ? 35 : 50;
    const hasChartMargin = !isMobile;

    const dropdownMaxModules = moduleId ? maxModules - 1 : maxModules;

    return (
        <Col className={classNames(css.Widget, css._chart)}>
            {/* TODO - hided temporary */}
            {/*<Button icon={iconsTypes.trash} iconSize="x2" type="gray" onClick={() => {}} variant="text" className={css.DeleteIcon}/>*/}

            <Row className={css.WidgetHeader} alignitems="center" justifycontent="space-between">
                <Row className={classNames(css.HeaderMoveContainer, isDragging && css._dragging)} {...dragHandleProps} alignitems="center">
                    {
                        dragHandleProps && (
                            <Col className={css.WidgetMoveIcon}>
                                <Icon name={iconsTypes.move} size="x1"/>
                            </Col>
                        )
                    }
                    <Row className={css.WidgetTitle}>
                        <Title size="x3" maxLines={2} className={css.WidgetTitle}>Modules Statistics</Title>
                    </Row>
                </Row>

                <Row>
                    <DropdownMulti
                        icon={iconsTypes.flowPlus}
                        placeholder="Add module"
                        data={filterData}
                        hasSearch={true}
                        type={"button-simplified"}
                        size={"tiny"}
                        maxCount={dropdownMaxModules}
                        minCount={2}
                        onSelect={selectModulesHandler}
                        initialSelected={selectedModules}
                        reset={dropdownResetRef.current}
                    />
                </Row>
            </Row>
            <Row className={css.Chart} grow="1">
                {
                    dataFormatted.length === 0 ? (
                        <EmptyChart type={chartModules.length === 0 ? "moduleNotSelected" : "noData"}/>
                    ) : (
                        <BarChart
                            data={dataFormatted}
                            stacked
                            type="packed"
                            showLegend={false}
                            labels={labels}
                            yLabel="Amount of patients, unit"
                            xLabel="High-Risk Modules"
                            yAxisWidth={yAxisWidth}
                            showTooltipLabel={false}
                            hasChartMargin={hasChartMargin}
                        />
                    )
                }
            </Row>
        </Col>
    );

};

export {ModulesStatChart};