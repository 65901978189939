import React, {FC, useCallback, useContext, useEffect, useRef, useState} from "react";
import {Row, Title, Col, iconsTypes, TableText, Icon, ButtonTooltip} from 'lavaa';
import css from "../../Dashboard.module.scss";
import cssTable from "../../Components/Table/Table.module.scss";
import {EmptyChart} from "../emptyChart";
import {Table} from "../../Components/Table/Table.component";
import type {SortColumn} from "../../Components/Table/Table.component";
import {classNames} from "../../../../../Tools";
import {NameCell} from "../../Components/Table/Cells/NameCell.com";
import {CallCell} from "../../Components/Table/Cells/CallCell.com";
import {useReports} from "../../../../../Hooks/UseReports";
import {useAccount} from "../../../../../Hooks/UseAccount";
import {ReportFilterType} from "../../../../Reports/Reports.types";
import {DashboardReportNames, reportHeaders, reportTitles} from "./DashboardReport.data";
import {useNavigate} from "react-router-dom";
import {reportLinks} from "../../../../Reports/DailyHuddle/DailyHuddle.data";
import {ModalsCtx} from "../../../../../Context/Modals.context";
import {DataGridHandle} from "react-data-grid";
import {useMixPanel} from "../../../../../Hooks/UseMixPanel";

type Props = {
    reportName: DashboardReportNames,
    initialSorting?: any,
    dragHandleProps?: any,
    isDragging?: boolean,
    isSorting?: boolean,
    reportLink?: string
}

// Dashboard report
const DashboardReport: FC<Props> = ({
    reportName,
    initialSorting = {},
    dragHandleProps,
    isDragging = false,
    isSorting = false,
    reportLink
}) => {
    const { setReportClientInfoModalActive, setReportClientInfoId, deviceType } = useContext(ModalsCtx);
    const { getDashboardReport, requestReportsWithPager } = useReports();
    const { HMSProjectId } = useAccount();
    const navigate = useNavigate();
    const { track } = useMixPanel();
    const reportData = getDashboardReport(reportName);
    const reportRef = useRef<DataGridHandle>(null);
    const isMobile = deviceType === 'mobile';

    // Sort
    const [sortColumns, setSortColumns] = useState<SortColumn[]>([]);

    // Filter
    const [filter, setFilter] = useState<ReportFilterType>({
        page: 1,
        pageSize: 3,
        search: [],
        sort: {
            sortedByField: '',
            isAscending: true,
            sortingType: 0, // 0 - any; 1 - string; 2 - number; 999 - other;
            ...initialSorting
        }
    });

    // Watch HMSProjectId
    useEffect(() => {
        requestReportsWithPager("dashboard", HMSProjectId, reportName, filter.sort, filter.search, filter.page, filter.pageSize);
    }, [HMSProjectId, reportName, filter]);

    // Scroll to top & left when dragging (fix for react-data-grid on dragging)
    useEffect(() => {
        if(!reportRef.current) return;
        if(!isDragging && !isSorting) return;

        const element = reportRef.current.element;
        element?.scrollTo(0, 0)
    }, [reportRef, isDragging, isSorting]);


    const onSortColumnsChange = useCallback((sortColumns: SortColumn[]) => {
        const sortType = sortColumns.slice(-1);
        setSortColumns(sortType);

        let newFilter = Object.assign({}, filter, {
            sort: {
                sortedByField: '',
                isAscending: true,
                sortingType: 0
            }
        });

        if(sortType.length > 0) {
            let columnKey = sortType[0].columnKey;

            if(columnKey === "Patient") columnKey = "PatientIdFullName";

            newFilter.sort.sortedByField = columnKey;
            newFilter.sort.isAscending = sortType[0].direction == "ASC";

            track('Sorting (Dashboard)', {report: reportName, action: 'sorting', columnName: columnKey, page: "dashboard"});
        }

        setFilter(newFilter);
    }, []);

    function goToReport() {
        let link = reportLink || '';
        if(!reportLink) {
            const reportKey = Object.keys(DashboardReportNames)[Object.values(DashboardReportNames).indexOf(reportName)];
            link = `/reports/dailyHuddle/${reportLinks[reportKey]?.link}`;
        }

        track('View Report (Dashboard)', {reportName: reportName});

        if(link === '') return;
        navigate(link)
    }

    function openClientInfo(clientId: string){
        setReportClientInfoId(clientId);
        setReportClientInfoModalActive(true);
        track('Contact (Dashboard)', {reportName: reportName, page: 'dashboard', component: "report"});
    }

    // Pre-calculate max column width
    const maxWidth = isMobile ? 400 : 550;
    const minWidth = isMobile ? 150 : 20;

    const columns = reportHeaders[reportName]?.map((headerKey: string) => {
        if(!reportData) return;

        const column: any = {
            id: headerKey,
            key: headerKey,
            headerCellClass: cssTable.HeaderCell,
            minWidth: minWidth,
            maxWidth: maxWidth,
            sortable: true
        };

        // Add class for Cell if it's Patient Name
        if(headerKey === "Patient"){
            column["name"] = "Patient";
            column["cellClass"] = cssTable.CellName;

            return column;
        }

        // Add class for Cell if it's Call
        if(headerKey === "Call"){
            column["name"] = "Contact";
            column["sortable"] = false;

            return column;
        }

        const header = reportData.headers.find((header: any) => header.columnName === headerKey);
        if(!header) return;

        column["name"] = header.displayName || header.columnName;

        return column;
    }).filter((column: any) => column != undefined); // Filter not recognized headers

    const rows = !reportData ? [] : reportData.rows.map((rowData: any) => {
        const row: any = {}
        
        columns?.forEach((column: any) => {
            if(column.key === "Patient") {
                row[column.key] = <NameCell name={rowData["FullName"]} id={rowData["PatientId"]}/>
                return;
            }
            if(column.key === "Call") {
                row[column.key] = <CallCell id={rowData["ClientID"]} onClick={openClientInfo}/>
                return;
            }

           row[column.key] = <TableText style="slim" centered={true} maxLines={2}>{rowData[column.key]}</TableText>
        });

        return row;
    });

    return (
        <Col className={classNames(css.Widget, css._compact)}>
            <ButtonTooltip
                icon={iconsTypes.instance}
                iconSize="18"
                type="secondary"
                onClick={goToReport}
                variant="text"
                className={css.ActionIcon}
                wrapperClassName={css.ActionIconWrapper}
                tooltip={{
                    tooltipText: 'View Report',
                    positionH: 'right',
                    positionV: 'bottom',
                }}
            />

            <Row className={css.WidgetHeader} alignitems="center" justifycontent="space-between">
                <Row className={classNames(css.HeaderMoveContainer, isDragging && css._dragging)} {...dragHandleProps} alignitems="center">
                    {
                        dragHandleProps && (
                            <Col className={css.WidgetMoveIcon}>
                                <Icon name={iconsTypes.move} size="x1"/>
                            </Col>
                        )
                    }
                    <Row className={css.WidgetTitle}>
                        <Title size="x3" maxLines={2} className={css.WidgetTitle}>{reportTitles[reportName]}</Title>
                    </Row>
                </Row>
            </Row>
            <Row className={css.Chart} grow="1">
                {
                    rows.length === 0 ? (
                        <EmptyChart type="noData" text={reportName === DashboardReportNames.DischargeLast48H ? 'No Relevant Data' : 'No data available'}/>
                    ) : (
                        <Row className={css.Table}>
                            <Table
                                columns={columns}
                                rows={rows}
                                sortColumns={sortColumns}
                                onSortColumnsChange={onSortColumnsChange}
                                innerRef={reportRef}
                            />
                        </Row>
                    )
                }
            </Row>
        </Col>
    );

};

export {DashboardReport};