import {
    Row,
    Col,
    iconsTypes,
    Text,
    Input,
    ScrollContainer,
} from 'lavaa';
import { useAccount } from '../../Hooks/UseAccount';
import React, {FC, useEffect, useRef, useState} from 'react';
import css from './style.module.scss';
import {ClinicList} from "./List";
import {ProjectCompanyListType} from "./data";
import {IUserProjectType} from "../../Redux/Slices/Account/Account.data";

interface IProps {
    closeHandler: () => void,
    onSelectProject: () => void
}

// Menu Choose Project Dropdown
const MenuChooseProjectDropdown: FC<IProps> = (props) => {
    const { closeHandler } = props;
    const { projects } = useAccount();

    const [search, setSearch] = useState<string>('');
    const [projectsList, setProjectsList] = useState<ProjectCompanyListType>({});
    const [maxHeight, setMaxHeight] = useState<number>(200);

    const scrollRef = useRef<HTMLDivElement>();

    useEffect(() => {
        prepareProjectsList();
    }, [projects]);

    useEffect(() => {
        window.addEventListener('resize', checkScreenSize);

        checkScreenSize();

        return () => {
            window.removeEventListener('resize', checkScreenSize);
        }
    }, []);

    function checkScreenSize() {
        const wh = window.innerHeight;
        const toTop = scrollRef.current?.getBoundingClientRect().top ?? 0;
        const toBottom = wh - toTop;

        const height = Math.min(toBottom - 20, 700);
        setMaxHeight(height)
    }

    function prepareProjectsList() {
        const list: ProjectCompanyListType = {};
        projects.forEach((project: IUserProjectType) => {
            const companyName = project.Company || "No Company";
            if(!list.hasOwnProperty(companyName)){
                list[companyName] = [];
            }
            list[companyName].push(project);
        });
        setProjectsList(list);
    }


    return (
        <>
            <div className={css.DropdownLayer} onClick={closeHandler}/>
            <Col paddingBottom='1rem' className={css.Dropdown}>
                    <Col paddingLeft='1rem' paddingTop='2rem' paddingRight='1rem' paddingBottom='1rem' >
                        <Row paddingLeft="1rem" paddingRight="1rem">
                            <Text text='IDE Projects' size='x4' bold='true' className={css.Title}/>
                        </Row>
                        <Row paddingTop="1.5rem">
                            <Input
                                onChange={setSearch}
                                type="text"
                                variant="inline"
                                size="small"
                                placeholder="Search by Clinic/Project Name"
                                icon={iconsTypes.search}
                            />
                        </Row>
                    </Col>
                    <Col>
                        <ScrollContainer maxHeight={maxHeight} addPadding={false} scrollRef={scrollRef}>
                            {
                                projectsList && Object.entries(projectsList).map(([companyName, projectsLocal]) => {
                                    return (
                                        <ClinicList
                                            company={companyName}
                                            projects={projectsLocal}
                                            search={search}
                                            key={companyName}
                                            onSelectProject={props.onSelectProject}
                                        />
                                    );
                                })
                            }
                        </ScrollContainer>
                    </Col>
            </Col>
        </>
    );
};

export { MenuChooseProjectDropdown };