import React, {FC} from "react";
import {Card} from "../Card/Card.component";
import {PersonalInfo} from "../Cards/PersonalInfo/PersonalInfo.component";
import {Col} from "lavaa";
import {Vitals} from "../Cards/Vitals";
import {ClinicResearch} from "../Cards/ClinicResearch";

interface IProps {
}

// Personal Info Page
const PersonalInfoPage: FC<IProps> = React.memo(() => {

    return (
        <Col paddingLeft="0.5rem" paddingRight="0.5rem">
            <Card title="Personal Info">
                <PersonalInfo/>
            </Card>
            <Card title="Vitals">
                <Vitals/>
            </Card>
            <Card title="Clinic Research">
                <ClinicResearch/>
            </Card>
        </Col>
    )
});

export {PersonalInfoPage};
