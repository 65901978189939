import { history } from '../../../Constants/History';
import {
    addLastMessageInfoAction,
    addNodeStateAction,
    addProjectDataOutputAction,
    addProjectReportLinkAction, stopProjectRunAction, updateProjectRunStatusAction
} from "./ProjectTemporary.slice";
import { IOutputType } from "../../../Components/FlowOutput/FlowOutput.types";

export const receiveProjectAddCallback = ( store: any, payload: any) => {
    const uid = payload.data.uid;

    let url = history.location.pathname;
    url = (url.slice(-1) === '/') ? url.slice(0, -1) : url;
    const path = url + '/' + uid;
    history.push(path);
}

export const receiveProjectRunCallback = ( store: any, payload: any) => {
    const data = payload.data;
    const message = data.message, step = data.flowStep,
      finishedNodes = data.finishedNodes ?? [], nodeInProgress = data.nodeInProgressUID ?? '';

    let type: IOutputType = 'default';
    store.dispatch(updateProjectRunStatusAction([]));

    let link = null
    if(step === 3){
        link = data.reportUrl;
        type = 'success';
    }

    if(step === 4){ // Fault
        // TODO
        type = 'error';
        store.dispatch(addProjectDataOutputAction([data.errorMessage, type]));
    }

    // Remove repeat messages
    const lastMessage = store.getState().projectTemporary.lastMessage;
    if(lastMessage.message !== message || lastMessage.nodeId !== nodeInProgress) {
        store.dispatch(addProjectDataOutputAction([message, type]));
        store.dispatch(addNodeStateAction([nodeInProgress, finishedNodes]));
    }
    store.dispatch(addLastMessageInfoAction([message, nodeInProgress]));

    if(link){
        store.dispatch(addProjectReportLinkAction([link]));
        const mess = 'Download project report';
        store.dispatch(addProjectDataOutputAction([mess, 'default', link]));
    }

    if(step === 3 || step === 4){
        store.dispatch(stopProjectRunAction([]));
    }
}