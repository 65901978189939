import { FC, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { history } from '../../Constants/History';
import { useAccount } from '../../Hooks/UseAccount';
import { useAuth } from '../../Hooks/UseAuth';
import { useLocalStorage } from '../../Hooks/UseLocalStorage';
import { usePrev } from '../../Hooks/UsePrev';

// Extension
const Extension: FC<any> = () => {

    // hooks
    const location: any = useLocation();
    const { accessToken, refreshToken, isAuth } = useAuth();
    const { logoutAccount } = useAccount();
    const prevIsAuth = usePrev(isAuth);

    // States
    const [, setRefreshToken, refreshTokenActual] = useLocalStorage('refresh_token', '');
    const [, setAccessToken, accessTokenActual] = useLocalStorage('access_token', '');

    // Is Extension Page
    const isExtensionPage = location.pathname.includes('/extension/');

    // Is in iframe
    const isInIframe = () => {
        return window !== window.parent;
    }

    // Watch tokens and auth
    useEffect(() => {
        
        if (isExtensionPage && isInIframe() === false) return;

        // Send Refresh Token & Access Token to Extension
        if (isAuth && accessToken && refreshToken) {
            window.parent.postMessage(JSON.stringify({type: 'iframeTokensReceive', accessToken, refreshToken}), '*');
        }

        // Logout
        if (isAuth === false && prevIsAuth === true) {
            window.parent.postMessage(JSON.stringify({type: 'iframeLogout'}), '*');
        }

    }, [accessToken, refreshToken, isAuth]);

    // Did mount
    useEffect(() => {

        // Watch Post Message
        window.addEventListener('message', (e: MessageEvent) => {
            const { origin, source, data } = e;

            try{
                const message = JSON.parse(data);

                // Message type is extensionTokensReceive
                if (message.type === 'extensionTokensReceive') {
                    const { refresh_token, access_token } = message;
                    setRefreshToken(refresh_token);
                    setAccessToken(access_token);
                }

                // Message type is extensionLogout
                if (message.type === 'extensionLogout') {
                    logoutAccount();
                }
            }
            catch (e) {
                // Skip
            }

        });

        console.log('ON IFRAME READY');

        // On Iframe Ready
        window.parent.postMessage(JSON.stringify({type: 'iframeReady'}), '*');
    }, []);

    return null;
};

export { Extension };