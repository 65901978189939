import { useGridFilter } from 'ag-grid-react';
import { forwardRef, useCallback, useImperativeHandle, useRef } from 'react';

export const YesNoFilter = forwardRef((props, ref) => {
    const refInput = useRef<any>(null);
    console.log('props', props);
    
    // const doesFilterPass = useCallback(({ node }: any) => {
    //     const value = getValue(node).toString().toLowerCase();
    //     return model.value.toLowerCase().split(' ').every((filterWord: any) => value.indexOf(filterWord) >= 0);
    // }, [model]);

    // const afterGuiAttached = useCallback((params: any) => {
    //     window.setTimeout(() => {
    //         refInput.current.focus();
    //     });
    // }, []);

    // useGridFilter({
    //     doesFilterPass,
    //     afterGuiAttached,
    // });

    // useImperativeHandle(ref, () => {
    //     return {
    //         componentMethod(message: any) {
    //             alert(`Alert from PartialMatchFilterComponent: ${message}`);
    //         },
    //     };
    // });

    // const onChange = ({ target: { value } }: any) => {
    //     onModelChange({ value });
    // };

    // const style = {
    //     borderRadius: '5px',
    //     width: '200px',
    //     height: '50px',
    //     padding: '10px',
    // };

    // return (
    //     <div style={style}>
    //         Partial Match Filter:
    //         <input
    //             style={{ height: '20px' }}
    //             ref={refInput}
    //             value={model == null ? '' : model.value}
    //             onChange={onChange}
    //             className="form-control"
    //         />
    //     </div>
    // );

    return <div>AAA</div>
});