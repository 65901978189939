import React, { FC } from "react";
import {Col, Text} from "lavaa";
import css from "./style.module.scss";

const VitalsEmpty: FC = React.memo(() => {
  return (
      <Col>
          <Text text="No Vitals yet" size="x3" className={css.Empty}/>
      </Col>
  )
});

export { VitalsEmpty };
