import { createSlice } from '@reduxjs/toolkit';

interface IHelpCenterData {
    faQs: any[],
    tours: any[]
}

const initialState: any = {
    faQs: [],
    tours: []
};

export const helpCenterSlice = createSlice({
    name: 'HelpCenter',
    initialState,
    reducers: {

        // Get Help Center Data
        getHelpCenterDataAction: (state, action) => state,
        
        // Receive Help Center Data
        receiveHelpCenterDataAction: (state, action) => {
            const { faQs, tours } = action.payload;
            state.faQs = faQs;
            state.tours = tours;
        }
    }
});

export const { 
    getHelpCenterDataAction,
    receiveHelpCenterDataAction
} = helpCenterSlice.actions;
export default helpCenterSlice.reducer;