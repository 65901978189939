import React, {FC} from "react";
import {Col, Dropdown, iconsTypes, Row} from "lavaa";
import css from "./style.module.scss";

const switchView: any = [
    { id: "diagnoses", value: "By Diagnoses" },
    { id: "priority", value: "By Priority" },
];

const filterByDiagnosesData: any = [
    { id: "with-g", value: "Modules with Guidelines" },
    { id: "without-g", value: "Modules without Guidelines" },
    { id: "checked", value: "Completed guidelines" },
    { id: "unchecked", value: "Uncompleted guidelines" },
    { id: "all", value: "All" },
];
const filterByPriorityData: any = [
    { id: "checked", value: "Completed guidelines" },
    { id: "unchecked", value: "Uncompleted guidelines" },
    { id: "all", value: "All" },
];

interface IProps {
    view: string,
    filter: string,
    changeView: (arg0: string) => void,
    onChangeFilter: (arg0: string) => void
}

// Guidelines Controls
const GuidelinesControls: FC<IProps> = React.memo((props) => {
    const { view, changeView, filter, onChangeFilter } = props;

    function selectViewHandler(data: any){
        changeView(data.id);
    }

    function selectFilterHandler(data: any){
        onChangeFilter(data.id);
    }

    const viewSelected = switchView.find((item: any) => item.id === view);

    const filterData = viewSelected.id === "priority" ? filterByPriorityData : filterByDiagnosesData;
    const filterSelected = filterData.find((item: any) => item.id === filter);

    return (
        <Row className={css.Controls} alignitems="center" justifycontent="space-between" paddingLeft="0.5rem" paddingRight="0.5rem">
            <Col>
                <Dropdown type="simple" placeholder="Filter" data={ switchView } selected={viewSelected} onSelect={selectViewHandler}></Dropdown>
            </Col>
            <Row>
                <Dropdown icon={iconsTypes.filter} type="button-simplified" hidePlaceholder={true} data={ filterData } selected={filterSelected} onSelect={selectFilterHandler}></Dropdown>
            </Row>
        </Row>
    )
});

export {GuidelinesControls};
