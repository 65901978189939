import {iconsTypes, ITabsSingleContent} from "lavaa";

export const patientPageTabs: ITabsSingleContent[] = [
    {
        title: 'Personal Info',
        icon: iconsTypes.person
    },
    {
        title: 'Health Card',
        icon: iconsTypes.healthCard
    },
    {
        title: 'Insights',
        icon: iconsTypes.treatment
    },
    {
        title: 'Basic Assessment',
        icon: iconsTypes.basicAssessment
    },
    {
        title: 'Surveys',
        icon: iconsTypes.queryStats
    },
];