import {PayloadAction, createSlice} from '@reduxjs/toolkit';
import { ThemeStateType, ThemeType } from './Theme.types';

const initialState: ThemeStateType = {
    theme: localStorage.getItem('theme') as ThemeType || 'light'
};

export const themeSlice = createSlice({
    name: 'Theme',
    initialState,
    reducers: {

        // Set Theme
        setThemeAction: (state: ThemeStateType, action: PayloadAction<{theme: ThemeType}>) => {
            const { theme } = action.payload;
            state.theme = theme;
        },
    }
});

export const {
    setThemeAction
} = themeSlice.actions;
export default themeSlice.reducer;