import { FC } from "react";
import {Col, Text, Row, Checkbox, Grid, Cell, Icon, iconsTypes} from 'lavaa';
import {MenuWidgets} from "./type";
import css from "./style.module.scss";
import {classNames} from "../../../../Tools";


interface IProps {
    menuWidgetsLocal: MenuWidgets[],
    setMenuWidgetsLocal: (data: MenuWidgets[]) => void
}

// Menu Widgets Content
const MenuWidgetsContent: FC<IProps> = (props) => {

    const {menuWidgetsLocal, setMenuWidgetsLocal} = props;

    function checkHandler(key: MenuWidgets, value:boolean) {
        if (!value) {
            setMenuWidgetsLocal(menuWidgetsLocal.filter((item) => item !== key));
        } else {
            setMenuWidgetsLocal([...menuWidgetsLocal, key]);
        }
    }

    function showItem(value: MenuWidgets, index: number | string){

        const isChecked = menuWidgetsLocal.includes(value);
        const isDisabled = !isChecked && menuWidgetsLocal.length >= 2;
        const hasArrow = isChecked && index != 0;

        function changeHandler(changeValue:boolean) {
            if(isDisabled) return;
            checkHandler(value, changeValue);
        }

        function pushUpHandler(){
            if(!hasArrow) return;
            const index = menuWidgetsLocal.indexOf(value);
            const local = [...menuWidgetsLocal];
            local.splice(index, 1);
            local.splice(index - 1, 0, value);
            setMenuWidgetsLocal(local);
        }

        return (
            <Row key={value}>
                <Cell grow="1">
                    <label htmlFor={ `widget_${index}` } className={classNames(css.Label, isDisabled ? css.disabled : "")}>
                        <Row>
                            <Cell paddingRight="0.5rem">
                                <Checkbox
                                    id={`widget_${index}`}
                                    checked={isChecked}
                                    onChange={(changeValue) => changeHandler(changeValue)}
                                    disabled={isDisabled}
                                />
                            </Cell>

                            <Text size="x3" className={classNames(css.Text, isDisabled ? css.disabled : "")}>{value}</Text>
                        </Row>
                    </label>
                </Cell>

                {
                    hasArrow && (
                        <Cell paddingLeft="0.5rem" className={css.ArrowUp} onClick={pushUpHandler}>
                            <Icon name={iconsTypes.arrowLongUp}/>
                        </Cell>
                    )
                }
            </Row>
        );
    }


    return (
        <Col>
            <Row paddingTop="0.5rem" paddingBottom="0.5rem" marginBottom="0.75rem">
                <Text size="x4" bold={true}>All Widgets</Text>
            </Row>


            <Grid className={css.List}>
                {
                    menuWidgetsLocal.map((value, index) => (
                        showItem(value, index)
                    ))
                }
                {
                    Object.entries(MenuWidgets).map(([key, value]) => {
                        if(menuWidgetsLocal.includes(value)) {
                            return null;
                        }
                        else{
                            return showItem(value, key);
                        }
                    })
                }
            </Grid>
        </Col>
    )
};

export { MenuWidgetsContent };