import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { reportsOptions } from "../../Reports/Reports.options";
import { Col, Row, Text, iconsTypes, Input } from "lavaa";
import { useAccount } from "../../../Hooks/UseAccount";
import css from "./ReportsMenu.module.scss";
import {useMixPanel} from "../../../Hooks/UseMixPanel";

interface IProps {
  activeReportType: string
}

// ReportsMenu
const ReportsMenu: FC<IProps> = (props) => {

  // Props
  const { activeReportType } = props;

  // States
  const [filter, setFilter] = useState<string>('');

  // Hooks
  const navigate = useNavigate();
  const { availableReports } = useAccount();
  const { track } = useMixPanel();
  
  // Handle Select Report
  const handleSelectReport = (reportType: string, reportName: string) => {
    track("Click on Report", {report: reportName});
    navigate('/reports/' + reportType);
  };

  // Filter Menu Items
  const filterMenuItems = (item: any) => {
    return item.name.toLowerCase().includes(filter.toLowerCase());
  };

  // Filter Available Reports
  const filterAvailableReports = (item: any) => {
    if (Array.isArray(availableReports) && availableReports.length) {
      return availableReports.includes(item.id);
    } else if (!availableReports) { // not exists
      return false;
    }
    return true;
  };

  return (
    <Col grow="1" paddingTop="1rem" paddingBottom="1rem" className={css.Menu}>
      
      {/* Menu Title */}
      <Row paddingBottom="1rem" paddingLeft="1.5rem" paddingRight="1.5rem">
        <Text text="Reports" size="x4" bold="true"/>
      </Row>

      {/* Menu Items */}
      <Row paddingLeft="1.5rem" paddingRight="1.5rem">
        <Input type="text" variant="inline" placeholder="Search by Reports Name" icon={iconsTypes.search} onChange={setFilter} />
      </Row>

      {/* Menu Items */}
      <Col className={css.MenuItems}>
        {
          reportsOptions
          .filter(filterAvailableReports)
          .filter(filterMenuItems).map(({name, reportType}) => (
            <Row key={reportType} className={css.Item} onClick={() => handleSelectReport(reportType, name)} data-active={reportType === activeReportType}>
              {name}
            </Row>
          ))
        }
      </Col>
    </Col>
  );
};

export { ReportsMenu };