import { FC } from 'react';
import { ITabsContent, Tabs, Container } from 'lavaa';
import { FlowOutput } from '../../FlowOutput/FlowOutput.component';
import { useDemo } from "../../../Hooks/useDemo";

// Upload Output
const UploadOutput: FC<any> = () => {

  const { dataOutput } = useDemo(false, false, true);

  const tabs: ITabsContent[] = [
    {
      title: 'Output',
      content: <FlowOutput logs={dataOutput} />,
      scroll: true
    },
  ];

  return (
    <Container paddingLeft="1rem" paddingRight="1rem">
      <Tabs content={tabs} />
    </Container>
  );
};

export { UploadOutput };