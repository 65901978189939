import { FC, useContext } from "react";
import { ModalsCtx } from "../../../../Context/Modals.context";
import { Modal } from 'lavaa';
import { useDemo } from "../../../../Hooks/useDemo";
import { FlowOutput } from "../../../FlowOutput/FlowOutput.component";

// Module Person Edit Output
const ModulePersonEditOutputModal: FC<any> = () => {

    const { isPersonEditOutputModalActive, setPersonEditOutputModalActive } = useContext(ModalsCtx);
    const { dataOutput } = useDemo();

    // Handle Close
    const handleClose = () => {
        setPersonEditOutputModalActive(false);
    };

    // Handle Cancel
    const handleCancel = () => {
        setPersonEditOutputModalActive(false);
    };

    // Handle Ok
    const handleOk = () => {
        setPersonEditOutputModalActive(false);
    };

    return <Modal
      active={ isPersonEditOutputModalActive }
      onClose={ handleClose }
      onCancel={ handleCancel }
      onOk={ handleOk }
      title="Output"
      cancelDisabled={ true }
      okText="Close"
      type="middle"
    >
        <FlowOutput logs={dataOutput} />
    </Modal>
};

export { ModulePersonEditOutputModal };