import { useDispatch, useSelector } from 'react-redux';
import { getHelpCenterDataAction } from "../Redux/Slices/HelpCenter/HelpCenter.slice";

export const useHelpCenter = (): any => {
    const dispatch = useDispatch();

    const helpCenterData = useSelector((state: any) => state.helpCenter);

    // Fetch Help Center Data
    const fetchHelpCenterData = (client: number) => {
        dispatch(getHelpCenterDataAction([client]));
    };

    return {
        fetchHelpCenterData,
        helpCenterData
    };
}
