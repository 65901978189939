import { createSlice } from '@reduxjs/toolkit';
import { projectAccessType } from './Projects.data';

export interface IProjectTypeState {
    data: {
        [key: string]: any
    },
    loading: boolean
}

interface IProjectsState {
    open: IProjectTypeState,
    myCompany: IProjectTypeState,
    my: IProjectTypeState
}

const initialState: IProjectsState = {
    open: {
        data: {},
        loading: false
    },
    myCompany: {
        data: {},
        loading: false
    },
    my: {
        data: {},
        loading: false
    }
}

export const projectsSlice = createSlice({
    name: 'Projects',
    initialState,
    reducers: {

        // Get Projects
        getProjectsAction: (state, action) => {
            
            // Set Loading...
            switch (action.payload.data) {
                case projectAccessType.open:
                    state.open.loading = true;
                    break;
                case projectAccessType.my:
                    state.my.loading = true;
                    break;
                case projectAccessType.myCompany:
                    state.myCompany.loading = true;
                    break;
            }

            return state;
        },

        // Receive Projects
        receiveProjectsAction: (state, action) => {
            const { type, results } = action.payload.data;
            let data: any = {};
            switch (type) {
                case projectAccessType.open:
                    state.open.loading = false;
                    data = state.open.data;
                    break;
                case projectAccessType.my:
                    state.my.loading = false;
                    data = state.my.data;
                    break;
                case projectAccessType.myCompany:
                    state.myCompany.loading = false;
                    data = state.myCompany.data;
                    break;
            }

            results.forEach((project: any) => {
                project['jsonLogic'] = (project['jsonLogic'] && project['jsonLogic'].length > 0) ? JSON.parse(project['jsonLogic']): {};
                data[project.uid] = project;
            });

            return state;
        },

        // Get Project
        getProjectAction: (state, action) => {
            console.log('Get Project Action --- ', action);
        },

        // Receive Project
        receiveProjectAction: (state, action) => {
            const project = action.payload.data;

            if(typeof project !== 'object') {
                return state;
            }

            project['jsonLogic'] = (project['jsonLogic'] && project['jsonLogic'].length > 0) ? JSON.parse(project['jsonLogic']): {};

            switch (project.accessType) {
                case projectAccessType.open:
                    state.open.data[project.uid] = project;
                    break;
                case projectAccessType.my:
                    state.my.data[project.uid] = project;
                    break;
                case projectAccessType.myCompany:
                    state.myCompany.data[project.uid] = project;
                    break;
            }
            return state;
        },

        // Add Project
        addProjectAction: (state, action) => {
            console.log('Add Project Action --- ', action.payload);
        },

        // Update Project
        updateProjectAction: (state, action) => {
        },

        // Receive Update Project
        receiveUpdateProjectAction: (state, action) => {
            const project = action.payload.data;

            if(typeof project !== 'object') {
                return state;
            }

            project['jsonLogic'] = (project['jsonLogic'] && project['jsonLogic'].length > 0) ? JSON.parse(project['jsonLogic']): {};
            switch (project.accessType) {
                case projectAccessType.open:
                    state.open.data[project.uid] = project;
                    delete state.my.data[project.uid];
                    delete state.myCompany.data[project.uid];
                    break;
                case projectAccessType.my:
                    state.my.data[project.uid] = project;
                    delete state.open.data[project.uid];
                    delete state.myCompany.data[project.uid];
                    break;
                case projectAccessType.myCompany:
                    state.myCompany.data[project.uid] = project;
                    delete state.open.data[project.uid];
                    delete state.my.data[project.uid];
                    break;
            }

            return state;
        },

        // Publish Project
        publishProjectAction: (state, action) => {
            console.log('Publish Project Action --- ', action);
        },

        // Run Project
        runProjectAction: (state, action) => {
            console.log('Run Project Action --- ', action);
        },

        receiveProjectRunAnswer: (state, action) => {
            console.log('Receive Project Run Action --- ', action);
        },

        // Delete Project
        deleteProjectAction: (state, action) => {
            console.log('Delete Project Action --- ', action);
        },

        // Receive Project Add
        receiveProjectAddAction: (state, action) => {
            const project = action.payload.data;

            if(typeof project !== 'object') {
                return state;
            }

            project['jsonLogic'] = (project['jsonLogic'] && project['jsonLogic'].length > 0) ? JSON.parse(project['jsonLogic']): {};

            switch (project.accessType) {
                case projectAccessType.open:
                    state.open.data[project.uid] = project;
                    break;
                case projectAccessType.my:
                    state.my.data[project.uid] = project;
                    break;
                case projectAccessType.myCompany:
                    state.myCompany.data[project.uid] = project;
                    break;
            }
            // console.log('Receive Project Add Action --- ', state.open.data[project.uid]);
            return state;
        },

        // Receive Publish Project
        receivePublishProjectAction: () => {
            console.log('Receive Publish Project Action --- ');
        },

        // Receive Delete Project
        receiveDeleteProjectAction: (state, action) => {
            const uid = action.payload.data;

            delete state.open.data[uid];
            delete state.my.data[uid];
            delete state.myCompany.data[uid];

            return state;
        }
    }
});

export const { 
    getProjectsAction,
    getProjectAction,
    addProjectAction,
    receiveProjectAddAction,
    updateProjectAction,
    publishProjectAction,
    runProjectAction,
    deleteProjectAction,
    receiveProjectsAction,
    receiveProjectAction,
    receiveUpdateProjectAction,
    receivePublishProjectAction,
    receiveDeleteProjectAction
} = projectsSlice.actions;
export default projectsSlice.reducer;