import { FC, useContext } from "react";
import { ModalsCtx } from "../../../../Context/Modals.context";
import { Col, Row, Icon, iconsTypes, Modal, Title } from "lavaa";
import { ReportLabel } from "../../../PatientPage/ReportLabel/ReportLabel.component";
import css from './PatientLabResultModal.module.scss';
import {AppCtx} from "../../../../Context/App.context";

// Patient Lab Result Modal
const PatientLabResultModal: FC<any> = () => {

    const { isPatientLabResultModalActive, setPatientLabResultModalActive, patientLabResultModalData, setPatientLabResultModalData } = useContext(ModalsCtx);
    const { moduleName = 'Unknown', labResult = { reasons: []}, client = {} } = patientLabResultModalData;
    const reasons = labResult.reasons.map((item: string) => item.split(';')).flat();
    const {deviceType} = useContext(AppCtx);

    // Handle Close
    const handleClose = () => {
        setPatientLabResultModalData({});
        setPatientLabResultModalActive(false);
    };

    // Handle Ok
    const handleOk = () => {
        setPatientLabResultModalData({});
        setPatientLabResultModalActive(false);
    };

    return <Modal 
        active={ isPatientLabResultModalActive } 
        onClose={ handleClose }
        cancelDisabled={ true }
        onOk={ handleOk }
        title={client ? client.name : 'Unknown'}
        okText="Close"
        type={ deviceType === 'mobile' ? 'large' : 'small' }
    >
        <Col className={ css.PatientLabResultModal }>
            
            {/* Header */}
            <Row alignitems="center">
                <Row grow="1">
                    <Title>{ moduleName }</Title>
                </Row>
                
                <Row>
                    <ReportLabel textColor={ labResult.textColor } bgColor={ labResult.bgColor }>{ labResult.resultGroupName }</ReportLabel>
                </Row>
            </Row>

            {/* Reasons */}
            {
                !reasons.length ? 'No data' :
                <Col paddingTop="1rem" className={ css.Reasons }>
                    {
                        reasons.map((item: any) => item === '' ? null : (<Row key={item} className={ css.Reason } paddingTop="0.75rem" paddingLeft="0.75rem" paddingRight="0.75rem" paddingBottom="0.75rem">
                        <Row grow="1">{ item }</Row>
                        {
                            (item.toLowerCase().endsWith('male') || item.toLowerCase().endsWith('female')) &&
                            <Row><Icon name={iconsTypes.link} /></Row>
                        }
                    </Row>))
                    }
                </Col>
            }
        </Col>
    </Modal>
};

export { PatientLabResultModal };