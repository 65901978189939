import React, {FC, useContext, useEffect, useState} from "react";
import { ModalsCtx } from "../../../../Context/Modals.context";
import { Modal} from 'lavaa';
import {MenuWidgetsContent} from "./content";
import {MenuWidgets} from "./type";

// Menu Widgets Modal
const MenuWidgetsModal: FC<any> = () => {

    const { isMenuWidgetsModalActive, setMenuWidgetsModalActive, menuWidgets, setMenuWidgets } = useContext(ModalsCtx);

    const [menuWidgetsLocal, setMenuWidgetsLocal] = useState<Array<MenuWidgets>>([]);

    useEffect(() => {
        if(isMenuWidgetsModalActive) initMenuWidgets();
    }, [isMenuWidgetsModalActive]);

    function initMenuWidgets(){
        const local: Array<MenuWidgets> = [];
        const values = Object.values(MenuWidgets);
        menuWidgets.map((value: MenuWidgets) => {
            if (values.includes(value)) {
                local.push(value);
            }
        });

        setMenuWidgetsLocal(local);
    }

    function saveChanges(){
        setMenuWidgets(menuWidgetsLocal);
    }

    // Handle Close
    const handleClose = () => {
        setMenuWidgetsModalActive(false);
    };

    // Handle Cancel
    const handleCancel = () => {
        setMenuWidgetsModalActive(false);
    };

    // Handle Ok
    const handleOk = () => {
        saveChanges();
        setMenuWidgetsModalActive(false);
    };


    return <Modal 
        active={ isMenuWidgetsModalActive }
        onClose={ handleClose } 
        onCancel={ handleCancel }
        onOk={ handleOk }
        title="Settings"
        okText="Save"
        cancelText="Cancel"
        type="small"
    >
        <MenuWidgetsContent menuWidgetsLocal={menuWidgetsLocal} setMenuWidgetsLocal={setMenuWidgetsLocal}/>
    </Modal>
};

export { MenuWidgetsModal };