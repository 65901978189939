import { FC } from 'react';
import { Col, Row, Text } from 'lavaa';

type Props = {
    value: string;
    colDef: any;
};

// ListCellRenderer Component
export const ListCellRenderer: FC<Props> = (props: Props) => {
    const { value, colDef } = props;
    const { renderField } = colDef;

    // Errors
    if (!Array.isArray(value)) {
        throw new Error('ListCellRenderer: value should be an array!');
    }

    if (!renderField) {
        throw new Error('ListCellRenderer: renderField is required!');
    }
    
    return (
        <Col>
            <Row>
                {
                    value.map((item: any, index: number) => (
                        <Row key={`${item[renderField]}_${index}`} paddingRight="0.5rem">
                            <Text>
                                {item[renderField]}
                                {
                                    index === value.length - 1 ? '' : ', '
                                }
                            </Text>
                        </Row>
                    ))
                }
            </Row>
        </Col>
    )
};