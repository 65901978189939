import React, {FC, useContext} from "react";
import css from "../../Questionnaire.module.scss";
import {Col, Row, Title, Text, iconsTypes, ScrollContainer, Button, Cell} from "lavaa";
import {QuestionnaireConfirmFields} from "./ConfirmPage.com";
import {AppCtx} from "../../../../../../../Context/App.context";

interface IProps {
    inputs: {[k: string]: any},
    onReturn: () => void
    onConfirm: () => void,
    loading: boolean
}

// Questionnaire Submit Page
const QuestionnaireSubmitPage: FC<IProps> = React.memo((props) => {

    const { onReturn, inputs, onConfirm, loading } = props;

    const {deviceType} = useContext(AppCtx);

    const questionsCount = Object.values(inputs).length;

    function confirmHandler(){
        if(loading) return;
        onConfirm();
    }

    const headerContainerMarginBottom = deviceType === 'mobile' ? '0.5rem' : '0';
    const titleSize: 'x6' | 'x5' = deviceType === 'desktop' ? 'x6' : 'x5';
    const textSize: 'x2' | 'x3' = deviceType === 'desktop' ? 'x3' : 'x2';
    const addPaddingScroll = deviceType === 'desktop';

    return (
        <Col className={css.Wrapper}>
            <Row marginBottom="2rem" alignitems="center" justifycontent="space-between" className={css.HeaderSubmit}>
                <Col grow="1">
                    <Cell className={css.HeaderContainer} grow="1" marginBottom={headerContainerMarginBottom}>
                        <Col className={css.Title} justifycontent="center">
                            <Title size={titleSize}>Review Answers</Title>
                        </Col>
                        <Col>
                            <Text size={textSize}>{questionsCount} Question{questionsCount != 1 ? 's' : ''}</Text>
                        </Col>
                    </Cell>
                    <Row>
                        <Text size='x3' bold={true}>Kindly review your responses before proceeding and CONFIRM your answers</Text>
                    </Row>
                </Col>
            </Row>
            <Col className={css.ScrollWrapper} grow="1">
                <ScrollContainer addPadding={addPaddingScroll}>
                    <Col className={css.FieldsWrapper}>
                        <QuestionnaireConfirmFields inputs={inputs}/>

                        {
                            deviceType === 'mobile' ? (
                                <Col justifycontent="start" paddingBottom="1rem" marginTop="2rem">
                                    <Row marginBottom="1rem">
                                        <Button
                                            icon={iconsTypes.arrowLeft}
                                            text="Back to form"
                                            type={"secondary"}
                                            onClick={onReturn}
                                            grow="1"
                                        />
                                    </Row>
                                    <Row>
                                        <Button
                                            text="Confirm"
                                            type={"primary"}
                                            onClick={confirmHandler}
                                            isLoading={loading}
                                            grow="1"
                                        />
                                    </Row>
                                </Col>
                            ) : (
                                <Row justifycontent="start" paddingBottom="1rem" marginTop="2rem">
                                    <Col marginRight="1.75rem">
                                        <Button
                                            icon={iconsTypes.arrowLeft}
                                            text="Back to form"
                                            type={"secondary"}
                                            onClick={onReturn}
                                        />
                                    </Col>
                                    <Col>
                                        <Button
                                            text="Confirm"
                                            type={"primary"}
                                            onClick={confirmHandler}
                                            isLoading={loading}
                                        />
                                    </Col>
                                </Row>
                            )
                        }
                    </Col>
                </ScrollContainer>
            </Col>
        </Col>
    )
});

export {QuestionnaireSubmitPage};
