import React, { FC } from "react";
import {Col, Text} from "lavaa";
import css from "./LifeStyle.module.scss";

const LifeStyleEmptyCom: FC = React.memo(() => {
  return (
      <Col>
          <Text text="No Lifestyle Survey Available" size="x3" className={css.Empty}/>
      </Col>
  )
});

export { LifeStyleEmptyCom };
