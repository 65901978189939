import {useDispatch, useSelector} from 'react-redux';
import {IActiveClientType, IClientType, IModuleType} from "../Redux/Slices/Demo/Demo.data";
import {
	getDemoClientsAction,
	getDemoModulesAction,
	getDemoClientAction,
	updateFlowIdAction,
	clearClientAction,
	nextPageAction,
	prevPageAction,
	addDataOutputAction,
	runDemoClientsAction,
	stopRunAction,
	setSortingAction,
	runDemoClientAction,
	updateLocalFlowIdAction,
	setPageAction,
	setAutocompleteAction,
	clearAutocompleteAction,
	getDemoClientGuidelinesAction,
	changeClientGuidelineStatusAction,
	changeGuidelineStatusAction,
	clearDemoClientsAction
} from "../Redux/Slices/Demo/Demo.slice";
import {useParams} from "react-router-dom";
import {useEffect, useRef} from "react";
import {useQuestionnaire} from "./UseQuestionnaire";
import {useAccount} from './UseAccount';

export const useDemo = (needPatientControl: boolean = false, needClientsControl: boolean = false, needRunControl: boolean = false, needModulesControl: boolean = false): any => {

	const dispatch = useDispatch();
	const {clearQuestionnaire} = useQuestionnaire();
	const {HMSProjectId} = useAccount();
	const HMSProjectIdPrev = useRef<string>(HMSProjectId);

	const {patientId, search} = useParams();
	const timerId = useRef<any>();

	const modules: IModuleType[] = useSelector((state: any) => state.demo.modules);
	const clients: IClientType[] = useSelector((state: any) => state.demo.clients);
	const clientsAutocomplete: IClientType[] = useSelector((state: any) => state.demo.clientsAutocomplete);
	const activeClient: IActiveClientType = useSelector((state: any) => state.demo.activeClient);
	const activeClientGuidelines: IActiveClientType = useSelector((state: any) => state.demo.activeClientGuidelines);
	const activeFlowId: string = useSelector((state: any) => state.demo.activeFlowId);
	const localFlowId: string = useSelector((state: any) => state.demo.localFlowId);
	const dataOutput = useSelector((state: any) => state.demo.output);
	const page = useSelector((state: any) => state.demo.page);
	const sorting = useSelector((state: any) => state.demo.sorting);
	const runState = useSelector((state: any) => state.demo.runState);
	const clientsLoading = useSelector((state: any) => state.demo.clientsLoading);

	useEffect(() => {
		if (!needPatientControl) return;
		if (patientId && patientId != activeClient?.client?.SourceClientID) {
			clearClient();
		}
	}, [patientId]);

	useEffect(() => {
		if (!needRunControl) return;

		if (timerId.current) clearTimeout(timerId.current);

		if (runState.isRunning) {
			timerId.current = setTimeout(ResponseTimeoutReached, 15000);
		}
	}, [runState.isRunning, runState.lastUpdate]);

	useEffect(() => {
		// Need Clients Control
		if (!needClientsControl) return;
		const searchValue = search ? search : "";

		fetchDemoClients(HMSProjectId, activeFlowId, searchValue);
	}, [page, sorting, search]);

	useEffect(() => {
		// Need Modules Control
		if (needModulesControl) {
			fetchDemoModules(HMSProjectId);
		}

		const isSameProject = HMSProjectIdPrev.current === HMSProjectId;
		HMSProjectIdPrev.current = HMSProjectId;

		// Need Clients Control
		if (needClientsControl && !isSameProject){
			const searchValue = search ? search : "";
			fetchInitDemoClients(HMSProjectId, activeFlowId, searchValue);
		}
	}, [HMSProjectId]);


	// Reset Run Data
	const ResponseTimeoutReached = () => {
		const message = 'Response timeout is reached.';
		dispatch(addDataOutputAction([message, 'error']));
		dispatch(stopRunAction([]));
	};

	const getModuleById = (moduleId: string) => {
		return modules.find((module) => module.ModuleUID === moduleId);
	}

	// Fetch Demo Modules
	const fetchDemoModules = (HMSProjectIdLocal: string | null = null) => {
		let projectId = HMSProjectIdLocal ?? HMSProjectId;
		console.warn('Fetch Demo Modules', projectId);
		if (projectId == null) return;

		dispatch(getDemoModulesAction([projectId]));
	};

	// Fetch Demo Clients
	const fetchDemoClients = (HMSProjectId: string, flowId: string = "", search = "", isAutocomplete = false) => {
		console.log('Fetch Demo Clients', HMSProjectId);

		if (isAutocomplete) {
			dispatch(setAutocompleteAction());
			dispatch(getDemoClientsAction([HMSProjectId, flowId, 0, 5, '', false, search]));
		} else {
			dispatch(getDemoClientsAction([HMSProjectId, flowId, page, 25, sorting.module, sorting.asc, search]));
		}
	};

	// Fetch Demo Clients on change ProjectId
	const fetchInitDemoClients = (HMSProjectId: string, flowId: string = "", search = "") => {
		console.log('Fetch Demo Clients on change project', HMSProjectId);

		dispatch(clearAutocompleteAction());
		dispatch(setPageAction(0));
		dispatch(setSortingAction({module: '', asc: false}));
		dispatch(clearDemoClientsAction());
		dispatch(getDemoClientsAction([HMSProjectId, flowId, page, 25, sorting.module, sorting.asc, search]));
	};



	// Clear AutoComplete
	const clearAutocomplete = () => {
		dispatch(clearAutocompleteAction());
	};

	// Fetch Demo Client
	const fetchDemoClient = (clientId: string, flowId: string = "", HMSProjectId: string = '') => {
		dispatch(getDemoClientAction([clientId, flowId, HMSProjectId]));
		dispatch(getDemoClientGuidelinesAction([clientId, flowId]));
	};

	// Change Client Guideline Status
	const changeClientGuidelineStatus = (clientGuideLineUID: string) => {
		dispatch(changeClientGuidelineStatusAction([clientGuideLineUID]));
	};

	// Change Guideline Status
	const changeGuidelineStatus = (guideLineUID: string, projectUID: string) => {
		const client = activeClient.client.SourceClientID.toString() || "";
		dispatch(changeGuidelineStatusAction([guideLineUID, client, projectUID]));
	};

	// Run Demo Clients
	const runDemoClients = (clients: string[]) => {
		dispatch(runDemoClientsAction([clients]));
	};

	// Run Demo Client
	const runDemoClient = (client: string, HMSProjectId: string = '') => {
		dispatch(runDemoClientAction([client, HMSProjectId]));
	};

	// Update FlowId
	const updateFlowId = (flowId: string = "") => {
		dispatch(updateFlowIdAction(flowId));
	};

	// Update Local FlowId
	const updateLocalFlowId = (flowId: string = "") => {
		dispatch(updateLocalFlowIdAction(flowId));
	};

	// Clear Demo Client
	const clearClient = () => {
		dispatch(clearClientAction([]));
		clearQuestionnaire();
	};

	// Add Data Output
	const addDataOutput = (output: string) => {
		dispatch(addDataOutputAction([output]));
	};

	// Set Next Page
	const setNextPage = () => {
		dispatch(nextPageAction());
	};

	// Set Prev Page
	const setPrevPage = () => {
		dispatch(prevPageAction());
	};

	// Set Page
	const setPage = (page: number) => {
		dispatch(setPageAction(page));
	};

	// Set Sort By
	const setSortBy = (module: string, asc: boolean | null = null) => {
		if (asc === null) {
			asc = true;

			if (module === sorting.module) {
				asc = !sorting.asc;
			}
		}

		dispatch(setSortingAction({module, asc}));
	};

	return {
		modules,
		clients, activeClient, clientsAutocomplete, activeClientGuidelines,
		activeFlowId, dataOutput, localFlowId, sorting,
		clientsLoading,
		getModuleById,
		changeClientGuidelineStatus, changeGuidelineStatus,
		fetchDemoModules, fetchDemoClients, fetchDemoClient, clearAutocomplete,
		page, setPage,
		setNextPage,
		setPrevPage,
		addDataOutput, runDemoClients, runDemoClient,
		updateFlowId, updateLocalFlowId,
		setSortBy
	};
}