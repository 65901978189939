import React, {FC, useEffect, useRef, useState} from "react";
import {QuestionnaireFormPage} from "./views/form/Form.com";
import {QuestionnaireSubmitPage} from "./views/submit/Submit.com";
import {QuestionnaireThanksPage} from "./views/thanks/ThanksPage.com";
import {useQuestionnaire} from "../../../../../Hooks/UseQuestionnaire";
import {QuestionnaireErrorPage} from "./views/error/ErrorPage.com";
import {LoadingFullScreen} from "lavaa";

enum StepType {
    Loading,
    Form,
    Submit,
    Waiting,
    ImmediatelyWaiting,
    Thanks,
    Error
}

interface IProps {
    questionnaireKey: string
}

// Questionnaire Page
const QuestionnairePage: FC<IProps> = React.memo((props) => {

    const {questionnaireKey} = props;
    const [inputs, setInputs] = useState<any>({});
    const [filledInputs, setFilledInputs] = useState<{[k: string]: any}>({});
    const [step, setStep] = useState<StepType>(StepType.Loading);
    const stepRef = useRef<StepType>(step);

    const {fetchOpenQuestionnaire, addOpenQuestionnaireAnswers} = useQuestionnaire();

    useEffect(() => {
        fetchOpenQuestionnaire(questionnaireKey);
    }, [questionnaireKey]);

    useEffect(() => {
        window.addEventListener('ReceiveOpenQuestionnaire', receiveOpenQuestionnaire, {passive: true});
        window.addEventListener('OpenQuestionnaireSubmitEvent', receiveOpenQuestionnaireSubmit, {passive: true});

        return () => {
            window.removeEventListener('ReceiveOpenQuestionnaire', receiveOpenQuestionnaire);
            window.removeEventListener('OpenQuestionnaireSubmitEvent', receiveOpenQuestionnaireSubmit);
        }
    }, []);

    // Check if questionnaire is exist
    function receiveOpenQuestionnaire(e: any){
        if(!e.detail){
            setStep(StepType.Error);
            stepRef.current = StepType.Error;
        }
        else{
            setStep(StepType.Form);
            stepRef.current = StepType.Form;
        }
    }

    // Check when questionnaire is submitted
    function receiveOpenQuestionnaireSubmit(){
        if(stepRef.current === StepType.Waiting || stepRef.current === StepType.ImmediatelyWaiting){
            setStep(StepType.Thanks);
            stepRef.current = StepType.Thanks;
        }
    }

    function changeHandler(field: string, value: any){
        setInputs((inputs: any) => {
            return Object.assign({}, inputs, {
                [field]: value
            });
        });
    }

    function onSubmitForm(filledInputs: any, confirmImmediately: boolean) {
        setFilledInputs(filledInputs);

        if(confirmImmediately) {
            confirmHandler(filledInputs);
        } else {
            setStep(StepType.Submit);
            stepRef.current = StepType.Submit;
        }
    }

    function onSubmitReturn(){
        if(step === StepType.Submit){
            setStep(StepType.Form);
            stepRef.current = StepType.Form;
        }
    }

    function confirmHandler(filledInputsLocal: any = undefined){
        addOpenQuestionnaireAnswers(questionnaireKey, filledInputsLocal || filledInputs);

        // If filledInputsLocal -> it's Immediately submit
        if(filledInputsLocal){
            setStep(StepType.ImmediatelyWaiting);
            stepRef.current = StepType.ImmediatelyWaiting;
        }
        else{
            setStep(StepType.Waiting);
            stepRef.current = StepType.Waiting;
        }
    }

    switch (step) {
        case StepType.Form:
        case StepType.ImmediatelyWaiting:
            return(
                <QuestionnaireFormPage
                    onSubmit={onSubmitForm}
                    inputs={inputs}
                    onChange={changeHandler}
                    loading={step === StepType.ImmediatelyWaiting}
                />
            );
        case StepType.Submit:
        case StepType.Waiting:
            return(
                <QuestionnaireSubmitPage
                    inputs={filledInputs}
                    onReturn={onSubmitReturn}
                    onConfirm={confirmHandler}
                    loading={step === StepType.Waiting}
                />
            );
        case StepType.Thanks:
            return(
                <QuestionnaireThanksPage/>
            );
        case StepType.Error:
            return(
                <QuestionnaireErrorPage/>
            );
        case StepType.Loading:
            return(
                <LoadingFullScreen/>
            );

    }

    return null;

});

export {QuestionnairePage};
