import {useStatistics} from "../../../../../Hooks/UseStatistics";
import {IStatisticType} from "../../../../../Redux/Slices/Statistics/Statistics.data";
import {BarChartDataType, BarChartLabelType} from "lavaa";

type RiskDataType = {
	// Risk Group
	[key: string]: number,
}

type ChartDataType = {
	// Age Group
	[key: string]: RiskDataType,
}

export function useTotalAgeStat(moduleId?: string, maxLabels: number = 4, maxRows: number = 5): [BarChartDataType[], BarChartLabelType[]]{
	const {statisticsData} = useStatistics();

	return prepareData(statisticsData, maxRows, maxLabels, moduleId);
}

function prepareData(statisticsData: IStatisticType[], maxRows: number, maxLabels: number, moduleId?: string): [BarChartDataType[], BarChartLabelType[]] {
	const [data, labelsData] = filterData(statisticsData, moduleId);

	const [dataSorted, labelsSorted] = sortData(data, labelsData);

	const [labelsFormatted, labels] = formatLabels(labelsSorted, maxLabels);

	const dataFormatted = formatData(dataSorted, labels, maxRows);

	return [dataFormatted, labelsFormatted];
}

// Merge data by Age Group and Risk Group
// Filter by module if moduleId is provided
function filterData(statisticsData: IStatisticType[], moduleId?: string): [ChartDataType, RiskDataType]{
	const labelsData: RiskDataType = {};
	const data: ChartDataType = {};

	statisticsData.forEach((item: IStatisticType) => {
		// Get data
		const moduleUID = item.ModuleUID;
		const group = item.AgeGroup;
		const risk = item.ResultGroupName;
		const count = item.Amount;
		const riskPercentage = item.ResultPercentage;

		// Filter by module if moduleId is provided
		if(moduleId && moduleUID !== moduleId){
			return;
		}

		// Create label if not exists
		if(!labelsData.hasOwnProperty(risk)){
			labelsData[risk] = -1;
		}

		// Update risk percentage if it is higher
		if(riskPercentage > labelsData[risk]){
			labelsData[risk] = riskPercentage;
		}

		// Create age group if not exists
		if(!data.hasOwnProperty(group)){
			data[group] = {}
		}

		// Create risk group if not exists
		if(!data[group].hasOwnProperty(risk)){
			data[group][risk] = 0;
		}

		// Increase risk group count
		data[group][risk] += count;
	});

	return [data, labelsData];
}

// Sort by data & labels
function sortData(data: ChartDataType, labelsData: RiskDataType): [[string, RiskDataType][], [string, number][]] {

	// Sort by age group name
	const dataSorted = Object.entries(data).sort((a: any, b: any) => {
		return b[0] > a[0] ? -1 : 1;
	});

	// Sort by labels risk percentage
	const labelsSorted = Object.entries(labelsData).sort((a: any, b: any) => {
		return b[1] - a[1];
	});

	return [dataSorted, labelsSorted];
}

function formatLabels(labelsSorted: [string, number][], maxLabels: number): [BarChartLabelType[], string[]]{
	// Remove labels if more than maxLabels
	const isLabelsMoreThanMax = labelsSorted.length > maxLabels;
	if(isLabelsMoreThanMax){
		labelsSorted.splice(maxLabels);
	}

	// Fill labels array
	const labels: string[] = [];
	labelsSorted.forEach(([label, _]: [string, number]) => {
		labels.push(label);
	});

	const labelsFormatted = labelFormatter(labels);
	return [labelsFormatted, labels];
}

// Format data for chart
function formatData(data: [string, RiskDataType][], labels: string[], maxRows: number): BarChartDataType[]{

	const dataFormatted: BarChartDataType[] = [];

	data.forEach(([group, riskData]: [string, RiskDataType], index) => {
		// Skip if there are more than maxRows
		if(data.length > maxRows && index >= maxRows){
			return;
		}

		const localData: BarChartDataType = {
			name: group,
			data: {},
		}

		// Count risk groups
		processingRiskData(localData.data, riskData, labels);
		dataFormatted.push(localData);
	});

	return dataFormatted;
}

// Process risk data and count
function processingRiskData(localData: RiskDataType, riskData: RiskDataType, labels: string[]) {
	Object.entries(riskData).forEach(([risk, count]: [string, number]) => {
		if(!labels.includes(risk)){
			return;
		}
		localData[risk] = count;
	});
}

function labelFormatter(labels: string[]):BarChartLabelType[]{
	return labels.map((label, index: number) => {
		return {
			key: label,
			name: label,
			color: colors[index % colors.length]
		}
	})
}

const colors = [
	"#FF3E1E", "#FFA726", "#FDD835",
	"#00E676", "#07B951","#14C9C9",
	"#3B54D0","#7C4DFF","#B1BAF4",
	"#90BE6D", "#6F767E"
];