import {FC, useState} from 'react';
import { Col, Row, Cell, Title, Input, Button, Text } from 'lavaa';
import css from '../Auth.module.scss';

// Login Page
const AuthRegistration: FC<any> = () => {
    // States
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');

    return (
        <Col className={ css.AuthPage } alignitems="center" justifycontent="center">
            {/* Form */}
            <Col className={ css.AuthForm } alignitems="center">
                <Title
                    textAlign="center"
                    grow="1"
                    size="x8"
                >
                    Create Account
                </Title>

                <Col className={css.AuthInputs}>

                    {/* Name */}
                    <Row grow="1" paddingBottom="1.5rem">
                        <Input
                            type="text"
                            wrap="col"
                            label="*Full Name"
                            placeholder="........................................"
                            onChange={ setName }
                            value={name}
                            className={css.AuthInput}
                            labelClassName={css.AuthInputLabel}
                        />
                    </Row>

                    {/* Email */}
                    <Row grow="1" paddingBottom="1.5rem">
                        <Input
                            type="text"
                            wrap="col"
                            label="*E-mail"
                            placeholder="..............................@........"
                            onChange={ setEmail }
                            value={email}
                            className={css.AuthInput}
                            labelClassName={css.AuthInputLabel}
                        />
                    </Row>

                    {/* Password */}
                    <Row grow="1" paddingBottom="1.5rem">
                        <Input
                            type="password"
                            wrap="col"
                            label="*Password"
                            placeholder="........................................"
                            onChange={ setPassword }
                            value={password}
                            className={css.AuthInput}
                            labelClassName={css.AuthInputLabel}
                        />
                    </Row>

                    {/* Password Confirm */}
                    <Row grow="1" paddingBottom="1.5rem">
                        <Input
                            type="password"
                            wrap="col"
                            label="*Confirm Password"
                            placeholder="........................................"
                            onChange={ setPasswordConfirm }
                            value={passwordConfirm}
                            className={css.AuthInput}
                            labelClassName={css.AuthInputLabel}
                        />
                    </Row>
                    <Cell grow="1" paddingBottom="1.5rem">
                        <Button text="Sing Up"  size="large" grow="1" />
                    </Cell>
                    <Cell paddingBottom="2rem" justifycontent="center">
                        <Text size="x3" textAlign="center">Already have an account?</Text>
                        <Text size="x3" textAlign="center" link="/login" bold={true} className={css.AuthLinkSignUp}>Log In</Text>
                    </Cell>
                </Col>
            </Col>
        </Col>
    );
};

export { AuthRegistration };