import React, {FC} from 'react';
import {Col, Text} from 'lavaa';
import {useDemo} from "../../../../Hooks/useDemo";

const LastUpdate: FC = React.memo(() => {

    const { activeClient } = useDemo();

    if ( !activeClient?.client || !activeClient?.client?.LastUpdateDate ) return null;


    const lastUpdateRaw = activeClient?.client?.LastUpdateDate;
    const lastUpdate = new Date(lastUpdateRaw);

    let month: string | number = lastUpdate.getMonth() + 1;
    if(month < 10) month = `0${month}`;

    let day: string | number = lastUpdate.getDate();
    if(day < 10) day = `0${day}`;

    const lastUpdateString = `${month}.${day}.${lastUpdate.getFullYear()}`;

    return (
        <Col paddingLeft="1.5rem">
            <Text size="x3">Last updates: {lastUpdateString}</Text>
        </Col>
    )
});

export {LastUpdate};
