import {FC, useContext, useEffect, useState} from "react";
import { ModalsCtx } from "../../../../Context/Modals.context";
import {Col, Row, Modal, Text, Button, iconsTypes} from "lavaa";
import css from "./style.module.scss";
import {useModules} from "../../../../Hooks/UseModules";
import {useTypes} from "../../../../Hooks/UseTypes";
import parse from 'html-react-parser';

// Diagnose Info Modal
const DiagnoseInfoModal: FC<any> = () => {

    const { isDiagnoseInfoModalActive, setDiagnoseInfoModalActive, diagnoseInfoModalData, setDiagnoseInfoModalData } = useContext(ModalsCtx);
    const { moduleName, moduleUID } = diagnoseInfoModalData;
    const { getModuleTemplate, fetchModuleInfo} = useModules();
    const { getDisorder } = useTypes();
    const [fullScreenAdditionalActive, setFullScreenAdditionalActive] = useState<boolean>(false);


    const module = getModuleTemplate(moduleUID);
    const disorder = module?.disorderTypeID ? getDisorder(module.disorderTypeID) : {};

    useEffect(() => {
        if(moduleUID) fetchModuleInfo(moduleUID);
    }, [moduleUID]);

    // Handle Close
    const handleClose = () => {
        setDiagnoseInfoModalData({});
        setDiagnoseInfoModalActive(false);
    };

    // Handle Ok
    const handleOk = () => {
        setDiagnoseInfoModalData({});
        setDiagnoseInfoModalActive(false);
    };

    // Toggle Full Screen Mode
    const toggleFullScreen = () => {
        setFullScreenAdditionalActive(!fullScreenAdditionalActive);
    };

    return <Modal 
        active={ isDiagnoseInfoModalActive }
        onClose={ handleClose }
        cancelDisabled={ true }
        onOk={ handleOk }
        title="Info"
        okText="Close"
        type="middle"
    >
        <Col>
            <Col grow="1" marginBottom="1.5rem">
                <Row marginBottom="0.25rem">
                    <Text size="x2" className={css.Label}>Diagnoses name</Text>
                </Row>
                <Row>
                    <Text size="x3" className={css.Value}>{ moduleName }</Text>
                </Row>
            </Col>
            {
                module && (
                    <>
                        <Col grow="1" marginBottom="1.5rem">
                            <Row marginBottom="0.25rem">
                                <Text size="x2" className={css.Label}>Type of disorder</Text>
                            </Row>
                            <Row>
                                <Text size="x3" className={css.Value}>{disorder.name ?? ''}</Text>
                            </Row>
                        </Col>
                        <Col grow="1" marginBottom="1.5rem">
                            <Row marginBottom="0.25rem">
                                <Text size="x2" className={css.Label}>Description</Text>
                            </Row>
                            <Row>
                                <Text size="x3" className={css.Value}>{module.description}</Text>
                            </Row>
                        </Col>
                        <Col grow="1" className={fullScreenAdditionalActive ? css.AdditionalInfoFullscreen : ""}>
                            <Row marginBottom="0.25rem" justifycontent="space-between" alignitems="center">
                                <Text size="x2" className={css.Label}>Additional info</Text>

                                {/* Fullscreen Button */}
                                {
                                    <Row grow="1" justifycontent="end">
                                        <Button onClick={ toggleFullScreen } type="secondary" icon={ iconsTypes.flowFullScreen } variant="controls" />
                                    </Row>
                                }
                            </Row>
                            <Col className={css.AdditionalInfo}>
                                {parse(module.additionalInfo)}
                            </Col>
                        </Col>
                    </>
                )
            }
        </Col>
    </Modal>
};

export { DiagnoseInfoModal };