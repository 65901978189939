import React, {FC, useContext, useEffect, useState} from "react";
import css from "../../Questionnaire.module.scss";
import {Col, Row, Title, Text, ScrollContainer, Button, Cell} from "lavaa";
import {QuestionnaireFields} from "./Fields.com";
import {classNames} from "../../../../../../../Tools";
import {AppCtx} from "../../../../../../../Context/App.context";
import {useQuestionnaire} from "../../../../../../../Hooks/UseQuestionnaire";

interface IProps {
    onSubmit: (filledInputs: {[k: string]: any}, confirmImmediately: boolean) => void
    inputs: any,
    onChange: (field: string, value: any) => void,
    loading: boolean
}

// Questionnaire Form Page
const QuestionnaireFormPage: FC<IProps> = React.memo((props) => {

    const { onSubmit, inputs, onChange, loading } = props;

    const {deviceType} = useContext(AppCtx);

    const [showSubmit, setShowSubmit] = useState<boolean>(false);

    const {questionnaireData} = useQuestionnaire();

    useEffect(() => {
        setShowSubmit(false);
    }, [questionnaireData]);

    useEffect(() => {
        validateFields();
    }, [inputs]);


    function validateFields(){
        const filledInputs: any = {};
        let canSubmit = false;

        Object.entries(inputs).map(([key, value]) => {
            if( value !== null && value !== undefined && value !== ''){
                // TODO - add input validation
                filledInputs[key] = value;
                canSubmit = true;
            }
        });

        setShowSubmit(canSubmit);
        return filledInputs;
    }

    function submit(confirmImmediately: boolean = false) {
        if(!showSubmit) return;

        const filledInputs: any = validateFields();

        if(Object.keys(filledInputs).length === 0) {
            setShowSubmit(false);
            return;
        }

        onSubmit(filledInputs, confirmImmediately);
    }

    const questionsCount = questionnaireData.length;

    const titleSize: 'x6' | 'x5' = deviceType === 'desktop' ? 'x6' : 'x5';
    const textSize: 'x2' | 'x3' = deviceType === 'desktop' ? 'x3' : 'x2';
    const addPaddingScroll = deviceType === 'desktop';

    return (
        <Col className={css.Wrapper}>
            <Row marginBottom="2rem" alignitems="center" justifycontent="space-between">
                <Cell className={css.HeaderContainer} grow="1">
                    <Col className={css.Title}>
                        <Title size={titleSize}>Questionnaire</Title>
                    </Col>
                    <Col>
                        <Text size={textSize}>{questionsCount} Question{questionsCount != 1 ? 's' : ''}</Text>
                    </Col>
                </Cell>
            </Row>
            <Col className={css.ScrollWrapper} grow="1">
                <ScrollContainer addPadding={addPaddingScroll}>
                    <Col className={classNames(css.FieldsWrapper, questionsCount > 0 && css._hasContent, questionsCount === 1 && css._hasOneField)}>
                        <QuestionnaireFields
                            questionnaireData={questionnaireData}
                            inputs={inputs}
                            onChange={onChange}
                        />
                        {
                            (questionsCount > 0) && (
                                <>
                                    {/* Save results */}
                                    <Row justifycontent="start" paddingBottom="1rem">
                                        <Button
                                            text="Submit"
                                            type={showSubmit ? "primary" : "disable"}
                                            onClick={() => submit(true)}
                                            size={deviceType === "mobile" ? "large" : "default"}
                                            isLoading={loading}
                                        />
                                    </Row>

                                    {/* Review + Save */}
                                    {
                                        (!loading) && (
                                            <Row justifycontent="start" paddingBottom="1rem">
                                                <Button
                                                    text="Review + Save"
                                                    type={showSubmit ? "secondary" : "disable"}
                                                    onClick={() => submit(false)}
                                                    size={deviceType === "mobile" ? "large" : "default"}
                                                />
                                            </Row>
                                        )
                                    }
                                </>
                            )
                        }
                    </Col>
                </ScrollContainer>
            </Col>
        </Col>
    )
});

export {QuestionnaireFormPage};
