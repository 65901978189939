import { createSlice } from '@reduxjs/toolkit';

interface IModuleTypeState {
    templates: any[],   // Menu item template data
    nodes: {            // Flow nodes data (full data in case when fetch module by primaryId)
        [key: string]: any
    },
    reports: any,
    onLoading: string[]
}

const initialState: IModuleTypeState = {
    templates: [],
    nodes: {},
    reports: {},
    onLoading: []
};

export const modulesSlice = createSlice({
    name: 'Modules',
    initialState,
    reducers: {

        // Get Modules
        getModulesAction: (state, action) => state,

        // Receive Modules
        receiveModulesAction: (state, action) => {
            let payload = action.payload.data?.results || [];

            if (payload.length) {
                payload = payload
                    .map((item: any) => ({...item, reportConfigs: item.reportConfigs || []}))
                    .filter((item: any) => !state.templates.some((itemState) => itemState.uid === item.uid && itemState.accessType === item.accessType));
            }

            state.templates = [...state.templates, ...payload];
        },

        // Get Module Template
        getModuleTemplateAction: (state, action) => {
            // console.log('Get Module Action Template --- ', action);
        },

        // Receive Module Template
        receiveModuleTemplateAction: (state, action) => {
            const module = action.payload.data;

            if(typeof module !== 'object') {
                return state;
            }

            module['jsonLogic'] = (module['jsonLogic'] && module['jsonLogic'].length > 0) ? JSON.parse(module['jsonLogic']): "";
            state.templates = state.templates.map((item: any) => (item.primaryId === module.primaryId ? module : item));
        },

        // Get Module Info
        getModuleInfoAction: (state, action) => {
        },

        // Receive Module Template
        receiveModuleInfoAction: (state, action) => {
            const module = action.payload.data;

            if(typeof module !== 'object') {
                return state;
            }

            module['jsonLogic'] = (module['jsonLogic'] && module['jsonLogic'].length > 0) ? JSON.parse(module['jsonLogic']): "";

            let isExist = false;
            state.templates = state.templates.map((item: any) => {
                    if (item.primaryId === module.primaryId) {
                        isExist = true;
                        return module;
                    }
                    else{
                        return item;
                    }
                }
            );

            if(!isExist){
                state.templates.push(module);
            }
        },

        // Get Module
        getModuleAction: (state, action) => {
            // console.log('Get Module Action --- ', action);
            state.onLoading.push(action.payload[1]);
        },

        // Receive Module
        receiveModuleAction: (state, action) => {
            if(action.payload.resultCode !== "OK"){
                const requestIds = action.payload.data;
                state.onLoading = state.onLoading.filter((id) => id !== requestIds[1]);
                return;
            }

            const module = action.payload.data;
            if(typeof module !== 'object') {
                return state;
            }

            module['jsonLogic'] = (module['jsonLogic'] && module['jsonLogic'].length > 0) ? JSON.parse(module['jsonLogic']): "";
            const moduleId = action.payload.data.nodeId;
            state.nodes[moduleId] = module;
            state.onLoading = state.onLoading.filter((id) => id !== moduleId);
        },

        // Get Module And Update
        getModuleAndUpdateAction: (state, action) => {
            // console.log('Get Module And Update Action --- ');
        },

        // Add Module
        addModuleAction: (state, action) => {
            // console.log('Add Module Action --- ');
        },

        // Receive Add Module
        receiveAddModuleAction: (state, action) => {
            const module = action.payload.data;
            state.templates = [...state.templates, {...module}];
        },

        // Buffer Report Data
        bufferReportDataAction: (state, action) => {
            const { payload } = action;
        },

        // Fetch Module Reports
        fetchModuleReportAction: (state, action) => {},

        // Receive Module Reports Action
        receiveModuleReportAction: (state, action) => {
            // const { payload } = action;
            // if (payload.data) {
            //     const moduleUID = payload.data.moduleUID;
            //     state.reports[moduleUID] = payload.data.configs;
            // }
        },

        // Update Module
        updateModuleAction: (state, action) => {},

        // Receive Update Module
        receiveUpdateModuleAction: (state, action) => {
            const module = action.payload.data;

            if(typeof module !== 'object') {
                return state;
            }

            module['jsonLogic'] = (module['jsonLogic'] && module['jsonLogic'].length > 0) ? JSON.parse(module['jsonLogic']): "";
            // console.log(module);
            // Update Template
            if (module.projectUID === null) {
                state.templates = state.templates.map((item: any) => (item.primaryId === module.primaryId ? module : item));
            }

            // Update Node
            if (module.projectUID !== null) {
                state.nodes[action.payload.data.nodeId] = module;
            }

            return state;
        },

        // Publish Module
        publishModuleAction: (state, action) => {
            // console.log('Publish Module Action --- ');
        },

        // Delete Module
        deleteModuleAction: (state, action) => {
            // console.log('Delete Module Action --- ');
        },

        // Receive Publish Module
        receivePublishModuleAction: (state, action) => {
            // console.log('Receive Publish Module Action --- ');
        },

        // Receive Delete Module
        receiveDeleteModuleAction: (state, action) => {
            const uid = action.payload.data;
            const templates = [...state.templates];
            templates.forEach((item: any, index: number, arr: Array<any>) => item.primaryId === uid && arr.splice(index, 1));
            state.templates = [...templates];
        },

        // Reset Modules
        resetModulesAction: (state) => {
            return {...initialState};
        }
    }
});

export const {
    getModulesAction,
    getModuleTemplateAction,
    getModuleAction,
    getModuleInfoAction,
    getModuleAndUpdateAction,
    addModuleAction, bufferReportDataAction, fetchModuleReportAction,
    updateModuleAction,
    publishModuleAction,
    deleteModuleAction,
    receiveModulesAction,
    receiveModuleTemplateAction,
    receiveModuleAction,
    receiveModuleInfoAction,
    receiveAddModuleAction,
    receiveUpdateModuleAction,
    receivePublishModuleAction,
    receiveDeleteModuleAction,
    resetModulesAction
} = modulesSlice.actions;
export default modulesSlice.reducer;
