import { FC, memo, useState } from "react";
import { classNames } from "../../../../Tools";
import { Modal, Row, Col, iconsTypes, Icon, Text, Button } from "lavaa";
import css from './SnoozeRenderer.module.scss';

type Props = {
    data: any;
    colDef: any;
    value: any;
    handleAction: (actionType: string, data: any) => void;
    eParentOfValue: any;
    eGridCell: any;
    modalTitle: string;
    modalBodyText: string;
    useReasons?: boolean;
};

// Reasons
const reasons = [
    'Specialist appointment made',
    'Appointment made',
    'Call completed',
    'Left Message',
    'SNF',
    'Hospice',
    'Deceased',
    'Not our Patient',
    'Other'
];

// SnoozeRendererConfirmOnly Component
export const SnoozeRendererConfirmOnly: FC<Props> = memo((props: Props) => {
    
    // Props
    const { data, colDef, handleAction, eParentOfValue, eGridCell, modalTitle, modalBodyText, useReasons = false } = props;
    
    const startDate = !!data.StartDateForSnooze ? new Date(data.StartDateForSnooze) : null;
    const endDate = !!data.FinishDateForSnooze ? new Date(data.FinishDateForSnooze) : null;
    const now = new Date();    
    const snooze = startDate !== null && endDate !== null ? now >= startDate && now <= endDate : false;
    
    const znoozeBtnProp = {
        ...props,
        colDef: {
            ...colDef,
            rendererOptions: {
                ...colDef.rendererOptions,
                text: snooze ? 'Activate' : 'Snooze'
            }
        }
    };
    
    // States
    const [snoozeConfirm, setSnoozeConfirmation] = useState<boolean>(false);
    const [clientId, setSnoozeClientId] = useState<string | null>(null);
    const [from, setFrom] = useState<number>();
    const [to, setTo] = useState<number>();
    const [reason, setReason] = useState('');

    // Handle Snooze Patient
    const handleSnoozePatient = (id: any) => {
        
        // Show Snooze Confirmation
        setSnoozeConfirmation(true);

        // Set Snooze Client Id
        setSnoozeClientId(id);
    };

    // Handle Unsnooze Client
    const handleUnsnoozePatient = (id: string) => {
        handleAction('unSnoozePatient', {clientId: id});
    };

    // Handle Snooze Confirmation Close
    const handleSnoozeConfirmationClose = () => {

        // Hide Snooze Confirmation
        setSnoozeConfirmation(false);

        // Reset Snooze Patient Id
        setSnoozeClientId(null);

        setFrom(undefined);
        setTo(undefined);
        setReason('');
    };

    // Handle Snooze Confirmation Apply
    const handleSnoozeConfirmationApply = () => {

        // Hide Snooze Confirmation
        setSnoozeConfirmation(false);

        // Snooze Client
        // setSnoozeClientId(null);
        // setFrom(undefined);
        // setTo(undefined);
        // setReason('');

        const from = new Date();
        const to = new Date(from.getTime() + 360 * 24 * 60 * 60 * 1000); // 1 year

        // Send Snooze Patient Action
        handleAction('snoozePatient', {clientId, from, to, reason});
    };

    // Handle Date Range
    const handleDateRangeChange = (days: number = 1) => {
        const from = new Date();
        const to = new Date(from.getTime() + days * 24 * 60 * 60 * 1000);

        // Update States
        setFrom(from.getTime());
        setTo(to.getTime());
    };

    // Handle Mouse Over
    const handleMouseOver = (e: any) => {
        const wrapperEl = eGridCell.querySelector('.ag-cell-wrapper');
        const valueEl = eGridCell.querySelector('.ag-cell-value');
        
        wrapperEl.style.overflow = 'visible';
        valueEl.style.overflow = 'visible';
    };

    // Handle Mouse Leave
    const handleMouseLeave = (e: any) => {
        const wrapperEl = eGridCell.querySelector('.ag-cell-wrapper');
        const valueEl = eGridCell.querySelector('.ag-cell-value');
        
        wrapperEl.style.overflow = 'hidden';
        valueEl.style.overflow = 'hidden';
    };
    
    return (
        <>

            {/* Snooze Button */}
            <Row alignitems="center">
                <Row alignitems="center" justifycontent="center" grow="1">
                    <Button 
                        onClick={(e: any) => {snooze ? handleUnsnoozePatient(data.ClientID) : handleSnoozePatient(data.ClientID)}} 
                        icon={iconsTypes.resolve} 
                        type="secondary" 
                        variant="text" 
                        iconSize="18" 
                    />
                </Row>

                {/* Reason Tooltip */}
                {
                    snooze && data && data.SnoozeReason ? <Row paddingLeft="0.5rem" alignitems="center">
                        <span className={css.ReasonTooltip} data-tooltip={data.SnoozeReason} onMouseOver={handleMouseOver} onMouseLeave={handleMouseLeave}>
                            <Icon name={iconsTypes.infoOutlined} />
                        </span>
                    </Row> : null
                }
                
            </Row>

            {/* Snooze Modal */}
            <Modal 
                active={snoozeConfirm} 
                title={modalTitle} 
                isFormValid={useReasons == true ? reason !== '' : true}
                width="400px" 
                height={useReasons ? '690px' : '170px'} 
                onClose={handleSnoozeConfirmationClose} 
                onCancel={handleSnoozeConfirmationClose} 
                onOk={handleSnoozeConfirmationApply}
                okText="Confirm"
                footerStyle={{borderTop: '1px solid var(--alternative-secondary-bg-color)'}}
                usePortal={true}>
                    <Col className={css.SnoozeRenderer}>
                        <Text text={modalBodyText} />

                        {/* Reasons */}
                        {
                            useReasons === false ? null : <Col paddingTop="1rem">
                                <Row paddingBottom="1rem">
                                    <Text size="x2" bold={true}>Reason *</Text>
                                </Row>
                                {
                                    reasons.map((item: string) => <Row paddingBottom="1rem" key={item}>
                                        <Button className={classNames(css.ReasonButton, item.toLowerCase() === reason.toLowerCase() ? css.Active : '')} onClick={() => setReason(item)}>{item}</Button>
                                    </Row>)
                                }
                            </Col>
                        }
                    </Col>
            </Modal>
        </>
    )
});