import { FC } from "react";
import {Col, Text, Row, Checkbox, Grid, Cell, addSnackbar} from 'lavaa';
import css from "./style.module.scss";
import {capitalizeFirstLetter, classNames} from "../../../../Tools";
import {DashboardCards, DashboardCardType} from "../../../Pages/Dashboard/Dashboard.data";
import React from "react";
import {useMixPanel} from "../../../../Hooks/UseMixPanel";


interface IProps {
    menuDashboardCardsLocal: string[],
    setMenuDashboardCardsLocal: (data: string[]) => void
}

// Menu Dashboard Cards Content
const MenuDashboardCardsContent: FC<IProps> = (props) => {

    const {menuDashboardCardsLocal, setMenuDashboardCardsLocal} = props;
    const { track } = useMixPanel();

    function checkHandler(key: string, value:boolean) {
        const card = DashboardCards[key];
        if(!card) return;

        const type = capitalizeFirstLetter(card.type);

        if (!value) {
            setMenuDashboardCardsLocal(menuDashboardCardsLocal.filter((item) => item !== key));
            addSnackbar('warning', `${type} removed!`, `${card.title} was removed`);
        } else {
            setMenuDashboardCardsLocal([...menuDashboardCardsLocal, key]);
            addSnackbar('success', `${type} added`, `${card.title} was added`);
            track("Shown Charts (Dashboard)", {chart: key});
        }
    }

    function showItem(card: DashboardCardType, index: string | number){

        const isChecked = menuDashboardCardsLocal.includes(card.key);

        function changeHandler(changeValue:boolean) {
            checkHandler(card.key, changeValue);
        }

        return (
            <Row key={card.key} className={css.Item} alignitems="center">
                <Cell grow="1">
                    <label htmlFor={ `widget_${index}` } className={classNames(css.Label)}>
                        <Row>
                            <Cell paddingRight="0.5rem">
                                <Checkbox
                                    id={`widget_${index}`}
                                    checked={isChecked}
                                    onChange={(changeValue) => changeHandler(changeValue)}
                                />
                            </Cell>

                            <Text size="x2" className={css.Text} maxLines={2} ellipsis={true}>{card.title}</Text>
                        </Row>
                    </label>
                </Cell>
            </Row>
        );
    }


    return (
        <Col>
            <Grid className={css.Wrapper}>
                <Grid className={css.List}>
                    <Text size="x3" bold={true}>Charts</Text>
                    {
                        Object.entries(DashboardCards)
                            .filter(([_, card]) => card.type === "chart")
                            .map(([key, card]) => (
                                <React.Fragment key={key}>
                                    {showItem(card, key)}
                                </React.Fragment>
                            ))
                    }
                </Grid>
                <Grid className={css.List}>
                    <Text size="x3" bold={true}>Reports</Text>
                    {
                        Object.entries(DashboardCards)
                            .filter(([_, card]) => card.type === "report")
                            .map(([key, card]) => (
                                <React.Fragment key={key}>
                                    {showItem(card, key)}
                                </React.Fragment>
                            ))
                    }
                </Grid>
            </Grid>
        </Col>
    )
};

export { MenuDashboardCardsContent };