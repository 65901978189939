import { FC } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Text } from 'lavaa';

type Props = {
    value: string;
    colDef?: any;
    data?: any;
};

// LinkCellRenderer Component
export const LinkCellRenderer: FC<Props> = (props: Props) => {
    const { value, colDef = {}, data = {} } = props;
    const { url = '', target = '' } = colDef;
    let resultUrl = url;

    const re = /\$\{(.*?)\}/;
    const match = url.match(re);
    if (match && match[1]) {
        const fieldName = match[1];

        if (data.hasOwnProperty(fieldName)) {
            resultUrl = url.replace(re, data[fieldName]);
        }
    }
    
    return (
        <Row shrink="1" justifycontent="center" style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
            <Text size="x2">
                <Link to={resultUrl} target={target}>{value}</Link>
            </Text>
        </Row>
    )
};