import React, {FC, useEffect} from "react";
import css from "./style.module.scss";
import {Col, Text} from "lavaa";
import {classNames} from "../../../Tools";
import {QuestionnaireInput} from "./fields";
import {IQuestionnaireType} from "../../../Redux/Slices/Questionnaire/Questionnaire.data";
import {generateDefaultValue, parseQuestionnaireType, QuestionnaireInputTypes} from "./type";

interface IProps {
    question: IQuestionnaireType,
    active?: boolean,
    value: any,
    onChange: (field: string, data: any) => void,
    num: number
}

// Questionnaire Field
const QuestionnaireField: FC<IProps> = React.memo((props) => {
    const {question, active = true, value, onChange, num} = props;
    const label = question.QuestionText;
    const parameters = question.Parameter;
    const id = parameters.ParameterID.toString();
    const typeRaw = parameters.DataType;

    useEffect(() => {
        const defValue = generateDefaultValue(typeRaw);
        if(value != defValue && value === undefined) onChange(id, defValue);
    }, []);


    if(parseQuestionnaireType(typeRaw) === null) return null;
    const type: QuestionnaireInputTypes = parseQuestionnaireType(typeRaw) as QuestionnaireInputTypes;


    function changeValue(data: any){
        onChange(id, data);

        // setValues((values: any) => {
        //     const newValues = Object.assign({}, values);
        //     newValues[index] = data;
        //     return newValues;
        // });
    }

    // const showAddMoreBtn = multiple && (type != 'dropdown');

    return (
        <Col className={classNames(css.Input, active ? '' : css.Inactive)} data-id={id}>
            <Col className={css.InputTitle}>
                <Text size="x3" bold={true}>{num+1}. {label}</Text>
            </Col>
            <Col>
                <QuestionnaireInput
                    value={value}
                    type={type}
                    question={question}
                    changeValue={changeValue}
                />
            </Col>
            {/*{*/}
            {/*    showAddMoreBtn && (*/}
            {/*        <Row justifycontent="start">*/}
            {/*            <Button text="Add more answers" icon={iconsTypes.plus} type="secondary" variant="text" onClick={addMore}/>*/}
            {/*        </Row>*/}
            {/*    )*/}
            {/*}*/}

        </Col>
    )
});

export {QuestionnaireField};
