import { FC, useState } from "react";
import { Icon, iconsTypes, Col, Row, Text } from "lavaa";
import { useCSVReader } from 'react-papaparse';
import { classNames } from '../../../Tools';
import { processCSVData } from "../../../Tools/schemeData";
import css from "./FileLoader.module.scss";

interface IProps {
  onUpload: ( data: any ) => void,
}

// FileLoader
const CSVLoader: FC<IProps> = ( props ) => {

  const { onUpload } = props;
  const { CSVReader } = useCSVReader();
  const [isHover, setIsHover] = useState(false);


  function onDragOver(event: DragEvent){
    event.preventDefault();
    setIsHover(true);
  }

  function onDragLeave(event: DragEvent){
    event.preventDefault();
    setIsHover(false);
  }

  function onUploadRejected(){
    setIsHover(false);
  }

  function onUploadAccepted(results: any){
    const res = processCSVData(results.data);
    onUpload(res);
    setIsHover(false);
  }

  return (
    <CSVReader
      onUploadAccepted={onUploadAccepted}
      onUploadRejected={onUploadRejected}
      onDragOver={onDragOver}
      onDragLeave={onDragLeave}
    >
      {({ getRootProps }: any) => (
        <Col className={classNames(css.FileLoader, isHover && css.Hover)} justifycontent="center" alignitems="center" {...getRootProps()}>
          <Row justifycontent="center" className={css.Icon} paddingBottom="1rem">
            <Icon name={iconsTypes.plusFilled}/>
          </Row>
          <Row justifycontent="center">
            <Text text="Add file" className={css.Label} bold="true"/>
          </Row>
        </Col>
      )}
    </CSVReader>
  );
};

export { CSVLoader };