import React, {FC} from 'react';
import {Col, Row, Text} from 'lavaa';
import css from './DiagnosisCode.module.scss';
import {useClients} from "../../../../../Hooks/UseClients";
import {CodesEmpty} from "./empty";

interface IProps {
    search?: string;
}

const DiagnosisCode: FC<IProps> = React.memo((props) => {
    const {search} = props;

    const {activeClientICD10Codes} = useClients();

    let codes;

    if(search){
        // Filter by search
        codes = activeClientICD10Codes.filter((item: any) => {
            return item.diagnosysID.toLowerCase().includes(search.toLowerCase()) || item.description.toLowerCase().includes(search.toLowerCase());
        });
    }
    else{
        codes = activeClientICD10Codes;
    }

    if(codes.length === 0) return (<CodesEmpty/>);

    return (
        <Col paddingTop="1.5rem" paddingBottom="0.75rem">
            {
                codes.map((item: any, index: number) => {
                    return (
                        <Row paddingBottom="0.75rem" paddingLeft="1.5rem" paddingRight="0.5rem" className={css.Item} key={index}>
                            <Text text={item.diagnosysID} size="x2" className={css.Code} bold="true"/>
                            <Text text={item.description} size="x2" className={css.Description}/>
                        </Row>
                    )
                })
            }
        </Col>
    )
});

export {DiagnosisCode};
