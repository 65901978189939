import { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useLocalStorage } from '../../Hooks/UseLocalStorage';

// Extension
const ExtensionSync: FC<any> = () => {

    // const location: any = useLocation();
    const { refreshToken, accessToken } = useParams();

    const [, setRefreshToken, refreshTokenActual] = useLocalStorage('refresh_token', '');
    const [, setAccessToken, accessTokenActual] = useLocalStorage('access_token', '');

    // Did mount
    useEffect(() => {
        // Save Tokens
        if (refreshToken && accessToken) {
            setRefreshToken(refreshToken);
            setAccessToken(accessToken);

            window.location.href = '/';
        }
    }, []);
    
    return null;
};

export { ExtensionSync };