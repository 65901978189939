import React, {FC, useContext} from "react";
import {useDemo} from "../../../../../Hooks/useDemo";
import {ModulesStatChart} from "./Chart";
import css from "../../Dashboard.module.scss";
import {Col, Icon, iconsTypes, Row, Title} from "lavaa";
import {EmptyChart} from "../emptyChart";
import {AppCtx} from "../../../../../Context/App.context";
import {classNames} from "../../../../../Tools";

type Props = {
    moduleId?: string,
    dragHandleProps?: any,
    isDragging?: boolean
}

// Modules Statistics
const ModulesStat: FC<Props> = ({
    moduleId,
    dragHandleProps,
    isDragging = false
}) => {
    let {deviceType} = useContext(AppCtx);
    const isMobile = deviceType === 'mobile';

    const { modules } = useDemo();

    const maxModules = isMobile ? 4 : 6;

    if(modules.length === 0) return (
        <Col className={css.Widget}>
            <Row className={css.WidgetHeader} alignitems="center" justifycontent="space-between">
                <Row className={classNames(css.HeaderMoveContainer, isDragging && css._dragging)} {...dragHandleProps} alignitems="center">
                    {
                        dragHandleProps && (
                            <Col className={css.WidgetMoveIcon}>
                                <Icon name={iconsTypes.move} size="x1"/>
                            </Col>
                        )
                    }
                    <Row className={css.WidgetTitle}>
                        <Title size="x3" maxLines={2} className={css.WidgetTitle}>Modules Statistics</Title>
                    </Row>
                </Row>
            </Row>
            <Row className={css.Chart} grow="1">
                <EmptyChart type="noData"/>
            </Row>
        </Col>
    );

    return (
        <ModulesStatChart maxModules={maxModules} moduleId={moduleId} dragHandleProps={dragHandleProps} isDragging={isDragging}/>
    );

};

export {ModulesStat};