import { FC } from 'react';

type Props = {
    value: string;
};

// HtmlCellRenderer Component
export const HtmlCellRenderer: FC<Props> = (props: Props) => {
    const { value } = props;

    return (
        <div dangerouslySetInnerHTML={{ __html: value.replace('<!DOCTYPE html>', '') }} />
    )
};