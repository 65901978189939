import React, {FC, useContext, useEffect, useState} from "react";
import { ModalsCtx } from "../../../../Context/Modals.context";
import { Modal} from 'lavaa';
import {MenuDashboardCardsContent} from "./MenuDashboardCardsContent.com";
import {DashboardCards} from "../../../Pages/Dashboard/Dashboard.data";

// Menu Dashboard Cards Modal
const MenuDashboardCards: FC<any> = () => {

    const { isMenuDashboardCardsModalActive, setMenuDashboardCardsModalActive, menuDashboardCards, setMenuDashboardCards } = useContext(ModalsCtx);

    const [menuDashboardCardsLocal, setMenuDashboardCardsLocal] = useState<string[]>([]);

    useEffect(() => {
        if(isMenuDashboardCardsModalActive) initMenuWidgets();
    }, [isMenuDashboardCardsModalActive]);

    function initMenuWidgets(){
        const local: Array<string> = [];
        const values = Object.keys(DashboardCards);
        menuDashboardCards.map((value: string) => {
            if (values.includes(value)) {
                local.push(value);
            }
        });

        setMenuDashboardCardsLocal(local);
    }

    // Handle Close
    const handleClose = () => {
        setMenuDashboardCards(menuDashboardCardsLocal);
        setMenuDashboardCardsModalActive(false);
    };

    return <Modal 
        active={ isMenuDashboardCardsModalActive }
        onClose={ handleClose } 
        title="Shown Charts"
        okDisabled={true}
        cancelDisabled={true}
        type="small"
    >
        <MenuDashboardCardsContent menuDashboardCardsLocal={menuDashboardCardsLocal} setMenuDashboardCardsLocal={setMenuDashboardCardsLocal}/>
    </Modal>
};

export { MenuDashboardCards };