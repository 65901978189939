import React, {FC, useEffect, useRef} from "react";
import {Row, iconsTypes, Button, Autocomplete, AutocompleteItemType} from "lavaa";
import css from "./style.module.scss";
import {useDemo} from "../../../Hooks/useDemo";
import {useNavigate} from "react-router-dom";
import { useAccount } from "../../../Hooks/UseAccount";
import { useMixPanel } from "../../../Hooks/UseMixPanel";

// Search Patient
const SearchPatient: FC<any> = React.memo(() => {
    const {activeFlowId, fetchDemoClients, clientsAutocomplete, clearAutocomplete} = useDemo();
    const { HMSProjectId } = useAccount();
    const navigate = useNavigate();
    const searchRef = useRef<string>("");
    const { track } = useMixPanel();

    const autocompleteList: AutocompleteItemType[] = clientsAutocomplete.map((item: any) => {
      return {
        id: item.id,
        value: item.name
      }
    });

    useEffect(() => {
        clearAutocomplete();
    }, []);

    function changeHandler(search: string){
        searchRef.current = search;
        if(search.length > 0){
            fetchDemoClients(HMSProjectId, activeFlowId, search, true);
        }
    }

    function chooseHandler(item: AutocompleteItemType | null){
        if(item){
            navigate(`/patients/${item.id}`);
            clearAutocomplete();
            track('Search Patient');
        }
        else{
            searchHandler();
        }
    }

    function searchHandler(){
        if(searchRef.current.length > 0){
            navigate(`/search/${searchRef.current}`);
        }
    }

    return(
        <Row className={css.Search} paddingBottom='2rem'>
            <Autocomplete
                placeholder="By patient's name"
                onChange={changeHandler}
                onSelect={chooseHandler}
                icon={iconsTypes.search}
                autocompleteList={autocompleteList}
            />
            <Row shrink="0" paddingLeft="2rem">
                <Button text="Search" type="primary" onClick={ searchHandler } />
            </Row>
        </Row>
    )
});

export {SearchPatient};
