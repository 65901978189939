export function convertValueWithScheme( scheme: any, value: any ) {
  const type = scheme.type;
  switch (type) {
    case "select":
      const values = scheme.fieldSettings.listValues;
      return values.find( ( item: any ) => item.value === value )?.title ?? value;

    case "boolean":
      return (value === true || value === "True") ? "Yes" : "No";

    case "number":
      return value.toString()
    case "text":
    default:
      return value;
  }
}

export function processCSVData( rows: any ) {
  let header = rows[0] as Array<string>;

  let clients: any = [];

  for ( let i = 1; i < rows.length; i++ ) {
    try {
      let row = rows[i] as Array<string>;
      let client: any = {
        firstName: row[header.indexOf( "Name" )].substring( 0, row[header.indexOf( "Name" )].indexOf( " " ) ),
        lastName: row[header.indexOf( "Name" )].substring( row[header.indexOf( "Name" )].indexOf( " " ) + 1 ),
        fullYears: parseInt( row[header.indexOf( "Full years" )] ),
        gender: row[header.indexOf( "Gender" )],
        id: row[header.indexOf( "ID" )],
        sourceClientID: row[header.indexOf( "ID" )],
        labResults: {},
        medicalHistories: {},
        personalRecords: {},
        drugs: row[header.indexOf( "Drugs" )].split( ";" ),
        drugsAsStr: row[header.indexOf( "Drugs" )]
      }

      client.personalRecords = {
        bmi: parseFloat( row[header.indexOf( "BMI" )] ),
        height: parseFloat( row[header.indexOf( "Height" )] ),
        weight: parseFloat( row[header.indexOf( "Weight" )] ),
        diastolicBloodPressure: parseFloat( row[header.indexOf( "Diastolic Blood Pressure" )] ),
        systolicBloodPressure: parseFloat( row[header.indexOf( "Systolic Blood Pressure" )] ),
        waistCircumference: parseFloat( row[header.indexOf( "Waist Circumference" )] ),
        race: row[header.indexOf( "Race" )],
        ethnicities: row[header.indexOf( "Ethnicities" )].split( "," ),
        previousInsurance: row[header.indexOf( "Previous Insurance" )].toUpperCase() == "TRUE",
        activityLevel: parseInt( row[header.indexOf( "Activity Level" )] ),
        stressLevel: parseInt( row[header.indexOf( "Stress Level" )] )
      };

      client.medicalHistories = {
        isDiabetes: row[header.indexOf( "Is Diabetes  from record" )].toUpperCase() == "TRUE",
        isPrediabetes: row[header.indexOf( "Is Prediabetes from record" )].toUpperCase() == "TRUE",
        hypertesion: row[header.indexOf( "Hypertesion" )].toUpperCase() == "TRUE",
        smoking: row[header.indexOf( "Smoking" )],
        noOfCOVIDVaccinations: parseInt( row[header.indexOf( "No Of COVID Vaccinations" )] ),
        wasCancer: row[header.indexOf( "Was Cancer" )].toUpperCase() == "TRUE",
        wasStroke: row[header.indexOf( "Was Stroke" )].toUpperCase() == "TRUE",
        copd: row[header.indexOf( "COPD" )].toUpperCase() == "TRUE",
        heartAttack: row[header.indexOf( "Heart Attack" )].toUpperCase() == "TRUE"
      };

      client.labResults = {
        hbA1C: parseFloat( row[header.indexOf( "HBA1C" )] ),
        fastingGlucose: parseInt( row[header.indexOf( "Fasting Glucose" )] ),
        ogtt: 0,
        albumin: parseInt( row[header.indexOf( "Albumin" )] ),
        albuminCreatinineRatio: parseInt( row[header.indexOf( "Albumin Creatinine Ratio" )] ),
        bloodcountHemoglobin: parseInt( row[header.indexOf( "Bloodcount/Hemoglobin" )] ),
        creatinine: parseInt( row[header.indexOf( "Creatinine" )] ),
        totalCholesterol: parseInt( row[header.indexOf( "Total Cholesterol" )] ),
        hdlCholesterol: parseInt( row[header.indexOf( "HDL Cholesterol" )] ),
        triglycerides: parseInt( row[header.indexOf( "Triglycerides" )] ),
        uricAcid: parseFloat( row[header.indexOf( "UricAcid" )] ),
        creatinineKidneyTest: parseFloat( row[header.indexOf( "Creatinine Kidney Test" )] )
      };

      clients.push( client );
    } catch (e) {
      console.warn( "ERROR on row", i, ", DATA: ", rows[i].data );
    }
  }

  return clients;
}