import {iconsTypes} from "lavaa";

export function getTreatmentPriorityIcon(priority: number): number{
    switch (priority) {
        case 1:
            return iconsTypes.highRisk;
        case 2:
            return iconsTypes.mediumRisk;
        default:
            return iconsTypes.lowRisk;
    }
}