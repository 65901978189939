import { Cell, Row, Col, withoutPaddings, Icon, iconsTypes, Text } from 'lavaa';
import { useAccount } from '../../Hooks/UseAccount';
import { MenuChooseProjectDropdown } from './Dropdown';
import {useContext, useEffect, useState} from 'react';
import css from './style.module.scss';
import {IUserProjectType} from "../../Redux/Slices/Account/Account.data";
import {AppCtx} from "../../Context/App.context";

type CurrentProjectType = {
    hospital: string,
    project: string
};

// Menu Choose Project
const MenuChooseProject = () => {

    //States
    const [dropdownActive, setDropdownActive] = useState(false);
    const [currentProject, setCurrentProject] = useState<CurrentProjectType>({
        hospital: '',
        project: ''
    });

    // Hooks
    const { projects, HMSProjectId } = useAccount();
    const {deviceType} = useContext(AppCtx);
    const isMobile = deviceType === 'mobile';

    function clickHandler(){
        setDropdownActive((prev) => !prev);
    }

    const onSelectProject = () => {
        setDropdownActive(false);
    };

    useEffect(() => {
        let current: CurrentProjectType = {
            hospital: '',
            project: ''
        };

        const item = projects.find((project: IUserProjectType) => project.ProjectUID === HMSProjectId);
        if(item){
            current = {
                hospital: item.Company || 'No Company',
                project: item.ProjectName
            };
        }

        setCurrentProject(current);
    }, [HMSProjectId, projects]);

    if(projects.length < 2) return null;

    return (
        <Row justifycontent="end" className={css.MenuProjectComponent}>
            <Row className={css.Wrapper}>
                <Row alignitems="center" className={css.MenuProject} onClick={clickHandler}>
                    
                    {/* User Icon */}
                    <Cell alignitems='center' justifycontent='center' className={css.HospitalIcon}>
                        <Icon name={iconsTypes.hospital} size={isMobile ? "x2" : "x3"}/>
                    </Cell>

                    <Col className={css.Info}>

                        {/* Hospital Name */}
                        <Cell {...withoutPaddings}>
                            <Text text={currentProject.hospital} size='x3' bold={true} ellipsis={true} className={css.HospitalName}/>
                        </Cell>

                        {/* Project Name */}
                        <Cell {...withoutPaddings}>
                            <Text text={currentProject.project} size='x2' ellipsis={true} className={css.ProjectName}/>
                        </Cell>
                    </Col>

                    {/* Arrow */}
                    <Cell alignitems='center' justifycontent='center'  {...withoutPaddings} paddingLeft='1rem' className={css.ArrowIcon}>
                        <Icon name={iconsTypes.arrowDown}/>
                    </Cell>
                </Row>

                {
                    /* Dropdown */
                    dropdownActive && <MenuChooseProjectDropdown onSelectProject={onSelectProject} closeHandler={() => setDropdownActive(false)}/>
                }
            </Row>
        </Row>
    );
};

export { MenuChooseProject };