import { useDispatch, useSelector } from 'react-redux';
import {IClinicalResearchType} from "../Redux/Slices/ClinicalResearch/ClinicalResearch.data";
import {
  getClinicalResearchesAction, resetClinicalResearchAction,
  updateClinicalResearchStatusAction
} from "../Redux/Slices/ClinicalResearch/ClinicalResearch.slice";

export const useClinicalResearch = (): any => {

  const dispatch = useDispatch();

  const clinicalResearch: IClinicalResearchType[] = useSelector((state: any) => state.clinicalResearch.clinicalResearch);

  // Fetch Clinical Researches
  const fetchClinicalResearches = (clientID: string, projectUID: string) => {
    dispatch(getClinicalResearchesAction([clientID, projectUID]));
  };

  // Update Clinical Research Status
  const updateClinicalResearchStatus = (moduleUID: string, clientID: string, projectUID: string, approved: boolean = true) => {
    const isApproved = approved ? 1 : 0;
    dispatch(updateClinicalResearchStatusAction([moduleUID, clientID, isApproved, projectUID]));
  };

  // Clear ClinicalResearch
  const clearClinicalResearch = () => {
    dispatch(resetClinicalResearchAction());
  };

  return {
    clinicalResearch,
    fetchClinicalResearches, updateClinicalResearchStatus, clearClinicalResearch
  };
}