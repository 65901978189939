import React, {FC} from "react";
import {QuestionnaireFieldNumber} from "./number";
import {QuestionnaireFieldBoolean} from "./boolean";
import {QuestionnaireFieldDate} from "./date";
import {QuestionnaireFieldText} from "./text";
import {parseQuestionnaireSelectOptions, QuestionnaireInputTypes} from "../type";
import {IQuestionnaireType} from "../../../../Redux/Slices/Questionnaire/Questionnaire.data";
import {QuestionnaireFieldDropdown} from "./dropdown";


interface IProps {
    value: any,
    // index: number,
    changeValue: (data: any) => void,
    // changeValue: (data: any, index: number) => void,
    question: IQuestionnaireType,
    type: QuestionnaireInputTypes,
    // onDelete?: (index: number) => void,
}

// Questionnaire Field Input
const QuestionnaireInput: FC<IProps> = React.memo((props) => {
    const {value, changeValue, question} = props;
    // const {index, onDelete} = props;
    const {type} = props;

    const placeholder = question.Parameter.EmptyPlaceholder || question.Parameter.DisplayName || undefined;


    function changeValueHandler(data: any){
        changeValue(data);
        // changeValue(data, index);
    }

    // function onDeleteHandler(){
    //     if(onDelete) onDelete(index);
    // }

    switch (type) {
        case 'text':
            return <QuestionnaireFieldText
                onChange={changeValueHandler}
                value={value}
                // onDelete={onDelete && onDeleteHandler}
                placeholder={placeholder}
            />;
        case 'number':
            return <QuestionnaireFieldNumber
                onChange={changeValueHandler}
                value={value}
                // onDelete={onDelete && onDeleteHandler}
                placeholder={placeholder}
                min={question.Parameter.Min}
                max={question.Parameter.Max}
                step={question.Parameter.Step}
            />;
        case 'boolean':
            return <QuestionnaireFieldBoolean onChange={changeValueHandler} value={value}/>;
        case 'date':
            return <QuestionnaireFieldDate
                onChange={changeValueHandler}
                value={value}
                // onDelete={onDelete && onDeleteHandler}
            />;
        case 'dropdown': {
            const options = parseQuestionnaireSelectOptions(question.Parameter.Options);
            return <QuestionnaireFieldDropdown onChange={changeValueHandler} value={value} options={options} isMultiple={false}/>;
        }
        case 'multiselect': {
            const options = parseQuestionnaireSelectOptions(question.Parameter.Options);
            return <QuestionnaireFieldDropdown onChange={changeValueHandler} value={value} options={options} isMultiple={true}/>;
        }
        default:
            return null;
    }
});

export {QuestionnaireInput};
