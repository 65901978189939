import React, { FC } from "react";
import { Row, Text} from "lavaa";
import { useDemo } from "../../../../../Hooks/useDemo";
import { useClients } from "../../../../../Hooks/UseClients";
import { convertValueWithScheme } from "../../../../../Tools/schemeData";
import css from "./MedicalHistory.module.scss";
import {classNames} from "../../../../../Tools";

interface IData {
  label: string,
  value: string,
}

const MedicalHistory: FC = React.memo(() => {

  const { activeClient } = useDemo();
  const { clientScheme } = useClients();

  if ( !activeClient?.client?.MedicalHistories || !clientScheme?.MedicalHistories) return null;

  const rawData = activeClient.client.MedicalHistories[0];

  const data: IData[] = [];
  Object.entries( rawData ).forEach( ( [key, value] ) => {
    const scheme = clientScheme.MedicalHistories.subfields[key];
    if ( !scheme ) return;

    data.push(
      {
        label: scheme.label,
        value: convertValueWithScheme( scheme, value ),
      }
    )
  } );

  return (
      <div className={css.Grid}>
        {
          data.map( ( item: any, index: number ) => (
            <Row key={index} className={classNames(css.Item, index >= data.length - 2 ? css.Last : "")}>
              <Text text={item.label} size="x2" ellipsis={true} className={css.ItemLabel} bold="true"/>
              <Text text={item.value} size="x2" className={css.ItemValue}/>
            </Row>
          ) )
        }
      </div>
  )
});

export { MedicalHistory };
