import React, {FC} from "react";
import css from "./style.module.scss";
import {Col, PieChart, PieChartDataType, Row, ScrollContainer, Text} from "lavaa";
import {useQuestionnaire} from "../../../../../Hooks/UseQuestionnaire";
import {IQuestionnaireStatisticsType} from "../../../../../Redux/Slices/Questionnaire/Questionnaire.slice";
import {useDemo} from "../../../../../Hooks/useDemo";
import {IModuleType} from "../../../../../Redux/Slices/Demo/Demo.data";

interface IProps {}

const colors = [
    "#FDD835", "#FF3E1E", "#07B951", "#3B54D0",
    "#14C9C9", "#FFA726", "#6F767E", "#7C4DFF",
    "#00E676", "#B1BAF4", "#90BE6D"
];


// Questionnaire Side
const QuestionnaireSide: FC<IProps> = React.memo(() => {

    const questionnaireStatistics: IQuestionnaireStatisticsType | undefined = useQuestionnaire().questionnaireStatistics;
    const { modules } = useDemo();

    if(!questionnaireStatistics) return null;

    const totalQuestions = questionnaireStatistics.QuestionsAmount;
    const totalAnswers = questionnaireStatistics.AnsweredQuestionsAmount;

    const completionRate = (totalQuestions === 0) ? 100 : Math.floor(totalAnswers / totalQuestions * 100);

    const completionRateChart: PieChartDataType[] = [
        {
            name: 'Answered',
            value: totalAnswers,
            color: "#00C853"
        },
        {
            name: 'Unanswered',
            value: totalQuestions - totalAnswers,
            color: "#E40931"
        },
    ];

    const byModulesChart: PieChartDataType[] = [];
    let modulesAddedCount = 0;
    let modulesAddedPercentage = 0;
    let hasSkipped = false;

    Object.entries(questionnaireStatistics.ModulesQuestionsPercentage).forEach(([key, value]: [string, number]) => {
        if(modulesAddedCount >= 9) {
            hasSkipped = true;
            return;
        }

        const module: IModuleType | undefined = modules.find((module: IModuleType) => module.ModuleUID === key);
        if(!module) {
            hasSkipped = true;
            return;
        }

        const percentage = Math.round(value * 10) / 10;

        byModulesChart.push({
            name: module.ModuleName,
            value: percentage,
            color: colors[modulesAddedCount % colors.length]
        });

        modulesAddedPercentage += percentage;
        modulesAddedCount++;
    });

    if(hasSkipped && modulesAddedPercentage < 100) {
        byModulesChart.push({
            name: 'Other',
            value: 100 - modulesAddedPercentage,
            color: colors[modulesAddedCount % colors.length]
        });
    }

    const estimatedTime = (totalQuestions - totalAnswers) * 0.5;

    return (
        <Col>
            <Col className={css.Side}>
                <Col className={css.Title}>
                    <Row marginBottom="0.25rem">
                        <Text size="x5" bold="true">Questionnaire Statistics</Text>
                    </Row>
                </Col>
                <ScrollContainer>
                    <Col className={css.BasicInfo}>
                        <Col marginBottom="1rem">
                            <Text size="x3" bold="true">ESTIMATED COMPLETION RATE</Text>
                            <Text size="x3">{completionRate} % Completed</Text>
                        </Col>
                        {
                            estimatedTime >= 2 && (
                                <Col>
                                    <Text size="x3" bold="true">ESTIMATED TIME TO COMPLETE</Text>
                                    <Text size="x3">{Math.ceil(estimatedTime)} Minutes</Text>
                                </Col>
                            )
                        }

                    </Col>
                    <Col className={css.ChartBox}>
                        <Col marginBottom="1rem">
                            <Text size="x4" bold="true">Questions by answers</Text>
                        </Col>
                        <Row grow="1" className={css.Chart} style={{height: '270px'}}>
                            <PieChart
                                data={completionRateChart}
                                textTitle="Total answers"
                                textValue={totalAnswers.toString()}
                                filled={false}
                                legendAlign='center'
                                legendVerticalAlign='bottom'
                                chartPosition='center'
                            />
                        </Row>
                    </Col>
                    <Col className={css.ChartBox}>
                        <Col marginBottom="1rem">
                            <Text size="x4" bold="true">Questions by modules</Text>
                        </Col>
                        {/*<Col marginBottom="1rem">*/}
                        {/*    <Text size="x2">Total questions <b>{totalQuestions}</b></Text>*/}
                        {/*</Col>*/}
                        <Row grow="1" className={css.Chart} style={{height: `${200 + byModulesChart.length * 33}px`}}>
                            <PieChart
                                data={byModulesChart}
                                filled={false}
                                textTitle="Total questions"
                                textValue={totalQuestions.toString()}
                                legendAlign='center'
                                legendVerticalAlign='bottom'
                                chartPosition='center'
                            />
                        </Row>
                    </Col>
                </ScrollContainer>
            </Col>
        </Col>
    )

});

export {QuestionnaireSide};
